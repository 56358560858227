import axios from "axios";
import { serverAddress } from "../../constants.js";

const utils = {
  authenticateUser: (params) => {
    if (params.username !== null && params.password !== null) {
      //code to call server api
      let url = serverAddress + "/login/check-login";
      return axios.post(url, params);
    }
  },

  validateToken: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/welcome";
      return axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      });
    }
  },
};

export default utils;
