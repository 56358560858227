import {notification} from 'antd';

export const OpenNotification = (notificationData) => {
    const {type, title, description, duration} = notificationData;

    notification[type]({
        message: title,
        description: description,
        duration: duration
    });
};