import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
// import { SalesSideBar } from "../../components/sales/SalesSideBar";
// import SalesHeader from "../../components/sales/SalesHeader";
import Loader from "../../assets/images/powertech-loader-2.gif";
import { OpenClickableNotification } from "../../components/shared/OpenClickableNotification";
import { OpenNotification } from "../../components/shared/OpenNotification";
import {
  NOTIFICATION,
  VALIDATION_MESSAGES,
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
  ROLE,
  serverAddress,
} from "../../constants";
import { validation } from "../../utils/validation";
import { CreateProforma } from "../../components/pages/export/CreateProforma";
import {
  getAllClientNames,
  addNewClient,
} from "../../redux/actions/ClientActions";
import {
  getAllQuotations,
  getQuotationDetails,
} from "../../redux/actions/QuotationActions";
import {
  createNewProforma,
  resetExport,
  getAllBanks,
} from "../../redux/actions/ProformaActions";
import {
  getExportById,
  getAllExports,
  createExportRevision,
  createFinalExport,
  getDownloadLink,
} from "../../redux/actions/ExportActions";
import { logOut } from "../../redux/actions/LoginActions";
import { withRouter } from "react-router-dom";
let sessionValue = JSON.parse(localStorage.getItem("data"));
let token = sessionValue?.loginData.user.token;
class CreateExportProforma extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      countryCode: "91",
      alt_countryCode: "91",
      title: "Mr",
      limit: 10,
      page: 1,
      currency: "",
      validation_error: {
        company_name_error: null,
        contact_person_name: null,
        contact_person_email: null,
        contact_person_mobile: null,
        category: null,
        type: null,
        // program_error: null,
        // source_error: null,
        // follow_up_body_error: null,
        form_error: false,
        token,
      },
    };
  }
  componentWillMount() {
    sessionValue = JSON.parse(localStorage.getItem("data"));
    const { match } = this.props;
    const { id } = match.params;
    if (sessionValue === null) {
      this.props.history.push("/");
    } else if (
      sessionValue.loginData.user.role.toLowerCase() !== ROLE.Orders &&
      sessionValue.loginData.user.role.toLowerCase() !== ROLE.Admin
    ) {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Unauthorised",
        description: ERROR_MESSAGES.UN_AUTHORISED_PAGE,
        duration: 4,
      };
      OpenNotification(notificationData);
      this.props.history.push(
        `/${sessionValue.loginData.user.role.toLowerCase()}`
      );
    } else {
      this.setState(
        {
          token: sessionValue.loginData.user.token,
        },
        () => {
          let params = {
            id: id,
            token: this.state.token,
            page: this.state.page,
            limit: this.state.limit,
          };
          // this.props.getAllQuotations(params);
          // this.props.getAllClientNames(params);
          this.props.getExportById(params);
          this.props.getAllExports(params);
          this.props.getAllBanks({ token: params.token });
        }
      );
    }
  }

  componentDidMount() {
    // this.props.getAllClientNames();
    console.log(this.props);
  }

  componentWillUnmount() {
    this.props.resetExport();
  }

  handleChange = (e) => {
    validation.validate_new_client_from(e.target, this.state.validation_error);
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state);
  };

  // handleQuotationSelect = (value) => {
  //   let params = {
  //     token: this.state.token,
  //     id: value,
  //   };
  //   this.props.getQuotationDetails(params);
  // };

  handleClientSelect = (value) => {
    // let params = {
    //   token: this.state.token,
    //   id: value,
    // };
    // this.props.getQuotationDetails(params);
    // console.log(value);
  };

  handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    if (!_.isEmpty(data)) {
      // localStorage.clear();
      this.props.history.push("/");
      this.props.logOut();
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };

  resetQuotation = () => {
    this.props.resetQuotation();
    this.props.resetNameList();
    setTimeout(() => {
      this.props.getAllClientNames({ token });
    }, 200); //call for name list after 200ms
  };

  handleExportSubmit = (
    props,
    currency,
    date,
    billto,
    subTotal,
    amountPaid
  ) => {
    let validation_error = { ...this.state.validation_error };
    const exportInvoiceId = "6297284c02a70f5c1354562c";
    if (props.length > 0) {
      const params = {
        exportData: props,
        currency: currency,
        invoiceDate: date,
        bill_to: billto,
        subTotal: subTotal,
        amount_paid: amountPaid,
        exportInvoiceId,
      };
      // console.log(params);
      this.props
        .createNewProforma(params)
        .then((result) => {
          if (result.value.msg === "Proforma Created") {
            const notificationData = {
              type: NOTIFICATION.SUCCESS,
              title: "Success",
              description: SUCCESS_MESSAGES.PROFORMA_ADDED_SUCCESSFULLY,
              duration: 6,
              link: result.value.downloadLink,
            };
            OpenClickableNotification(notificationData);
            localStorage.removeItem("exportData");
            localStorage.removeItem("currency");
            localStorage.removeItem("invoiceDate");
            localStorage.removeItem("amountPaid");
            this.props.history.push("/proforma/list");
            // this.props.history.push(
            //   `/export/packing/?id=${result.value.data._id}&final=${
            //     url === "new" ? false : true
            //   }`
            // );
          }
        })
        .catch((err) => {
          const notificationData = {
            type: NOTIFICATION.ERROR,
            title: "Error",
            description: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
            duration: 4,
          };
          OpenNotification(notificationData);
          //console.log(err);
        });
    } else {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Form Error",
        description: VALIDATION_MESSAGES.SOMETHING_WENT_WRONG,
        duration: 4,
      };
      OpenNotification(notificationData);
      this.setState({ validation_error });
    }
  };

  handleClientSubmit = (e, props) => {
    e.preventDefault();
    let validation_error = { ...this.state.validation_error };
    if (props.hasOwnProperty("company_name") && props.hasOwnProperty("type")) {
      validation_error["form_error"] = false;
      this.setState({ validation_error }, () => {
        let params = {
          company_name: props.company_name ? props.company_name : null,
          billing_address: props.billing_address ? props.billing_address : null,
          shipping_address: props.shipping_address
            ? props.shipping_address
            : null,
          gstin_number: props.gstin_number ? props.gstin_number : null,
          first_name: props.contact_person_first_name
            ? `${this.state.title && props.title}.${
                props.contact_person_first_name &&
                props.contact_person_first_name
              }`
            : null,
          last_name: props.contact_person_last_name
            ? props.contact_person_last_name
            : null,
          email_address: props.contact_person_email
            ? props.contact_person_email
            : null,
          phone_number: props.contact_person_mobile
            ? `(+${this.state.countryCode}) ${
                props.contact_person_mobile && props.contact_person_mobile
              }`
            : null,
          alternate_phone_number: props.contact_person_alternate_mobile
            ? `(+${this.state.alt_countryCode}) ${
                props.contact_person_alternate_mobile &&
                props.contact_person_alternate_mobile
              }`
            : null,
          type: props.type ? props.type : null,
          category: props.category ? props.category : null,
          token: this.state.token,
          // employee_id: this.state.user_id,
        };
        this.props
          .addNewClient(params)
          .then((res) => {
            if (!res.value.error) {
              const notificationData = {
                type: NOTIFICATION.SUCCESS,
                title: "Success",
                description: SUCCESS_MESSAGES.CLIENT_ADDED_SUCCESSFULLY,
                duration: 4,
              };
              OpenNotification(notificationData);
              document.getElementById("close_popup").click();
            }
          })
          .catch((err) => {
            const notificationData = {
              type: NOTIFICATION.ERROR,
              title: "Error",
              description: ERROR_MESSAGES.CLIENT_ALREADY_EXISTS,
              duration: 4,
            };
            OpenNotification(notificationData);
          });
      });
    } else {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Form Error",
        description: VALIDATION_MESSAGES.CHECK_FORM_FIELDS_CLIENT,
        duration: 4,
      };
      OpenNotification(notificationData);
      this.setState({ validation_error });
    }
  };

  render() {
    return (
      // <div style={{ backgroundColor: "#EEEEEE", height: "100vh" }}>
      //   {/*<HeaderComponent content={this.state} handleSignOut={this.handleSignOut}/>*/}
      //   <SalesHeader
      //     content={this.state}
      //     history={this.props.history}
      //     redirectToLeadPage={this.redirectToLeadPage}
      //     handleSignOut={this.handleSignOut}
      //   />
      //   <SalesSideBar content={this.state} userData={this.props.userData} />
      <>
        {this.props.exportData.isbankListFetched ? (
          <CreateProforma
            content={this.state}
            userData={this.props.userData}
            exportData={this.props.exportData}
            quotationData={this.props.quotationData}
            clientData={this.props.clientData}
            resetQuotation={this.resetQuotation}
            handleClientSubmit={this.handleClientSubmit}
            handleChange={this.handleChange}
            handleExportSubmit={this.handleExportSubmit}
            // handleQuotationSelect={this.handleQuotationSelect}
            resetClientForm={this.resetClientForm}
          />
        ) : (
          <img
            src={Loader}
            alt="logo"
            className="mx-auto d-block loaderImage"
          />
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    clientData: state.ClientReducer,
    userData: state.LoginReducer,
    exportData: state.ExportReducer,
    quotationData: state.QuotationReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllQuotations: (params) => dispatch(getAllQuotations(params)),
    getAllBanks: (params) => dispatch(getAllBanks(params)),
    addNewClient: (params) => dispatch(addNewClient(params)),
    getQuotationDetails: (params) => dispatch(getQuotationDetails(params)),
    createNewProforma: (params) => dispatch(createNewProforma(params)),
    getExportById: (params) => dispatch(getExportById(params)),
    getAllExports: (params) => dispatch(getAllExports(params)),
    getAllClientNames: (params) => dispatch(getAllClientNames(params)),
    logOut: () => dispatch(logOut()),
    resetExport: () => dispatch(resetExport()),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateExportProforma)
);
