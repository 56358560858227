import axios from "axios";
import { serverAddress } from "../../constants.js";

const utils = {
  addNewClient: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/client/add-new-client";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getClientCode: () => {
    let url = serverAddress + "/client/generate/id";
    return axios.get(url);
  },
  getAllClientNames: (params) => {
    let url =
      serverAddress +
      `/client/names/list?page=${params.page}&limit=${params.limit}&search=${
        params.search ? params.search : ""
      }`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getTotalClients: (params) => {
    let url = serverAddress + "/client/total-clients";
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getAllClients: (params) => {
    let url =
      serverAddress +
      `/client/list?page=${params.page}&limit=${params.limit}&filterType=${
        params.filterType ? params.filterType : ""
      }&filterCategory=${
        params.filterCategory ? params.filterCategory : ""
      }&search=${params.search ? params.search : ""}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getClientDetails: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/client/client-information/${params.id}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  addNewContact: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/client/add/contact";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  updateClientData: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/client/update";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  updateClientCont: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/client/contact/update";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  deleteClient: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/client/${params.id}`;
      return axios.delete(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  deleteClientContact: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/client/contact/${params.id}`;
      return axios.delete(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getClientDetailsById: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/client/client-info/${params}`;
      return axios.get(url);
    }
  },
};

export default utils;
