import React, { useState } from "react";
import axios from "axios";
import { OpenNotification } from "../../../shared/OpenNotification";
import { notification } from "antd";
import { NOTIFICATION, serverAddress } from "../../../../constants";

const UploadClientData = () => {
  const [file, setFile] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    setFile(uploadedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hi");
    const sessionValue = JSON.parse(localStorage.getItem("data"));
    const token = sessionValue?.loginData.user.token;

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(
          `${serverAddress}/client/upload-new-clients`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log("File uploaded successfully:", response.data);
        // console.log(response.data.success);
        if (!response.data.success || response.data.success == false) {
          let notificationData = {
            type: "error",
            title: "Error",
            description: response.data.message || response.data.error,
            duration: 3,
          };
          OpenNotification(notificationData);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        if (response.data.success) {
          let notificationData = {
            type: "success",
            title: "Success",
            description: response.data.message,
            duration: 3,
          };
          OpenNotification(notificationData);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        let notificationData = {
          type: "error",
          title: "Error",
          description: "Something Went wrong",
          duration: 3,
        };
        OpenNotification(notificationData);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      let notificationData = {
        type: "error",
        title: "Error",
        description: "Please Select a File",
        duration: 3,
      };
      OpenNotification(notificationData);
    }
  };

  const handleDownloadSample = () => {
    const sampleCsvContent = `company_name,billing_address,shipping_address,gstin_number,type,category,first_name,last_name,email_address,phone_number,alternate_phone_number\nHP Industry Something,john cane,john cane,12345ABCD,EXPORT,B2B,John,Doe,john.doe@example.com,1234567890,0987654321\nChanged Something,jane doe,jane doe,67890EFGH,DOMESTIC,B2C,Jane,Doe,jane.doe@example.com,9876543210,0123456789`;
    const blob = new Blob([sampleCsvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    a.click();
  };

  return (
    <div>
      <section className="content-header">
        <div className="row">
          <div className="col-md-12 mr-2 ml-n4">
            <div
              className="header-icon mt-1"
              style={{
                "max-width": "2.5rem",
                "max-heigth": "2rem",
                fontSize: "2.5rem",
              }}
            >
              <i className="fa fa-list-alt" />
            </div>
            <div className="header-title ml-5 mt-2">
              <h1>Upload New Clients File</h1>

              {/* <small>Marketing & Sales</small> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          style={{
            border: "2px dashed #ccc",
            padding: "20px",
            textAlign: "center",
            cursor: "pointer",
            minHeight: "400px",
            backgroundColor: "#C2E8FF",
          }}
        >
          <h3>Drag & Drop a File Here</h3>
          <a
            onClick={handleDownloadSample}
            className="mt-1 border-primary text-primary float-end me-2"
          >
            Download Sample CSV File
          </a>
          <form onSubmit={handleSubmit} className="mt-3">
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />

            <button type="submit">Upload</button>
          </form>

          {file && <p>Selected File: {file.name}</p>}
        </div>
      </section>
    </div>
  );
};

export default UploadClientData;
