import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoginForm } from "../components/LoginForm";
import {
  authenticateUser,
  validateToken,
} from "../redux/actions/LoginActions.js";
import { ROLE } from "../constants";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const bodyParameters = {
      user_name: this.state.username,
      password: this.state.password,
    };
    this.props.authenticateUser(bodyParameters);
  };

  componentWillMount() {
    let sessionValue = JSON.parse(localStorage.getItem("data"));
    let params = {
      token: sessionValue?.loginData?.user?.token,
    };

    if (sessionValue) {
      const bodyParameters = {
        user_name: sessionValue.loginData.user_name,
        password: sessionValue.loginData.password,
      };

      this.props
        .authenticateUser(bodyParameters)
        .then(() => {
          // Authentication successful, now validate the token
          return this.props.validateToken(params);
        })
        .then((res) => {
          if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() === ROLE.Admin &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/admin");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() === ROLE.purchase &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/purchase");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() === ROLE.Sales &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/sales");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() === ROLE.Orders &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/orders");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() ===
              ROLE.SuperAdmin &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/superadmin");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() === ROLE.Store &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/store");
          } else if (
            sessionValue &&
            sessionValue.loginData.user.role.toLowerCase() ===
              ROLE.StorePurchase &&
            this.props.loginData.tokenValid
          ) {
            this.props.history.push("/storePurchase");
          } else {
            this.props.history.push("/");
          }
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    } else {
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(props, nextProps) {
    const loginData = props.loginData;

    // console.log("show login data",loginData);
    if (loginData.isLogin) {
      this.setState({ loginData }, () => {
        localStorage.setItem("data", JSON.stringify(loginData));
        if (loginData?.loginData?.user?.role.toLowerCase() === ROLE.Admin) {
          // console.log("triggered in login");
          props.history.push("/admin");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.Orders
        ) {
          props.history.push("/orders");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.Sales
        ) {
          props.history.push("/sales");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.purchase
        ) {
          props.history.push("/purchase");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.SuperAdmin
        ) {
          props.history.push("/superadmin");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.Store
        ) {
          props.history.push("/store");
        } else if (
          loginData?.loginData?.user?.role.toLowerCase() === ROLE.StorePurchase
        ) {
          props.history.push("/storePurchase");
        }
      });
    } else {
      this.setState({ message: loginData.message_error });
    }
  }

  render() {
    return (
      <div className="vh-100">
        <LoginForm
          content={this.state}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticateUser: (params) => dispatch(authenticateUser(params)),
    validateToken: (params) => dispatch(validateToken(params)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
