import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  QuotationData: [],
  QuotationAddResponse: [],
  QuotationsList: [],
  LeadQuotations: [],
  message_error: "",
  receivedResponse: false,
  isLeadQuotationsFetched: false,
  quotationAdded: false,
  quotationListFetched: false,
  quotationDetailsFetched: false,
  quotationUpdated: false,
  revisionDeleted: false,
};

export default function QuotationReducer(
  state = initialFetchDataState,
  action
) {
  switch (action.type) {
    case types.ADD_NEW_QUOTATION_FULFILLED:
      state = {
        ...state,
        loading: false,
        QuotationAddResponse: action.payload,
        receivedResponse: 200,
        quotationAdded: true,
      };
      break;

    case types.ADD_NEW_QUOTATION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        quotationAdded: false,
      };
      break;
    case types.ADD_NEW_QUOTATION_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_QUOTATION_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        QuotationsList: action.payload,
        receivedResponse: 200,
        quotationListFetched: true,
      };
      break;
    case types.GET_QUOTATION_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        quotationListFetched: false,
      };
      break;
    case types.GET_QUOTATION_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_UPDATED_QUOTATION_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        QuotationsList: action.payload,
        receivedResponse: 200,
        // quotationListFetched: true,
      };
      break;
    case types.GET_UPDATED_QUOTATION_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,////
        // quotationListFetched: false,
      };
      break;
    case types.GET_UPDATED_QUOTATION_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_QUOTATION_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        QuotationData: action.payload,
        receivedResponse: 200,
        quotationDetailsFetched: true,
      };
      break;
    case types.GET_QUOTATION_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        quotationDetailsFetched: false,
      };
      break;
    case types.GET_QUOTATION_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.DATA_NOT_FOUND,
      };
      break;

    case types.GET_QUOTATION_DETAILS_BY_LEAD_ID_FULFILLED:
      state = {
        ...state,
        loading: false,
        LeadQuotations: action.payload,
        receivedResponse: 200,
        isLeadQuotationsFetched: true,
      };
      break;
    case types.GET_QUOTATION_DETAILS_BY_LEAD_ID_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isLeadQuotationsFetched: false,
      };
      break;
    case types.GET_QUOTATION_DETAILS_BY_LEAD_ID_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.DATA_NOT_FOUND,
      };
      break;

    case types.CREATE_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        QuotationAddResponse: action.payload,
        quotationUpdated: true,
      };
      break;
    case types.CREATE_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        quotationUpdated: false,
      };
      break;
    case types.CREATE_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.DELETE_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        revisionDeleted: true,
      };
      break;
    case types.DELETE_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        revisionDeleted: false,
      };
      break;
    case types.DELETE_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.GET_SALES_DOWNLOAD_LINK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;
    case types.GET_SALES_DOWNLOAD_LINK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.GET_SALES_DOWNLOAD_LINK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.RESET_QUOTATION:
      state = {
        ...state,
        loading: false,
        quotationUpdated: false,
        quotationAdded: false,
        isLeadQuotationsFetched: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
