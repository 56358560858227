import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import ClientReducer from "./ClientReducer";
import QuotationReducer from "./QuotationReducer";
import LeadsReducer from "./LeadsReducer";
import EmployeeReducer from "./EmployeeReducer";
import VendorReducer from "./VendorReducer";
import PurchaseReducer from "./PurchaseReducer";
import OrdersReducer from "./OrderReducer";
import MrnReducer from "./MrnReducer";
import InventoryReducer from "./InventoryReducer";
import ExportReducer from "./ExportReducer";
import ProformaReducer from "./ProformaReducer";
import PackingReducer from "./PackingReducer";
import FileReducer from "./FileReducer";
import SidebarReducer from "./SidebarReducer";
export default combineReducers({
  LoginReducer,
  ClientReducer,
  QuotationReducer,
  LeadsReducer,
  EmployeeReducer,
  VendorReducer,
  PurchaseReducer,
  OrdersReducer,
  MrnReducer,
  InventoryReducer,
  ExportReducer,
  ProformaReducer,
  PackingReducer,
  FileReducer,
  SidebarReducer,
});
