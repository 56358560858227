import * as types from "../../constants";
import ExportAPI from "../../utils/api/ExportAPI";

export function getAllExports(params) {
  return {
    type: types.GET_ALL_EXPORTS,
    payload: ExportAPI.getAllExports(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedExports(params) {
  return {
    type: types.GET_UPDATED_EXPORTS,
    payload: ExportAPI.getAllExports(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllBanks(params) {
  return {
    type: types.GET_BANK_LIST,
    payload: ExportAPI.getAllBanks(params).then((response) => {
      return response.data;
    }),
  };
}

export function addFilesToExport(params) {
  return {
    type: types.ADD_FILES_TO_EXPORT,
    payload: ExportAPI.addFilesToExport(params).then((response) => {
      return response.data;
    }),
  };
}
export function getExportById(params) {
  return {
    type: types.GET_EXPORT_DETAILS,
    payload: ExportAPI.getExportById(params).then((response) => {
      return response.data;
    }),
  };
}

export function createExport(params) {
  return {
    type: types.CREATE_EXPORT,
    payload: ExportAPI.createExport(params).then((response) => {
      return response.data;
    }),
  };
}

export function createExportRevision(params) {
  return {
    type: types.CREATE_EXPORT_REVISION,
    payload: ExportAPI.createExportRevisiom(params).then((response) => {
      return response.data;
    }),
  };
}

export function createFinalExport(params) {
  return {
    type: types.CREATE_FINAL_EXPORT,
    payload: ExportAPI.createFinalExport(params).then((response) => {
      return response.data;
    }),
  };
}

export function getDownloadLink(params) {
  // console.log(params)
  return {
    type: types.GET_EXPORT_DOWNLOAD_LINK,
    payload: ExportAPI.getDownloadLink(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetExport() {
  return {
    type: types.RESET_EXPORT,
  };
}

export function resetExportData() {
  return {
    type: types.RESET_EXPORT_DATA,
  };
}
