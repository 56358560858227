import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  totalLeads: [],
  isAllLeadCountFetched: false,
  LeadData: [],
  LeadAddResponse: [],
  LeadsList: [],
  clientLeads: [],
  PendingLeads: "",
  TotalLeadsSend: "",
  RevisedQuotationLeads: "",
  ClosedLeads: "",
  message_error: "",
  receivedResponse: false,
  isPendingLeadsFetched: false,
  isClosedLeadsFetched: false,
  isLeadCreated: false,
  isLeadDeleted: false,
  isLeadsListFetched: false,
  isClientLeadsListFetched: false,
  isLeadStatusUpdated: false,
  isLeadRemarkUpdated: false,
  isLeadHandledByUpdated: false,
  leadStatusCounts: {},
  isLeadStatusCountFetched: false,
  employeeLeadStatusCounts: {},
  isEmployeeLeadStatusCountFetched: false,
};

export default function LeadsReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_ALL_LEAD_COUNT_FULFILLED:
      state = {
        ...state,
        loading: false,
        totalLeads: action.payload,
        receivedResponse: 200,
        isAllLeadCountFetched: true,
      };
      break;

    case types.GET_ALL_LEAD_COUNT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isAllLeadCountFetched: false,
      };
      break;

    case types.GET_ALL_LEAD_COUNT_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.ADD_NEW_LEAD_FULFILLED:
      state = {
        ...state,
        loading: false,
        LeadAddResponse: action.payload,
        receivedResponse: 200,
        isLeadCreated: true,
      };
      break;

    case types.GET_LEAD_STATUS_COUNT_FULFILLED: // Add this case
      return {
        ...state,
        loading: false,
        leadStatusCounts: action.payload.leadStatusCounts,
        totalLeads: action.payload.totalLeads,
        isLeadStatusCountFetched: true,
      };

    case types.GET_LEAD_STATUS_COUNT_PENDING: // Add this case
      return {
        ...state,
        loading: true,
        isLeadStatusCountFetched: false,
      };

    case types.GET_LEAD_STATUS_COUNT_REJECTED: // Add this case
      return {
        ...state,
        loading: false,
        isLeadStatusCountFetched: false,
        message_error: action.payload.message,
      };

    case types.GET_EMPLOYEE_LEAD_STATUS_COUNT_FULFILLED:
      return {
        ...state,
        loading: false,
        employeeLeadStatusCounts: action.payload.data,
        isEmployeeLeadStatusCountFetched: true,
      };

    case types.GET_EMPLOYEE_LEAD_STATUS_COUNT_PENDING:
      return {
        ...state,
        loading: true,
        isEmployeeLeadStatusCountFetched: false,
      };

    case types.GET_EMPLOYEE_LEAD_STATUS_COUNT_REJECTED:
      return {
        ...state,
        loading: false,
        isEmployeeLeadStatusCountFetched: false,
        message_error: action.payload.message,
      };

    case types.ADD_NEW_LEAD_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;

    case types.ADD_NEW_LEAD_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.DELETE_LEAD_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isLeadDeleted: true,
      };
      break;

    case types.DELETE_LEAD_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isLeadDeleted: false,
      };
      break;

    case types.DELETE_LEAD_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_CLOSED_LEADS_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClosedLeads: action.payload,
        receivedResponse: 200,
        isClosedLeadsFetched: true,
      };
      break;

    case types.GET_CLOSED_LEADS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClosedLeadsFetched: false,
      };
      break;

    case types.GET_CLOSED_LEADS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_PENDING_LEADS_FULFILLED:
      state = {
        ...state,
        loading: false,
        PendingLeads: action.payload,
        receivedResponse: 200,
        isPendingLeadsFetched: true,
      };
      break;

    case types.GET_PENDING_LEADS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPendingLeadsFetched: false,
      };
      break;

    case types.GET_PENDING_LEADS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_TOTAL_OFFER_SEND_FULFILLED:
      state = {
        ...state,
        loading: false,
        TotalLeadsSend: action.payload,
        receivedResponse: 200,
        isPendingLeadsFetched: true,
      };
      break;

    case types.GET_TOTAL_OFFER_SEND_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPendingLeadsFetched: false,
      };
      break;

    case types.GET_TOTAL_OFFER_SEND_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_REVISED_QUOTATION_OFFER_SEND_FULFILLED:
      state = {
        ...state,
        loading: false,
        RevisedQuotationLeads: action.payload,
        receivedResponse: 200,
        isPendingLeadsFetched: true,
      };
      break;

    case types.GET_REVISED_QUOTATION_OFFER_SEND_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPendingLeadsFetched: false,
      };
      break;

    case types.GET_TOTAL_OFFER_SEND_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_LEADS_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        LeadsList: action.payload,
        receivedResponse: 200,
        isLeadsListFetched: true,
      };
      break;

    case types.GET_LEADS_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        isLeadsListFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_LEADS_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_EMPLOYEE_LEADS_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        LeadsList: action.payload,
        receivedResponse: 200,
        isLeadsListFetched: true,
      };
      break;

    case types.GET_EMPLOYEE_LEADS_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        isLeadsListFetched: false,
        receivedResponse: false,
      };
      break;

    case types.GET_EMPLOYEE_LEADS_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_CLIENT_LEADS_FULFILLED:
      state = {
        ...state,
        loading: false,
        clientLeads: action.payload,
        receivedResponse: 200,
        isClientLeadsListFetched: true,
      };
      break;

    case types.GET_CLIENT_LEADS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientLeadsListFetched: false,
      };
      break;

    case types.GET_CLIENT_LEADS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.UPDATE_LEAD_STATUS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        // isLeadsListFetched: true,
        isLeadStatusUpdated: true,
      };
      break;

    case types.UPDATE_LEAD_STATUS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        // isLeadsListFetched: false,
        isLeadStatusUpdated: false,
      };
      break;

    case types.UPDATE_LEAD_STATUS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.UPDATE_LEAD_REMARK_FULFILLED:
      // console.log('working')
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        // isLeadsListFetched: true,
        isLeadRemarkUpdated: true,
      };
      break;

    case types.UPDATE_LEAD_REMARK_PENDING:
      // console.log('working')
      state = {
        ...state,
        loading: true,
        // isLeadsListFetched: false,
        isLeadRemarkUpdated: false,
      };
      break;

    case types.UPDATE_LEAD_REMARK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.UPDATE_LEAD_HANDLEDBY_FULFILLED:
      // console.log('working')
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        // isLeadsListFetched: true,
        isLeadHandledByUpdated: true,
      };
      break;

    case types.UPDATE_LEAD_HANDLEDBY_PENDING:
      // console.log('working')
      state = {
        ...state,
        loading: true,
        // isLeadsListFetched: false,
        isLeadHandledByUpdated: false,
      };
      break;

    case types.UPDATE_LEAD_HANDLEDBY_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_UPDATED_LEADS_FULFILLED:
      // console.log('working')
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        LeadsList: action.payload,
        // isLeadsListFetched: true,
        // isLeadHandledByUpdated: true,
      };
      break;

    case types.GET_UPDATED_LEADS_PENDING:
      // console.log('working')
      state = {
        ...state,
        loading: true,
        // isLeadsListFetched: false,
        // isLeadHandledByUpdated: false,
      };
      break;

    case types.GET_UPDATED_LEADS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.RESET_STATE:
      state = {
        ...state,
        loading: false,
        isLeadCreated: false,
      };
      break;

    default:
      state = {
        ...state,
      };
  }
  return state;
}
