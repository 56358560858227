import axios from "axios";
import { serverAddress } from "../../constants.js";
const sessionValue = JSON.parse(localStorage.getItem("data"));
const token = sessionValue?.loginData.user.token;
const utils = {
  getAllQuotations: (params) => {
    let url =
      serverAddress +
      `/sale/all?page=${params.page}&limit=${params.limit}&search=${
        params.search ? params.search : ""
      }`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  addNewQuotation: (params) => {
    if (params.productLines !== null && params.salesPerson !== "") {
      //code to call server api
      let url = serverAddress + "/sale";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  deleteRevision: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/sale/rev/${params.id}`;
      return axios.delete(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getQuotationDetails: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/sale?uuid=${params.id}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  createRevision: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/sale";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getDownloadLink: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/sale/download`;
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
  getQuotationsByLeadId: (params) => {
    if (params.token !== undefined) {
      let url = serverAddress + `/sale/lead/${params.id}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
};

export default utils;
