import React from "react";
import { ProformaList } from "../pages/export/ProformaList";
import Loader from "../../assets/images/powertech-loader-2.gif";
import { useHistory } from "react-router";
export const ProformaDashboard = (props) => {
  const history = useHistory();
  return (
    <div className="content-wrapper">
      {/*main content will go inside parent div*/}
      <section className="content-header ">
        <div className="row">
          <div className="col-md-1 pr-0">
            <button
              type="button"
              className="bck-btn btn btn-outline-primary mt-1"
              onClick={() => history.goBack()}
              style={{
                padding: "6px 10px",
                borderRadius: "10px",
              }}
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-md-10 mr-2 pl-0">
            <div
              className="header-icon mt-1"
              style={{
                "max-width": "2.5rem",
                "max-heigth": "2rem",
                fontSize: "2.5rem",
              }}
            >
              <i className="fa fa-list" />
            </div>
            <div className="header-title ml-5">
              <h1>Proforma List</h1>
              <small>Marketing & Sales</small>
            </div>
          </div>
        </div>
      </section>
      <ProformaList content={props} />
    </div>
  );
};
