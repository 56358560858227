import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  PackingData: [],
  PackingList: [],
  addResponse: [],
  isPackingListFetched: false,
  isPackingDetailsFetched: false,
  isPackingCreated: false,
  isPackingRevisionCreated: false,
  receivedResponse: false,
  message_error: "",
};

export default function PackingReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_ALL_PACKING_FULFILLED:
      state = {
        ...state,
        loading: false,
        PackingList: action.payload,
        receivedResponse: 200,
        isPackingListFetched: true,
      };
      break;
    case types.GET_ALL_PACKING_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPackingListFetched: false,
      };
      break;
    case types.GET_ALL_PACKING_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_PACKING_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        PackingData: action.payload,
        receivedResponse: 200,
        isPackingDetailsFetched: true,
      };
      break;
    case types.GET_PACKING_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPackingDetailsFetched: false,
      };
      break;
    case types.GET_PACKING_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.CREATE_PACKING_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isPackingCreated: true,
      };
      break;
    case types.CREATE_PACKING_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPackingCreated: false,
      };
      break;
    case types.CREATE_PACKING_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        isPackingCreated: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_PACKING_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isPackingRevisionCreated: true,
      };
      break;
    case types.CREATE_PACKING_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPackingRevisionCreated: false,
      };
      break;
    case types.CREATE_PACKING_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_PACKING_DOWNLOAD_LINK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;
    case types.GET_PACKING_DOWNLOAD_LINK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.GET_PACKING_DOWNLOAD_LINK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.RESET_PACKING:
      state = {
        ...state,
        isPackingCreated: false,
        isPackingRevisionCreated: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
