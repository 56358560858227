import { type } from "jquery";
import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  VendorData: [],
  VendorCode: [],
  VendorAddResponse: [],
  VendorsList: [],
  VendorsNameList: [],
  VendorPurchases: [],
  message_error: "",
  receivedResponse: false,
  isVendorAdded: false,
  isVendorDeleted: false,
  isVendorPurchasesFetched: false,
  isVendorCodeFetched: false,
  isVendorContactAdded: false,
  isVendorDetailsUpdated: false,
  isVendorDetailsFetched: false,
  isVendorContactUpdated: false,
  isVendorNameListFetched: false,
  isVendorListFetched: false,
};

export default function VendorReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.ADD_NEW_VENDOR_FULFILLED:
      state = {
        ...state,
        loading: false,
        VendorAddResponse: action.payload,
        receivedResponse: 200,
        isVendorAdded: true,
      };
      break;
    case types.ADD_NEW_VENDOR_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorAdded: false,
      };
      break;
    case types.ADD_NEW_VENDOR_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_VENDOR_DETAILS_PURCHASES_FULFILLED:
      state = {
        ...state,
        loading: false,
        VendorPurchases: action.payload,
        receivedResponse: 200,
        isVendorPurchasesFetched: true,
      };
      break;
    case types.GET_VENDOR_DETAILS_PURCHASES_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorPurchasesFetched: false,
      };
      break;
    case types.GET_VENDOR_DETAILS_PURCHASES_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_VENDORS_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        VendorsList: action.payload,
        receivedResponse: 200,
        isVendorListFetched: true,
      };
      break;
    case types.GET_VENDORS_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorListFetched: false,
      };
      break;
    case types.GET_VENDORS_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_UPDATED_VENDORS_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        VendorsList: action.payload,
        receivedResponse: 200,
        // isVendorListFetched: true,
      };
      break;
    case types.GET_UPDATED_VENDORS_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isVendorListFetched: false,
      };
      break;
    case types.GET_UPDATED_VENDORS_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_VENDOR_DETAILS_FULFILLED:
      state = {
        ...state,
        VendorData: action.payload,
        loading: false,
        receivedResponse: 200,
        isVendorDetailsFetched: true,
      };
      break;
    case types.GET_VENDOR_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorDetailsFetched: false,
      };
      break;
    case types.GET_VENDOR_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: "Vendor Not Found",
        receivedResponse: false,
      };
      break;
    case types.UPDATE_VENDOR_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isVendorDetailsUpdated: true,
      };
      break;
    case types.UPDATE_VENDOR_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorDetailsUpdated: false,
      };
      break;
    case types.UPDATE_VENDOR_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.DELETE_VENDOR_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isVendorDeleted: true,
      };
      break;
    case types.DELETE_VENDOR_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isVendorDeleted: false,
      };
      break;
    case types.DELETE_VENDOR_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.RESET_VENDOR:
      state = {
        ...state,
        isVendorAdded: false,
      };
      break;
    case type.RESET_VENDOR_LIST:
      state = {
        ...state,
        isVendorNameListFetched: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
