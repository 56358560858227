import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  ClientData: [],
  ClientCode: [],
  ClientAddResponse: [],
  ClientsList: [],
  ClientsNameList: [],
  TotalClients: "",
  message_error: "",
  receivedResponse: false,
  isClientAdded: false,
  isClientContactDeleted: false,
  isClientDeleted: false,
  isClientCodeFetched: false,
  isClientContactAdded: false,
  isTotalClientsFetched: false,
  isClientDetailsUpdated: false,
  isClientDetailsFetched: false,
  isClientContactUpdated: false,
  isClientNameListFetched: false,
  isClientListFetched: false,
  isClientDetailsFetchedById: false,
};

export default function ClientReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.ADD_NEW_CLIENT_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClientAddResponse: action.payload,
        receivedResponse: 200,
        isClientAdded: true,
      };
      break;

    case types.ADD_NEW_CLIENT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientAdded: false,
      };
      break;
    case types.ADD_NEW_CLIENT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_TOTAL_CLIENTS_FULFILLED:
      state = {
        ...state,
        loading: false,
        TotalClients: action.payload,
        receivedResponse: 200,
        isTotalClientsFetched: true,
      };
      break;

    case types.GET_TOTAL_CLIENTS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isTotalClientsFetched: false,
      };
      break;
    case types.GET_TOTAL_CLIENTS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.DELETE_CLIENT_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isClientDeleted: true,
      };
      break;
    case types.DELETE_CLIENT_PENDING:
      state = {
        ...state,
        loading: false,
        isClientDeleted: false,
      };
      break;
    case types.DELETE_CLIENT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.DELETE_CLIENT_CONTACT_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isClientContactDeleted: true,
      };
      break;

    case types.DELETE_CLIENT_CONTACT_PENDING:
      state = {
        ...state,
        loading: false,
        isClientContactDeleted: false,
      };
      break;
    case types.DELETE_CLIENT_CONTACT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_CLIENT_CODE_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClientCode: action.payload,
        receivedResponse: 200,
        isClientCodeFetched: true,
      };
      break;

    case types.GET_CLIENT_CODE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientCodeFetched: false,
      };
      break;
    case types.GET_CLIENT_CODE_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_CLIENT_NAMES_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClientsNameList: action.payload,
        receivedResponse: 200,
        isClientNameListFetched: true,
      };
      break;
    case types.GET_CLIENT_NAMES_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientNameListFetched: false,
      };
      break;
    case types.GET_CLIENT_NAMES_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_CLIENT_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClientsList: action.payload,
        receivedResponse: 200,
        isClientListFetched: true,
      };
      break;
    case types.GET_CLIENT_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientListFetched: false,
      };
      break;
    case types.GET_CLIENT_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_UPDATED_CLIENT_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        ClientsList: action.payload,
        receivedResponse: 200,
        // isClientListFetched: true,
      };
      break;
    case types.GET_UPDATED_CLIENT_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isClientListFetched: false,
      };
      break;
    case types.GET_UPDATED_CLIENT_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_CLIENT_DETAILS_FULFILLED:
      state = {
        ...state,
        ClientData: action.payload,
        loading: false,
        receivedResponse: 200,
        isClientDetailsFetched: true,
      };
      break;
    case types.GET_CLIENT_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientDetailsFetched: false,
      };
      break;
    case types.GET_CLIENT_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: "Client Not Found",
        receivedResponse: false,
      };
      break;
    case types.GET_CLIENT_DETAILS_BY_ID_FULFILLED:
      state = {
        ...state,
        ClientData: action.payload,
        loading: false,
        receivedResponse: 200,
        isClientDetailsFetchedById: true,
        isClientDetailsFetched: true,
      };
      break;
    case types.GET_CLIENT_DETAILS_BY_ID_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientDetailsFetchedById: false,
        isClientDetailsFetched: false,
      };
      break;
    case types.GET_CLIENT_DETAILS_BY_ID_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: "Client Info Not Found",
        receivedResponse: false,
        isClientDetailsFetchedById: false,
        isClientDetailsFetched: false,
      };
      break;
    case types.ADD_NEW_CONTACT_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isClientContactAdded: true,
      };
      break;
    case types.ADD_NEW_CONTACT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientContactAdded: false,
      };
      break;
    case types.ADD_NEW_CONTACT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.UPDATE_CLIENT_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isClientDetailsUpdated: true,
      };
      break;
    case types.UPDATE_CLIENT_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isClientDetailsUpdated: false,
      };
      break;
    case types.UPDATE_CLIENT_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.UPDATE_CLIENT_CONTACT_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isClientContactUpdated: true,
      };
      break;
    case types.UPDATE_CLIENT_CONTACT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        UPDATE_CLIENT_CONTACT_PENDING: false,
      };
      break;
    case types.UPDATE_CLIENT_CONTACT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.RESET_CLIENT_STATE:
      state = {
        ...state,
        loading: false,
        isClientAdded: false,
      };
      break;
    case types.RESET_CLIENT_NAME_LIST:
      state = {
        ...state,
        loading: true,
        isClientNameListFetched: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
