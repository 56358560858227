import React from "react";
import axios from "axios";
import { serverAddress } from "../constants";
const sessionValue = JSON.parse(localStorage.getItem("data"));

const token = sessionValue?.loginData?.user?.token;
function ExportDocBtn(props) {
  let link = serverAddress;
  if (props.name === "client") {
    link = `${link}/client/export/doc`;
  }
  if (props.name === "employee") {
    link = `${link}/employee/export/doc`;
  }
  if (props.name === "lead") {
    link = `${link}/lead/export/doc`;
  }
  if (props.name === "vendor") {
    link = `${link}/vendor/export/doc`;
  }
  if (props.name === "purchase-order") {
    link = `${link}/purchase/export/doc`;
  }
  if (props.name === "job-order") {
    link = `${link}/order/export/doc`;
  }
  if (props.name === "mrn") {
    link = `${link}/mrn/export/doc`;
  }
  if (props.name === "item") {
    link = `${link}/item/export/doc`;
  }
  if (props.name === "item_ledger") {
    link = `${link}/item/get-item-ledger/${props.item_id}?created_at=${
      props.created_at
    }&export_requested=${true}`;
  }
  const handleExportDoc = async (e) => {
    e.preventDefault();
    let config;
    if (props.token) {
      config = {
        headers: { Authorization: `Bearer ${props.token}` },
        params: { folderName: props.name },
      };
    } else {
      config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { folderName: props.name },
      };
    }

    if (props.name === "item_ledger") {
      await axios
        .get(link, config)
        .then((res) => window.open(res.data.url))
        .catch((err) => {
          throw err;
        });
    } else {
      await axios
        .post(link, {}, config)
        .then((res) => window.open(res.data.url))
        .catch((err) => {
          throw err;
        });
    }
  };

  return (
    <div>
      <form onSubmit={(e) => handleExportDoc(e)}>
        <button
          type="submit"
          className="d-flex float-right mt-n5 ml-n2 btn btn-primary"
        >
          Export
        </button>
      </form>
    </div>
  );
}

export default ExportDocBtn;
