import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Link } from "react-router-dom";
import { ERROR_MESSAGES } from "../../../constants";
import { Table, Pagination, Dropdown, Menu, Radio, Space } from "antd";
// import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
export const ProformaList = (props) => {
  let history = useHistory();

  const [proformaList, SetproformaList] = useState(
    props.content.proformaData.ProformaList.proformaData
  );
  useEffect(() => {
    SetproformaList(props.content.proformaData.ProformaList.proformaData);
  }, [props.content.proformaData.ProformaList.proformaData]);

  const totalRecords = props.content.proformaData.ProformaList.totalProforma;
  const [search, setSearch] = useState("");
  const [recperpage, SetRecPerPage] = useState(props.content.state.limit);
  const [activepage, SetActivePage] = useState(props.content.state.page);
  const sno = recperpage * (activepage - 1);

  function searchBoxChange(e) {
    // SetOrders(
    //   list.filter(
    //     (order) =>
    //       order.client_name
    //         .toLowerCase()
    //         .includes(e.target.value.toLowerCase()) ||
    //       order.case_owner.toLowerCase().includes(e.target.value.toLowerCase())
    //   )
    // );
    e.preventDefault();
    if (search.trim() === "") {
      // If the search input is empty, reset the state
      SetRecPerPage(props.content.state.limit);
      SetActivePage(props.content.state.page);
      props.content.handleSearch(search.toLowerCase());
    } else {
      // If the search input is not empty, perform the search
      props.content.handleSearch(search.toLowerCase());
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      searchBoxChange(e);
    }
  }

  function applyFilter(e) {
    SetRecPerPage(e.target.value);
    props.content.handlePagination(1, e.target.value);
    SetActivePage(1);
  }

  function handlePageChange(pageNumber) {
    props.content.handlePagination(pageNumber, recperpage);
    SetActivePage(pageNumber);
  }

  function redirectToExportDetails(e, unique_id) {
    e.preventDefault();
    let path = `/proforma/details/${unique_id}`;
    history.push(path);
  }

  const columns = [
    {
      title: "S.No",
      width: "5%",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return sno + index + 1;
      },
    },
    {
      title: "Proforma No",
      dataIndex: "proforma_no",
      key: "proforma_no",
      render: (text, record, index) => (
        <div
          className="table-td-div"
          style={{ cursor: "pointer" }}
          onClick={(event) => redirectToExportDetails(event, record.id)}
        >
          <div className="table-td-div-inner">
            <span>{record.proforma_no}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Bill To",
      dataIndex: "bill_to",
      key: "bill_to",
      render: (text, record, index) => (
        <div
          className="table-td-div"
          style={{ cursor: "pointer" }}
          onClick={(event) => redirectToExportDetails(event, record.id)}
        >
          <div className="table-td-div-inner">
            <span>{record.bill_to}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (text, record, index) => (
        <div
          className="table-td-div"
          style={{ cursor: "pointer" }}
          onClick={(event) => redirectToExportDetails(event, record.id)}
        >
          <div className="table-td-div-inner">
            <span>{record.invoice_date}</span>
          </div>
        </div>
      ),
    },
  ];

  const data = [];
  if (proformaList) {
    proformaList.map((item, index) => {
      data.push({
        key: index,
        sno: sno + index + 1,
        id: item._id,
        proforma_no: `PRO-INV-${item.invoiceNo}`,
        bill_to: item.billTo,
        invoice_date: format(new Date(item.createdAt), "dd-MM-yyyy"),
      });
    });
  }
  // function getBillTO(i) {
  //   let billTo = "";
  //   proformaList[i].exportData.map((item) => {
  //     if (item?.label === "Bill To") {
  //       billTo = item?.bill_to?.value;
  //     }
  //   });
  //   return billTo;
  // }

  return (
    <section className="content">
      <div className="row">
        <div className="col-lg-12 pinpin px-5">
          <div className="card lobicard" data-sortable="true">
            <div className="card-body">
              <div className="table minheight350">
                <div style={{ marginBottom: "30px" }}>
                  <div className="row">
                    {}
                    {/* <div className="col-md-4 form-inline">
                      <label className="control-label">
                        Records per page :
                      </label>
                      <select
                        className="ml-5 form-control"
                        onChange={(event) => applyFilter(event)}
                        value={recperpage}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                      </select>
                    </div> */}
                    <div className="col-md-5">
                      <div className="row col-md-12">
                        <div className="col-md-3 d-flex align-items-center">
                          <label
                            className="control-label"
                            // style={{ lineHeight: "2.0", marginTop: "1px" }}
                          >
                            Search :
                          </label>
                        </div>
                        <div className="input-group col-md-9">
                          {/* <form>
                            <input
                              type="text"
                              placeholder="Search by Bill to Client"
                              style={{ width: "100%" }}
                              className="form-control"
                              onChange={(event) => searchBoxChange(event)}
                            />
                          </form> */}
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search by bill to client"
                            aria-label="Search by bill to client"
                            aria-describedby="basic-addon2"
                            onChange={(event) => setSearch(event.target.value)}
                            onKeyPress={handleKeyPress}
                            defaultValue={props?.content?.state?.search}
                          />

                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-primary"
                              onClick={(event) => searchBoxChange(event)}
                              type="button"
                            >
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <Link to="/proforma/new">
                        <button className="btn btn-primary" type="button">
                          <i
                            className="fa fa-plus-circle "
                            aria-hidden="true"
                          ></i>{" "}
                          Add Proforma
                        </button>
                      </Link>
                    </div>
                    <div className="col-md-3 form-inline">
                      <div className="row align-items-center">
                        <div className="col-md-4">
                          <label className="control-label">Show :</label>
                        </div>
                        <div className="col-md-4">
                          <select
                            className="form-control"
                            onChange={(event) => applyFilter(event)}
                            value={recperpage}
                          >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                        <div
                          className="col-md-4"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          per page
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  //   scroll={{ x: 1500, y: 300 }}
                />

                <div className="mt-3">
                  <Pagination
                    total={totalRecords}
                    // showSizeChanger
                    // showQuickJumper
                    // defaultPageSize={5}
                    // pageSizeOptions={["5", "10", "25", "50", "100"]}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      handlePageChange(page);
                    }}
                  />
                </div>
                {/* <table
                  id="dataTableExample1"
                  className="table  table-hover table-white-space"
                >
                  <thead className="back_table_color">
                    <tr className="info">
                      <th>S.No</th>
                      <th>Invoice Number</th>
                      <th>Bill To</th>
                      <th>Ship To</th>
                      <th>Created At </th>
                    </tr>
                  </thead>
                  <tbody>
                    {proformaList && proformaList.length > 0 ? (
                      proformaList
                        // .slice(
                        //   recperpage * (activepage - 1),
                        //   recperpage * activepage
                        // )
                        .map((exp, index) => {
                          if (exp !== undefined) {
                            return (
                              <>
                                <tr
                                  key={index + 1}
                                  onClick={(e) =>
                                    redirectToExportDetails(e, exp._id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{sno + index + 1}</td>
                                  <td>
                                    EXP-INV-
                                    {exp.invoiceNo ? exp.invoiceNo : null}
                                  </td>
                                  <td>
                                    {/* {exp.billTo.invoice_to
                                      ? exp.billTo.invoice_to
                                      : null} */}
                {/* {getBillTO(index)} */}
                {/* {exp.bill_to ? exp.bill_to : null}
                                  </td>
                                  <td>
                                    {/* {exp.shipTo.importer
                                      ? exp.shipTo.importer
                                      : null} */}
                {/* </td>
                                  <td>
                                    {exp.createdAt
                                      ? format(
                                          new Date(exp.createdAt),
                                          "dd-MM-yyyy"
                                        )
                                      : null}
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        }) */}
                {/* ) : (
                      <tr>
                        <td colSpan={7}>
                          {ERROR_MESSAGES.NO_DATA_IN_CURRENT_SECTION}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table> */}
                {/* {!_.isEmpty(proformaList) ? (
                  <div className="pagination-div">
                    <Pagination
                      firstPageText={
                        <i
                          className="fa fa-angle-double-left"
                          aria-hidden="true"
                        />
                      }
                      lastPageText={
                        <i
                          className="fa fa-angle-double-right"
                          aria-hidden="true"
                        />
                      }
                      prevPageText={
                        <i className="fa fa-angle-left" aria-hidden="true" />
                      }
                      nextPageText={
                        <i className="fa fa-angle-right" aria-hidden="true" />
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={activepage}
                      itemsCountPerPage={recperpage}
                      totalItemsCount={totalRecords}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
