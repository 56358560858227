import * as types from "../../constants";
import QuotationAPI from "../../utils/api/QuotationAPI";

export function addNewQuotation(params) {
  return {
    type: types.ADD_NEW_QUOTATION,
    payload: QuotationAPI.addNewQuotation(params).then((response) => {
      return response.data;
    }),
  };
}

export function deleteRevision(params) {
  return {
    type: types.DELETE_REVISION,
    payload: QuotationAPI.deleteRevision(params).then((response) => {
      return response.data;
    }),
  };
}

export function getQuotationDetails(params) {
  return {
    type: types.GET_QUOTATION_DETAILS,
    payload: QuotationAPI.getQuotationDetails(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllQuotations(params) {
  return {
    type: types.GET_QUOTATION_LIST,
    payload: QuotationAPI.getAllQuotations(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedQuotations(params) {
  return {
    type: types.GET_UPDATED_QUOTATION_LIST,
    payload: QuotationAPI.getAllQuotations(params).then((response) => {
      return response.data;
    }),
  };
}

export function createRevision(params) {
  return {
    type: types.CREATE_REVISION,
    payload: QuotationAPI.createRevision(params).then((response) => {
      return response.data;
    }),
  };
}

export function getQuotationsByLeadId(params) {
  // console.log(params)
  return {
    type: types.GET_QUOTATION_DETAILS_BY_LEAD_ID,
    payload: QuotationAPI.getQuotationsByLeadId(params).then((response) => {
      return response.data;
    }),
  };
}

export function getDownloadLink(params) {
  // console.log(params)
  return {
    type: types.GET_SALES_DOWNLOAD_LINK,
    payload: QuotationAPI.getDownloadLink(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetQuotation() {
  return {
    type: types.RESET_QUOTATION,
  };
}
