import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";

import { Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
// import { SalesSideBar } from "../../components/sales/SalesSideBar";
// import SalesHeader from "../../components/sales/SalesHeader";
import Loader from "../../assets/images/powertech-loader-2.gif";
import { OpenClickableNotification } from "../../components/shared/OpenClickableNotification";
import { OpenNotification } from "../../components/shared/OpenNotification";
import {
  NOTIFICATION,
  VALIDATION_MESSAGES,
  SUCCESS_MESSAGES,
  ERROR_MESSAGES,
  ROLE,
  serverAddress,
} from "../../constants";
import {
  initialNotificationLine,
  initialProductLine,
  initialExport,
  initialShippingLine,
  initialBankDetailsLine,
  initialbillToLine,
  initialShipToLine,
} from "../../components/shared/data/initialExportData";
import { validation } from "../../utils/validation";
import { CreateProforma } from "../../components/pages/export/CreateProforma";
import {
  getAllClientNames,
  addNewClient,
} from "../../redux/actions/ClientActions";
import {
  getExportById,
  getAllExports,
  createExportRevision,
  createFinalExport,
  getDownloadLink,
} from "../../redux/actions/ExportActions";
import {
  getAllQuotations,
  getQuotationDetails,
} from "../../redux/actions/QuotationActions";
import {
  createNewProforma,
  resetExport,
  getAllBanks,
} from "../../redux/actions/ProformaActions";
import { logOut } from "../../redux/actions/LoginActions";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import PreviewPdf from "../../components/pages/export/ExportPreviewPdf";
import ProformaPreviewPdf from "../../components/pages/export/ProformaPreviewPdf";
let sessionValue = JSON.parse(localStorage.getItem("data"));
let token = sessionValue?.loginData.user.token;

class ExportDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      countryCode: "91",
      alt_countryCode: "91",
      title: "Mr",
      limit: 10,
      page: 1,
      currency: "",
      amount_paid: 0,
      already_paid: 0,
      subTotal: 0,
      showPdf: false,
      bill_to: {},
      balance: 0,
      bill_edit: false,
      invoiceDate: null,
      validation_error: {
        company_name_error: null,
        contact_person_name: null,
        contact_person_email: null,
        contact_person_mobile: null,
        category: null,
        type: null,
        // program_error: null,
        // source_error: null,
        // follow_up_body_error: null,
        form_error: false,
        token,
      },
    };
  }
  async componentWillMount() {
    sessionValue = JSON.parse(localStorage.getItem("data"));
    const { match } = this.props;
    const { id } = match.params;

    if (sessionValue === null) {
      this.props.history.push("/");
    } else if (
      sessionValue.loginData.user.role.toLowerCase() !== ROLE.Orders &&
      sessionValue.loginData.user.role.toLowerCase() !== ROLE.Admin
    ) {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Unauthorised",
        description: ERROR_MESSAGES.UN_AUTHORISED_PAGE,
        duration: 4,
      };
      OpenNotification(notificationData);
      this.props.history.push(
        `/${sessionValue.loginData.user.role.toLowerCase()}`
      );
    } else {
      this.setState(
        {
          token: sessionValue.loginData.user.token,
        },
        () => {
          let params = {
            id: id,
            final: true,
            token: this.state.token,
            page: this.state.page,
            limit: this.state.limit,
          };
          // this.props.getAllQuotations(params);
          this.props.getExportById(params);
          this.props.getAllExports(params);

          // this.props.getAllClientNames(params);
          this.props.getAllBanks({ token: params.token });
        }
      );
    }
  }

  componentDidMount() {
    // this.props.getAllClientNames();
    // const {match}=this.props;
    // const {id}= match.params;
    // console.log(id);
    // localStorage.setItem("proformaData", JSON.stringify(this.props.exportData.ExportData.data.exportData));
    // console.log(JSON.parse(localStorage.getItem("proformaData"))[3]);
    // console.log(JSON.parse(localStorage.getItem("exportData")))
    // this.setState({
    //   balance:
    //     parseInt(this.state.subTotal) -
    //     parseInt(this.state.amount_paid) -
    //     parseInt(this.state.already_paid),
    // });
    // setTimeout(() => {
    // console.log(this.props.exportData.ExportData.data);
    // console.log(this.state.subTotal)
    // console.log(this.state.amount_paid)
    // console.log(this.state.already_paid)
    //   this.props.exportData.ExportData.data.exportData.map((item) => {
    //     if (!Array.isArray(item)) {
    //       console.log(item?.label + "======");
    //       Object.keys(item).forEach((key, index) => {
    //         console.log(key, index + "keyy-------");
    //         console.log(
    //           item[key].label +
    //             "labell-------" +
    //             item[key].value +
    //             "---------valueeeeeee"
    //         );
    //       });
    //     } else {
    //       item.forEach((i) => {
    //         Object.keys(i).forEach((key) => {
    //           console.log(key + "====" + i[key]);
    //         });
    //       });
    //     }
    //   });
    //   let total;
    // }, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.exportData.isExportDetailsFetched !==
      this.props.exportData.isExportDetailsFetched
    ) {
      if (this.props.exportData.isExportDetailsFetched) {
        let total = this.props.exportData.ExportData.data.exportData
          .slice(-1)[0]
          ?.map((item, index) => {
            const total = +item["rate"] * item["quantity"];
            return total;
          });

        this.setState({ subTotal: parseInt(total) });

        // Update the already_paid state and calculate the balance
        this.setState((prevState) => {
          const balance =
            parseInt(total) -
            parseInt(prevState.amount_paid) -
            parseInt(this.props.exportData.ExportData.data.amount_paid);
          console.log(
            parseInt(total),
            parseInt(prevState.amount_paid),
            parseInt(this.props.exportData.ExportData.data.amount_paid),
            balance
          );
          return {
            already_paid: parseInt(this.props.exportData.already_paid),
            balance: parseInt(balance),
          };
        });
      }
    }
  }
  componentWillUnmount() {
    this.props.resetExport();
  }

  handleChange = (e) => {
    validation.validate_new_client_from(e.target, this.state.validation_error);
    this.setState({ [e.target.name]: e.target.value });
    // console.log(this.state);
  };

  // handleQuotationSelect = (value) => {
  //   let params = {
  //     token: this.state.token,
  //     id: value,
  //   };
  //   this.props.getQuotationDetails(params);
  // };

  handleClientSelect = (value) => {
    // let params = {
    //   token: this.state.token,
    //   id: value,
    // };
    // this.props.getQuotationDetails(params);
    // console.log(value);
  };

  // handlebillChange = (value) => {
  //   localStorage.setItem('bill_to',JSON.stringify(this.state.bill_to));

  // }

  handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    if (!_.isEmpty(data)) {
      // localStorage.clear();
      this.props.history.push("/");
      this.props.logOut();
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };

  handleProformaSubmit = async (e) => {
    // const {match}=this.props;
    // const {id}= match.params;
    // localStorage.setItem("bill_to", JSON.stringify(this.state.bill_to));
    if (!this.state.invoiceDate) {
      window.alert("Invoice Date Can not be empty");
      return;
    }
    let save = await this.setState({
      balance:
        this.props.exportData.ExportData.data.exportData
          .slice(-1)[0]
          ?.map((item, index) => {
            const total = +item["rate"] * item["quantity"];
            return total;
          }) -
        this.state.amount_paid -
        this.state.already_paid,
    });
    // localStorage.setItem("balance", this.state.balance);
    // const modifiedIndex =
    //   this.props.exportData.ExportData.data.exportData.findIndex(
    //     (item) => item["label"] === "Bill To"
    //   );

    // const modified =
    //   modifiedIndex !== -1
    //     ? [...this.props.exportData.ExportData.data.exportData]
    //     : [
    //         ...this.props.exportData.ExportData.data.exportData
    //           .slice(0, -2)
    //           .map((item, index) => {
    //             if (item["label"] === "Bill To") {
    //               return (item = this.state.bill_to);
    //             } else {
    //               return item;
    //             }
    //           }),
    //       ];

    // const final = modified;
    //  console.log(this.state.bill_to["Invoice To"]);

    // console.log(this.state.bill_to);
    const params = {
      exportData: this.props.exportData.ExportData.data.exportData,
      currency: this.props.exportData.ExportData.data.currency,
      already_paid: this.props.exportData.ExportData.data.amount_paid,
      invoiceDate: this.state.invoiceDate,
      bill_to: this.state.bill_to["Invoice To"],
      subTotal: parseInt(this.state.subTotal),
      amount_paid: this.state.amount_paid,
      exportInvoiceId: this.props.match.params.id,
    };

    this.props
      .createNewProforma(params)
      .then((result) => {
        this.setState({ showPdf: true });
        if (result.value.msg === "Proforma Created") {
          const notificationData = {
            type: NOTIFICATION.SUCCESS,
            title: "Success",
            description: SUCCESS_MESSAGES.PROFORMA_ADDED_SUCCESSFULLY,
            duration: 6,
            link: result.value.downloadLink,
          };
          OpenClickableNotification(notificationData);
          localStorage.removeItem("exportData");
          localStorage.removeItem("currency");
          localStorage.removeItem("invoiceDate");
          localStorage.removeItem("amountPaid");
          this.props.history.push("/proforma/list");
          // this.props.history.push(
          //   `/export/packing/?id=${result.value.data._id}&final=${
          //     url === "new" ? false : true
          //   }`
          // );
        }
      })
      .catch((err) => {
        const notificationData = {
          type: NOTIFICATION.ERROR,
          title: "Error",
          description: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
          duration: 4,
        };
        OpenNotification(notificationData);
        console.log(err);
      });
  };

  resetQuotation = () => {
    this.props.resetQuotation();
    this.props.resetNameList();
    setTimeout(() => {
      this.props.getAllClientNames({ token });
    }, 200); //call for name list after 200ms
  };

  handleBack = () => {
    this.setState({ showPdf: false });
  };
  handleNext = () => {
    if (!this.state.amount_paid || !this.state.invoiceDate) {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Error",
        description: VALIDATION_MESSAGES.CHECK_FORM_FIELDS,
        duration: 4,
      };
      OpenNotification(notificationData);
      return;
    }

    if (Object.keys(this.state.bill_to).length == 0) {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Error",
        description: "Please Fill New Billing Details",
        duration: 4,
      };
      OpenNotification(notificationData);
      return;
    }
    this.setState({ showPdf: true });
  };

  // handleExportSubmit = (
  //   props,
  //   currency,
  //   date,
  //   billto,
  //   subTotal,
  //   amountPaid
  // ) => {
  //   let validation_error = { ...this.state.validation_error };
  //   if (props.length > 0) {
  //     const params = {
  //       exportData: this.props?.exportData?.ExportData?.data?.exportData,
  //       currency: this.props.exportData.ExportData.data.currency,
  //       invoiceDate: this.state.invoiceDate,
  //       bill_to: this.state.bill_to,
  //       subTotal: this.state.balance + this.state.amount_paid,
  //       amount_paid: this.state.amount_paid,
  //     };
  //     // console.log(params);
  //     this.props
  //       .createNewProforma(params)
  //       .then((result) => {
  //         if (result.value.msg === "Proforma Created") {
  //           const notificationData = {
  //             type: NOTIFICATION.SUCCESS,
  //             title: "Success",
  //             description: SUCCESS_MESSAGES.PROFORMA_ADDED_SUCCESSFULLY,
  //             duration: 6,
  //             link: result.value.downloadLink,
  //           };
  //           OpenClickableNotification(notificationData);
  //           localStorage.removeItem("exportData");
  //           localStorage.removeItem("currency");
  //           localStorage.removeItem("invoiceDate");
  //           localStorage.removeItem("amountPaid");
  //           this.props.history.push("/proforma/list");
  //           // this.props.history.push(
  //           //   `/export/packing/?id=${result.value.data._id}&final=${
  //           //     url === "new" ? false : true
  //           //   }`
  //           // );
  //         }
  //       })
  //       .catch((err) => {
  //         const notificationData = {
  //           type: NOTIFICATION.ERROR,
  //           title: "Error",
  //           description: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
  //           duration: 4,
  //         };
  //         OpenNotification(notificationData);
  //         //console.log(err);
  //       });
  //   } else {
  //     const notificationData = {
  //       type: NOTIFICATION.ERROR,
  //       title: "Form Error",
  //       description: VALIDATION_MESSAGES.SOMETHING_WENT_WRONG,
  //       duration: 4,
  //     };
  //     OpenNotification(notificationData);
  //     this.setState({ validation_error });
  //   }
  // };

  // handleClientSubmit = (e, props) => {
  //   e.preventDefault();
  //   let validation_error = { ...this.state.validation_error };
  //   if (props.hasOwnProperty("company_name") && props.hasOwnProperty("type")) {
  //     validation_error["form_error"] = false;
  //     this.setState({ validation_error }, () => {
  //       let params = {
  //         company_name: props.company_name ? props.company_name : null,
  //         billing_address: props.billing_address ? props.billing_address : null,
  //         shipping_address: props.shipping_address
  //           ? props.shipping_address
  //           : null,
  //         gstin_number: props.gstin_number ? props.gstin_number : null,
  //         first_name: props.contact_person_first_name
  //           ? `${this.state.title && props.title}.${
  //               props.contact_person_first_name &&
  //               props.contact_person_first_name
  //             }`
  //           : null,
  //         last_name: props.contact_person_last_name
  //           ? props.contact_person_last_name
  //           : null,
  //         email_address: props.contact_person_email
  //           ? props.contact_person_email
  //           : null,
  //         phone_number: props.contact_person_mobile
  //           ? `(+${this.state.countryCode}) ${
  //               props.contact_person_mobile && props.contact_person_mobile
  //             }`
  //           : null,
  //         alternate_phone_number: props.contact_person_alternate_mobile
  //           ? `(+${this.state.alt_countryCode}) ${
  //               props.contact_person_alternate_mobile &&
  //               props.contact_person_alternate_mobile
  //             }`
  //           : null,
  //         type: props.type ? props.type : null,
  //         category: props.category ? props.category : null,
  //         token: this.state.token,
  //         // employee_id: this.state.user_id,
  //       };
  //       this.props
  //         .addNewClient(params)
  //         .then((res) => {
  //           if (!res.value.error) {
  //             const notificationData = {
  //               type: NOTIFICATION.SUCCESS,
  //               title: "Success",
  //               description: SUCCESS_MESSAGES.CLIENT_ADDED_SUCCESSFULLY,
  //               duration: 4,
  //             };
  //             OpenNotification(notificationData);
  //             document.getElementById("close_popup").click();
  //           }
  //         })
  //         .catch((err) => {
  //           const notificationData = {
  //             type: NOTIFICATION.ERROR,
  //             title: "Error",
  //             description: ERROR_MESSAGES.CLIENT_ALREADY_EXISTS,
  //             duration: 4,
  //           };
  //           OpenNotification(notificationData);
  //         });
  //     });
  //   } else {
  //     const notificationData = {
  //       type: NOTIFICATION.ERROR,
  //       title: "Form Error",
  //       description: VALIDATION_MESSAGES.CHECK_FORM_FIELDS_CLIENT,
  //       duration: 4,
  //     };
  //     OpenNotification(notificationData);
  //     this.setState({ validation_error });
  //   }
  // };

  render() {
    const { showPdf } = this.state;
    // const { subTotal, amount_paid, already_paid } = this.state;
    // // console.log(this.props.exportData);

    // // Add conditional rendering to display loader or data
    // if (!this.props.exportData.isbankListFetched) {
    //   return <img src={Loader} alt="loader" />;
    // }

    // // Calculate the balance using the latest state values
    // const balance = subTotal - amount_paid - already_paid;
    return (
      // {this.props.exportData.ExportData.exportData}

      // {this.props.exportData.ExportData.data}
      // isExportDetailsFetched

      <div style={{ backgroundColor: "#dfe1e6" }}>
        {!showPdf &&
        this.props.exportData.isbankListFetched &&
        this.props.exportData.isExportDetailsFetched ? (
          <div>
            <h1 className="text-center mb-5 ">Create Proforma</h1>
            <Container>
              <Row className="d-flex justify-content-start row-cols-2">
                <Col style={{ fontWeight: "bold" }}>Invoice Date</Col>
                <Col className="p-0">
                  <input
                    className="w-100"
                    name="invoiceDate"
                    onChange={(e) =>
                      this.setState({ invoiceDate: e.target.value })
                    }
                    value={this.state.invoiceDate}
                    type="date"
                    required
                  />
                </Col>
              </Row>
            </Container>
            {this.props.exportData.ExportData.data.exportData &&
              this.props.exportData.ExportData.data.exportData
                .slice(
                  0,
                  this.props.exportData.ExportData.data.exportData.length - 2
                )
                .map((item, index) => {
                  if (item["label"] !== "Bill To") {
                    return (
                      <>
                        <div class="hr-theme-slash-2 mt-4 mb-2">
                          <div class="hr-line1"></div>
                          <div class="hr-icon">
                            <h6 key={index}>{item["label"]}</h6>
                          </div>
                          <div class="hr-line2"></div>
                        </div>

                        <div class="container">
                          <div class="row row-cols-2">
                            {Object.keys(item).map((key, i) => {
                              if (key !== "label") {
                                return (
                                  <div key={i} className="mt-3">
                                    <span style={{ fontWeight: "bold" }}>
                                      {item[key]["label"]} :
                                    </span>{" "}
                                    {item[key]["value"]}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div class="hr-theme-slash-2 mt-4 mb-2">
                          <div class="hr-line1"></div>
                          <div class="hr-icon">
                            <h6 key={index}>{item["label"]}</h6>
                          </div>
                          <div class="hr-line2"></div>
                        </div>
                        <Container>
                          <Row
                            className="row-cols-2"
                            style={{ position: "relative" }}
                          >
                            {Object.keys(item).map((key, i) => {
                              if (key !== "label") {
                                return (
                                  <>
                                    {!this.state.bill_edit && (
                                      <div key={i} className="mt-3">
                                        <span style={{ fontWeight: "bold" }}>
                                          {item[key]["label"]} :
                                        </span>{" "}
                                        {item[key]["value"]}
                                      </div>
                                    )}

                                    {this.state.bill_edit && (
                                      <Form.Group
                                        className="mb-3 px-2"
                                        controlId="exampleForm.ControlInput1"
                                      >
                                        <Form.Label>
                                          {item[key]["label"]}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          onChange={(e) => {
                                            const updatedBillTo = {
                                              ...this.state.bill_to,
                                            };
                                            updatedBillTo[item[key]["label"]] =
                                              e.target.value;
                                            this.setState({
                                              bill_to: updatedBillTo,
                                            });
                                          }}
                                          value={
                                            this.state.bill_to[
                                              item[key]["label"]
                                            ]
                                          }
                                          placeholder={item[key["value"]]}
                                        />
                                      </Form.Group>
                                    )}
                                    {!this.state.bill_edit && (
                                      <Button
                                        style={{
                                          position: "absolute",
                                          right: "10px",
                                          top: "20%",
                                        }}
                                        onClick={() =>
                                          this.setState({ bill_edit: true })
                                        }
                                      >
                                        ADD
                                      </Button>
                                    )}
                                  </>
                                );
                              }
                            })}
                          </Row>
                        </Container>
                      </>
                    );
                  }
                })}
            <table class="table table-bordered mt-5">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Description</th>
                  <th scope="col">HSN No</th>
                  <th scope="col">Units</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Rate</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(exportData.slice(-1)[0])} */}
                {this.props.exportData.ExportData.data.exportData.slice(
                  -1
                )[0] &&
                  this.props.exportData.ExportData.data.exportData
                    .slice(-1)[0]
                    ?.map((item, index) => (
                      <tr>
                        <td>{item["item"]}</td>
                        <td>{item["description"]}</td>
                        <td>{item["hsn_no"]}</td>
                        <td>{item["units"]}</td>
                        <td>{item["quantity"]}</td>
                        <td>{item["rate"]}</td>
                        <td>{(item["rate"], item["quantity"])}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <Container
              className="text-start bg-white "
              style={{
                fontWeight: "600",
                border: "1px solid black",
                borderRadius: "4px",
                marginBlock: "20px",
              }}
            >
              <Row className="mb-3  p-3">
                <Col>Total Amount</Col>
                <Col>
                  {" "}
                  {this.props.exportData.ExportData.data.exportData
                    .slice(-1)[0]
                    ?.map((item, index) => {
                      const total = +item["rate"] * item["quantity"];
                      return total.toFixed(2);
                    })}{" "}
                  {"  "}
                  {this.props.exportData.ExportData.data.currency}
                </Col>
              </Row>
              <Row className="mb-3  p-3">
                <Col>Amount Received</Col>
                <Col>
                  {this.props.exportData.ExportData.data.amount_paid.toFixed(2)}{" "}
                  {this.props.exportData.ExportData.data.currency}
                </Col>
              </Row>
              <Row className="mb-3  p-3">
                <Col>Amount Payable </Col>
                <Col>
                  <input
                    type="number"
                    style={{ fontSize: "16px", borderRadius: "5px" }}
                    name="amount_paid"
                    onChange={(e) =>
                      this.setState({ amount_paid: e.target.value })
                    }
                    value={this.state.amount_paid}
                  />{" "}
                  {"  "}
                  {this.props.exportData.ExportData.data.currency}
                </Col>
              </Row>
              <Row className="mb-3  p-3">
                <Col>Balance Amount </Col>
                <Col>
                  {this.state.balance - this.state.amount_paid >= 0 ? (
                    (this.state.balance - this.state.amount_paid).toFixed(2)
                  ) : (
                    <p className="text-danger bg-white">
                      Please Enter A Valid Amount Payable
                    </p>
                  )}{" "}
                  {this.props.exportData.ExportData.data.currency}
                </Col>
              </Row>
              <Row style={{ height: "40px" }}>
                <Col></Col>
              </Row>
              {/* <Row style={{ height: "40px" }}>
                <Col>Amount Already Paid Previously</Col>
                <Col>{this.props.exportData.ExportData.data.amount_paid} {this.props.exportData.ExportData.data.currency}</Col>

              </Row> */}
              <Row className="mb-3">
                <Col></Col>
                <Col>
                  <Button
                    variant="dark"
                    className="rounded-0 px-5"
                    onClick={this.handleNext}
                    style={{ boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)" }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          !showPdf && (
            <img
              src={Loader}
              alt="logo"
              className="mx-auto d-block loaderImage"
            />
          )
        )}

        {showPdf && (
          <Container fluid>
            <Row className="mb-3 row-cols-1 w-100">
              <Col>
                <ProformaPreviewPdf
                  data={this.props.exportData.ExportData.data.exportData}
                  subTotal={this.state.subTotal}
                  currency={this.props.exportData.ExportData.data.currency}
                  date={this.state.invoiceDate}
                  usage="proforma"
                  already_paid={
                    this.props.exportData.ExportData.data.amount_paid
                  }
                  amount_paid={this.state.amount_paid}
                />
              </Col>
            </Row>
            <Row className="justify-content-end mr-2">
              <Col className="d-flex justify-content-end">
                <Button className="bg-secondary mr-2" onClick={this.handleBack}>
                  <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                </Button>
                <Button onClick={this.handleProformaSubmit}>Save</Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    clientData: state.ClientReducer,
    userData: state.LoginReducer,
    exportData: state.ExportReducer,
    quotationData: state.QuotationReducer,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAllQuotations: (params) => dispatch(getAllQuotations(params)),
    getExportById: (params) => dispatch(getExportById(params)),
    getAllExports: (params) => dispatch(getAllExports(params)),
    getAllBanks: (params) => dispatch(getAllBanks(params)),
    addNewClient: (params) => dispatch(addNewClient(params)),
    getQuotationDetails: (params) => dispatch(getQuotationDetails(params)),
    createNewProforma: (params) => dispatch(createNewProforma(params)),
    getAllClientNames: (params) => dispatch(getAllClientNames(params)),
    logOut: () => dispatch(logOut()),
    resetExport: () => dispatch(resetExport()),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportDetails)
);

{
  /*==     <PreviewPdf 
      data={this.props.exportData.ExportData.data.exportData}
      subTotal={this.state.amount_paid}
      currency={this.props.exportData.ExportData.data.currency}
      date={this.state.invoiceDate}
      usage={"proforma"}
      amount_paid={this.state.amount_paid}

    />

    */
}

{
  /* 
<Row className="my-3 " style={{position:'absolute',left:'10px'}}>
                          
<Col className="d-flex justify-content-end align-items-start">
  {!this.state.bill_edit && (
    <Button
  
      onClick={() =>
        this.setState({ bill_edit: true })
      }
    >
      EDIT 
    </Button>
  )}
</Col>
    </Row>
    */
}
