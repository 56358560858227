import React, { Component, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Loader from "../assets/images/powertech-loader-2.gif";
import Routes from "./Routes.jsx";
import "../assets/font-awesome/css/font-awesome.min.css";
import "../assets/dist/css/stylecrm.css";
import "../assets/dist/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "antd/dist/antd.css";
import { NetworkCheck } from "./NetworkHOC";

class App extends Component {
  render() {
    const loading = (
      <div className="col d-flex align-items-center justify-content-center vh-100">
        <img src={Loader} alt="logo" />
      </div>
    );
    return (
      <div className="App">
        <Router>
          <Suspense fallback={loading}>
            <Routes />
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default NetworkCheck(App);
