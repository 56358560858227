export const initialNotificationLine = {
  label: "Notify Party",
  importer: {
    label: "Importer",
    value: "",
  },
  importer_address: {
    label: "Address",
    value: "",
  },
  contact_person_name: {
    label: "Contact Person Name",
    value: "",
  },
  contact_person_number: {
    label: "Contact Person Number",
    value: "",
  },
  contact_person_email: {
    label: "Contact Person Email",
    value: "",
  },
  port_of_discharge: {
    label: "Port of Discharge",
    value: "",
  },
  tin_no: {
    label: "TIN No",
    value: "",
  },
  bin_no: {
    label: "BIN No",
    value: "",
  },
  destination_country: {
    label: "Destination Country",
    value: "",
  },
  irc_no: {
    label: "IRC No",
    value: "",
  },
};

export const initialProductLine = {
  item: "",
  description: "",
  quantity: "1",
  hsn_no: "",
  rate: "0",
  units: "SET",
};

export const initialShipToLine = {
  label: "New Ship To",
  ship_to: {
    label: "Importer",
    value: "",
  },
  shipping_address: {
    label: "Address",
    value: "",
  },
  contact_person_name_ship: {
    label: "Contact Person Name",
    value: "",
  },
  contact_person_number_ship: {
    label: "Contact Person Number",
    value: "",
  },
  contact_person_email_ship: {
    label: "Contact Person Email",
    value: "",
  },
  port_of_discharge_ship: {
    label: "Port Of Discharge",
    value: "",
  },
  destination_country_ship: {
    label: "Destination Country",
    value: "",
  },
  final_destination_country_ship: {
    label: "Final Destination Country",
    value: "",
  },
};

export const initialbillToLine = {
  label: "New Bill To",
  bill_to: {
    label: "Invoice To",
    value: "",
  },
  bill_address: {
    label: "Address",
    value: "",
  },
};

export const initialShippingLine = {
  shipping_by: {
    label: "Shipping By",
    value: "",
  },
  label: "New Shipping Section",
  loading_port: {
    label: "Loading Port",
    value: "",
  },
  lcaf_no: {
    label: "LCAF No",
    value: "",
  },
  lc_no: {
    label: "L/C No",
    value: "",
  },
  packing_no: {
    label: "Packing No",
    value: "",
  },
  payment_terms: {
    label: "Payment Terms",
    value: "",
  },
};

export const additionalSection = {
  subTitle1: "",
  subTitle2: "",
  subTitle3: "",
  subTitle4: "",
  subTitle5: "",
  subTitle6: "",
};

export const initialBankDetailsLine = {
  lc_issue_bank: {
    label: "LC Issue Bank",
    value: "",
  },
  label: "Applicant Bank Details",
  applicant_bank_tin_no: {
    label: "TIN No",
    value: "",
  },
  applicant_bank_bin_no: {
    label: "BIN No",
    value: "",
  },
};

export const initialExport = {
  //selected bank details

  bankDetails: [
    {
      label: "Bank Details",
      bank_name: {
        label: "Bank Name",
        value: "",
      },
      bank_account_no: {
        label: "Bank Account No",
        value: "",
      },
      swift_code: {
        label: "Swift Code",
        value: "",
      },
      ad_code: {
        label: "AD Code",
        value: "",
      },
      rtgs_code: {
        label: "RTGS Code",
        value: "",
      },
    },
  ],
  // //selected quotationId
  // quotationId: "",

  //Shipping way details
  shippingLines: [
    {
      shipping_by: {
        label: "Shipping By",
        value: "",
      },
      loading_port: {
        label: "Loading Port",
        value: "",
      },
      lcaf_no: {
        label: "LCAF No",
        value: "",
      },
      lc_no: {
        label: "L/C No",
        value: "",
      },
      label: "Shipping Details",
      packing_no: {
        label: "Packing No",
        value: "",
      },
      payment_terms: {
        label: "Payment Terms",
        value: "",
      },
    },
  ],

  BankDetailLines: [
    {
      lc_issue_bank: {
        label: "LC Issue Bank",
        value: "",
      },
      label: "Applicant Bank Details",
      applicant_bank_tin_no: {
        label: "TIN No",
        value: "",
      },
      applicant_bank_bin_no: {
        label: "BIN No",
        value: "",
      },
    },
  ],

  //Bill To part data
  billToLines: [
    {
      label: "Bill To",
      bill_to: {
        label: "Invoice To",
        value: "",
      },
      bill_address: {
        label: "Address",
        value: "",
      },
    },
  ],

  //Ship To part data
  shipToLines: [
    {
      ship_to: {
        label: "Importer",
        value: "",
      },
      label: "Ship To",
      shipping_address: {
        label: "Address",
        value: "",
      },
      contact_person_name_ship: {
        label: "Contact Person Name",
        value: "",
      },
      contact_person_number_ship: {
        label: "Contact Person Number",
        value: "",
      },
      contact_person_email_ship: {
        label: "Contact Person Email",
        value: "",
      },
      port_of_discharge_ship: {
        label: "Port Of Discharge",
        value: "",
      },
      destination_country_ship: {
        label: "Destination Country",
        value: "",
      },
      final_destination_country_ship: {
        label: "Final Destination Country",
        value: "",
      },
    },
  ],

  //notifyParty Data
  nofityPartyLines: [
    {
      label: "Notify Party",
      importer: {
        label: "Importer",
        value: "",
      },
      importer_address: {
        label: "Address",
        value: "",
      },
      port_of_discharge: {
        label: "Port of Discharge",
        value: "",
      },
      destination_country: {
        label: "Destination Country",
        value: "",
      },
      contact_person_name: {
        label: "Contact Person Name",
        value: "",
      },
      contact_person_number: {
        label: "Contact Person Number",
        value: "",
      },
    },
  ],
  productLines: [
    {
      item: "",
      description: "",
      quantity: "1",
      hsn_no: "",
      rate: "0",
      units: "SET",
    },
  ],
};
