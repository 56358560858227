import * as React from "react";
import { notification } from "antd";

export const OpenClickableNotification = (notificationData) => {
  const { type, title, description, duration, link } = notificationData;
  const btn = (
    <a rel="noreferrer" onClick={() => window.open(link)}>
      Download
    </a>
  );
  notification[type]({
    message: title,
    description: description,
    duration: duration,
    btn: btn,
  });
};
