import * as types from "../../constants";
import LoginAPI from "../../utils/api/LoginAPI";

export function authenticateUser(params) {
  return {
    type: types.LOGIN_DATA,
    payload: LoginAPI.authenticateUser(params).then((response) => {
      return response.data;
    }),
  };
}

export function validateToken(params) {
  return {
    type: types.VALIDATE_TOKEN,
    payload: LoginAPI.validateToken(params).then((response) => {
      return response.data;
    }),
  };
}

export function logOut() {
  return {
    type: types.LOGOUT,
  };
}
