import React, { useState, useEffect } from "react";
import { ProformaForm } from "./ProformaForm";
// import { ExportPreview } from "./ExportPreview";
import $ from "jquery";
import {
  initialNotificationLine,
  initialProductLine,
  initialExport,
  initialShippingLine,
  initialBankDetailsLine,
  initialbillToLine,
  initialShipToLine,
} from "../../shared/data/initialExportData";
import { useHistory } from "react-router-dom";
import PreviewPdf from "./ExportPreviewPdf";
export const CreateProforma = (props) => {
  const banks = props.exportData?.bankList?.data;

  let storedData = JSON.parse(localStorage.getItem("exportData"));

  let storedDate = localStorage.getItem("invoiceDate");
  let storedCurrency = localStorage.getItem("currency");
  let storedAmountPaid = localStorage.getItem("amountPaid");
  // console.log(storedData);
  let history = useHistory();
  const [exportData, setExportData] = useState(
    storedData ? storedData : { ...initialExport }
  );
  const [data, setData] = useState([]);
  const [step, setStep] = useState(1);
  const [subTotal, setSubTotal] = useState();
  const [billto, setBillTo] = useState("");
  const [currency, setCurrency] = useState(
    storedCurrency ? storedCurrency : ""
  );
  const [amountPaid, setAmountPaid] = useState(
    storedAmountPaid ? storedAmountPaid : 0
  );
  const [date, setDate] = useState(storedDate ? storedDate : "");
  const [error, setError] = useState({
    date: false,
    bank: false,
    cur: false,
  });

  const [spinbtn, setSpinbtn] = useState(false);

  useEffect(() => {
    let status = props.exportData.isExportCreated;
    if (status) {
      setSpinbtn(true);
    } else {
      setSpinbtn(false);
    }
  }, [props.exportData.isExportCreated]);

  const initialTerms = [
    {
      type: "bulleted-list",
      children: [
        {
          text: "Supplyment for export under ARN No. : AD0904190026417",
        },
      ],
    },
    {
      type: "bulleted-list",
      children: [
        {
          text: "We hereby certify that the goods have been shipped in accordance with proforma invoice no. EXP/21248 dated 10.07.2019",
        },
      ],
    },
  ];
  const [value, setValue] = useState(initialTerms);

  const handleRemove = (e) => {
    const productLines = exportData.productLines.filter(
      (productLine, index) => index !== e
    );
    setExportData({ ...exportData, productLines });
  };

  const handleHeaderChange = (category, index, value) => {
    switch (category) {
      case "shippingLines":
        const prevData = exportData.shippingLines;
        prevData[index]["label"] = value;
        if (index === 0) {
          value.toLowerCase().includes("shipping")
            ? $("#shipping_line_pop").popover("hide")
            : $("#shipping_line_pop").popover("show");
        }
        const shippingLines = prevData;
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const prevData1 = exportData.BankDetailLines;
        prevData1[index]["label"] = value;
        const BankDetailLines = prevData1;
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const prevData2 = exportData.billToLines;
        prevData2[index]["label"] = value;
        if (index === 0) {
          value.toLowerCase().includes("bill")
            ? $("#billto_line_pop").popover("hide")
            : $("#billto_line_pop").popover("show");
        }
        const billToLines = prevData2;
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const prevData3 = exportData.shipToLines;
        prevData3[index]["label"] = value;
        if (index === 0) {
          value.toLowerCase().includes("ship")
            ? $("#shipto_line_pop").popover("hide")
            : $("#shipto_line_pop").popover("show");
        }
        const shipToLines = prevData3;
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const prevData4 = exportData.nofityPartyLines;
        prevData4[index]["label"] = value;
        if (index === 0) {
          value.toLowerCase().includes("notify")
            ? $("#notify_line_pop").popover("hide")
            : $("#notify_line_pop").popover("show");
        }
        const nofityPartyLines = prevData4;
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };

  const handleFieldRemove = (category, index, key) => {
    switch (category) {
      case "shippingLines":
        const prevData = exportData.shippingLines;
        delete prevData[index][key];
        const shippingLines = prevData;
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const prevData1 = exportData.BankDetailLines;
        delete prevData1[index][key];
        const BankDetailLines = prevData1;
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const prevData2 = exportData.billToLines;
        delete prevData2[index][key];
        const billToLines = prevData2;
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const prevData3 = exportData.shipToLines;
        delete prevData3[index][key];
        const shipToLines = prevData3;
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const prevData4 = exportData.nofityPartyLines;
        delete prevData4[index][key];
        const nofityPartyLines = prevData4;
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };

  const handleLabelChange = (category, index, key, value) => {
    switch (category) {
      case "shippingLines":
        // e.preventDefault();
        const prevData = exportData.shippingLines;
        prevData[index][key]["label"] = value;

        const shippingLines = prevData;
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        // e.preventDefault();
        const prevData1 = exportData.BankDetailLines;
        prevData1[index][key]["label"] = value;
        const BankDetailLines = prevData1;
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        // e.preventDefault();
        const prevData2 = exportData.billToLines;
        prevData2[index][key]["label"] = value;
        const billToLines = prevData2;
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        // e.preventDefault();
        const prevData3 = exportData.shipToLines;
        prevData3[index][key]["label"] = value;
        const shipToLines = prevData3;
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        // e.preventDefault();
        const prevData4 = exportData.nofityPartyLines;
        prevData4[index][key]["label"] = value;
        const nofityPartyLines = prevData4;
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };
  const handleFieldChange = (category, index, key, value) => {
    switch (category) {
      case "shippingLines":
        const prevData = exportData.shippingLines;
        prevData[index][key]["value"] = value;
        prevData[index][key]["error"] = false;
        const shippingLines = prevData;
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const prevData1 = exportData.BankDetailLines;
        prevData1[index][key]["value"] = value;
        prevData1[index][key]["error"] = false;
        const BankDetailLines = prevData1;
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const prevData2 = exportData.billToLines;
        prevData2[index][key]["value"] = value;
        prevData2[index][key]["error"] = false;
        const billToLines = prevData2;
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const prevData3 = exportData.shipToLines;
        prevData3[index][key]["value"] = value;
        prevData3[index][key]["error"] = false;
        const shipToLines = prevData3;
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const prevData4 = exportData.nofityPartyLines;
        prevData4[index][key]["value"] = value;
        prevData4[index][key]["error"] = false;
        const nofityPartyLines = prevData4;
        setExportData({ ...exportData, nofityPartyLines });
        break;
      case "bank_name":
        const bank = banks[value];
        const newexportData = exportData.bankDetails;
        newexportData[0]["bank_name"]["value"] = bank.bank_name;
        newexportData[0]["bank_name"]["error"] = false;
        newexportData[0]["bank_account_no"]["value"] = bank.bank_account_no;
        newexportData[0]["swift_code"]["value"] = bank.swift_code;
        newexportData[0]["ad_code"]["value"] = bank.ad_code;
        newexportData[0]["rtgs_code"]["value"] = bank.rtgs_code;
        const bankDetails = newexportData;
        setExportData({ ...exportData, bankDetails });
        break;
      default:
        return 0;
    }
  };

  const handleNewField = (category, index) => {
    let labelIndex = Math.floor(Math.random() * 100);
    switch (category) {
      case "shippingLines":
        const prevData = exportData.shippingLines;
        prevData[index][`new_field${labelIndex}`] = {
          label: `New Field${labelIndex}`,
          value: "",
        };
        const shippingLines = prevData;
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const prevData1 = exportData.BankDetailLines;
        prevData1[index][`new_field${labelIndex}`] = {
          label: `New Field${labelIndex}`,
          value: "",
        };
        const BankDetailLines = prevData1;
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const prevData2 = exportData.billToLines;
        prevData2[index][`new_field${labelIndex}`] = {
          label: `New Field${labelIndex}`,
          value: "",
        };
        const billToLines = prevData2;
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const prevData3 = exportData.shipToLines;
        prevData3[index][`new_field${labelIndex}`] = {
          label: `New Field${labelIndex}`,
          value: "",
        };
        const shipToLines = prevData3;
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const prevData4 = exportData.nofityPartyLines;
        prevData4[index][`new_field${labelIndex}`] = {
          label: `New Field${labelIndex}`,
          value: "",
        };
        const nofityPartyLines = prevData4;
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };

  const handleNewSection = (category) => {
    switch (category) {
      case "shippingLines":
        const shippingLines = [
          ...exportData.shippingLines,
          { ...initialShippingLine },
        ];
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const BankDetailLines = [
          ...exportData.BankDetailLines,
          { ...initialBankDetailsLine },
        ];
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const billToLines = [
          ...exportData.billToLines,
          { ...initialbillToLine },
        ];
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const shipToLines = [
          ...exportData.shipToLines,
          { ...initialShipToLine },
        ];
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const nofityPartyLines = [
          ...exportData.nofityPartyLines,
          { ...initialNotificationLine },
        ];
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };
  const handleDeleteSection = (category, index) => {
    switch (category) {
      case "shippingLines":
        const shippingLines = exportData.shippingLines;
        shippingLines.splice(index, 1);
        setExportData({ ...exportData, shippingLines });
        break;
      case "BankDetailLines":
        const BankDetailLines = exportData.BankDetailLines;
        BankDetailLines.splice(index, 1);
        setExportData({ ...exportData, BankDetailLines });
        break;
      case "billToLines":
        const billToLines = exportData.billToLines;
        billToLines.splice(index, 1);
        setExportData({ ...exportData, billToLines });
        break;
      case "shipToLines":
        const shipToLines = exportData.shipToLines;
        shipToLines.splice(index, 1);
        setExportData({ ...exportData, shipToLines });
        break;
      case "nofityPartyLines":
        const nofityPartyLines = exportData.nofityPartyLines;
        nofityPartyLines.splice(index, 1);
        setExportData({ ...exportData, nofityPartyLines });
        break;
      default:
        return 0;
    }
  };
  // const handleNotifyRemove = (e) => {
  //   const exportLines = exportData.exportLines.filter(
  //     (productLine, index) => index !== e
  //   );
  //   setExportData({ ...exportData, exportLines });
  // };

  // const handleQuotationSelect = (event) => {
  //   props.handleQuotationSelect(event.value)
  // }

  const handleProductLineChange = (index, name, value) => {
    const exportLines = exportData.exportLines.map((exportLine, i) => {
      if (i === index) {
        const newexportLine = { ...exportLine };
        newexportLine[name] = value;
        return newexportLine;
      }
      return { ...exportLine };
    });

    setExportData({ ...exportData, exportLines });
  };
  // const productAddToData = (products) => {
  //   const productLines = products
  //   setExportData({ ...exportData, productLines })
  // }

  // const submitClient = (e, name) => {
  //   e.preventDefault();
  //   props.handleClientSubmit(e, clientData);
  //   handleChange(name, clientData.company_name);
  //   // document.getElementById(name).defaultValue = {
  //   //   label: clientData.company_name,
  //   //   value: clientData.company_name,
  //   // };
  // };

  const handleProductChange = (index, name, value) => {
    const productLines = exportData.productLines.map((productLine, i) => {
      if (i === index) {
        delete productLine["discount"];
        delete productLine["tax"];
        const newProductLine = { ...productLine };
        if (name === "description" || name === "item") {
          newProductLine[name] = value;
          newProductLine[`${name}_error`] = false;
        } else {
          if (
            value[value.length - 1] === "." ||
            (value[value.length - 1] === "0" && value.includes("."))
          ) {
            newProductLine[name] = value;
            newProductLine[`${name}_error`] = false;
          } else {
            const n = typeof value === "number" ? parseFloat(value) : value;
            newProductLine[name] = (n ? n : 0).toString();
            newProductLine[`${name}_error`] = false;
          }
        }
        return newProductLine;
      }
      return { ...productLine };
    });

    setExportData({ ...exportData, productLines });
  };

  // const handleChange = (name, value) => {
  //   if (name !== "bill_to" && name !== "ship_to") {
  //     const newexportData = { ...exportData };
  //     newexportData[name] = value.target.value;
  //     setExportData(newexportData);
  //   }
  //   if (name === "bill_to" || name === "ship_to") {
  //     const newexportData = { ...exportData };
  //     newexportData[name] = value?.label;
  //     newexportData["clientCode"] = value?.value;
  //     setExportData(newexportData);
  //   }
  //   // console.log(invoice);
  //   if (name === "bank_name") {
  //     const bank = banks[value.target.value];
  //     // console.log(value);
  //     const newexportData = { ...exportData };
  //     newexportData["bank_name"] = bank.bank_name;
  //     newexportData["bank_account_no"] = bank.bank_account_no;
  //     newexportData["swift_code"] = bank.swift_code;
  //     newexportData["ad_code"] = bank.ad_code;
  //     newexportData["rtgs_code"] = bank.rtgs_code;
  //     setExportData(newexportData);
  //   }
  // };

  const handleAdd = (e) => {
    e.preventDefault();
    const exportLines = [
      ...exportData.exportLines,
      { ...initialNotificationLine },
    ];
    setExportData({ ...exportData, exportLines });
  };

  const handleProductAdd = (e) => {
    e.preventDefault();
    const productLines = [
      ...exportData.productLines,
      { ...initialProductLine },
    ];
    setExportData({ ...exportData, productLines });
  };

  const cleanData = (data) => {
    let newData = [];
    Object.keys(data).map((key) => {
      // console.log(typeof data[key]);
      if (data[key] && key != "productLines" && typeof data[key] === "object") {
        data[key].map((item) => {
          newData.push(item);
        });
      } else if (typeof data[key] === "object") {
        newData.push(data[key]);
      }
    });
    return newData;
  };

  const getBillTO = (data) => {
    //console.log(data.slice(0, -1));
    // console.log(data);
    let billTo = "";
    data.slice(0, -1).map((item) => {
      if (item.label.toLowerCase().split(" ").join("").includes("billto")) {
        billTo = item?.bill_to?.value;
      }
    });
    setBillTo(billTo);
    return billTo;
  };

  const validate = () => {
    // let error = false;
    let errCount = 0;
    let data = exportData;

    Object.keys(data).map((category, i) => {
      if (category != "productLines") {
        data[category].map((item, index) => {
          Object.keys(item).map((key, i) => {
            if (key != "label") {
              if (item[key].value === "") {
                //console.log(item[key].value, key, "trigerred");
                // error = true;
                errCount++;
                item[key]["error"] = true;
              } else {
                // error = false;
                item[key]["error"] = false;
              }
            }
          });
        });
      } else {
        data[category].map((prod, index) => {
          Object.keys(prod).map((key, i) => {
            if (key != "hsn_no") {
              if (prod[key] === "") {
                // error = true;
                errCount++;
                prod[`${key}_error`] = true;
              } else {
                errCount = errCount;
              }
            }
          });
        });
        //console.log(data[category]);
      }
    });
    if (currency === "") {
      errCount++;
      // error = true;
      setError({ ...error, cur: true });
    }
    if (date === "") {
      errCount++;
      // error = true;
      setError({ ...error, date: true });
    }
    setExportData(data);
    //console.log(errCount);
    return errCount;
  };
  const handleSave = async (e) => {
    e.preventDefault();
    let storeData = exportData;
    await localStorage.setItem("exportData", JSON.stringify(storeData));
    await localStorage.setItem("currency", currency);
    await localStorage.setItem("invoiceDate", date);
    await localStorage.setItem("amountPaid", amountPaid);
    const cleanedData = await cleanData(exportData);
    getBillTO(cleanedData);
    cleanedData.splice(-1, 0, value);
    cleanedData.join();
    setData(cleanedData);
    !validate() && setStep(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinbtn(true);
    props.handleExportSubmit(
      data,
      currency,
      date,
      billto,
      subTotal,
      amountPaid
    );
    setExportData({ ...initialExport });
  };

  const calculateAmount = (quantity, rate) => {
    const quantityNumber = parseFloat(quantity);
    const rateNumber = parseFloat(rate);
    const value = quantityNumber * rateNumber;
    return value.toFixed(2);
  };

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="row">
          <div className="col-md-1 pr-0">
            <button
              type="button"
              className="bck-btn btn btn-outline-primary mt-1"
              onClick={() => history.goBack()}
              style={{
                padding: "6px 10px",
                borderRadius: "10px",
              }}
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-md-11 mr-2 ml-n4">
            <div
              className="header-icon mt-1"
              style={{
                "max-width": "2.5rem",
                "max-heigth": "2rem",
                fontSize: "2.5rem",
              }}
            >
              <i className="fa fa-file-text" />
            </div>
            <div className="header-title ml-5">
              <h1>New Proforma</h1>
              <small>Marketing & Sales</small>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-lg-12 pinpin">
            <div className="card lobicard" data-sortable="true">
              <div className="card-body" style={{ backgroundColor: "#EEEEEE" }}>
                <form id="enquiryLead" className="row">
                  <div className="col-sm-12">
                    <div className="col-sm-12">
                      <div>
                        {step == 1 ? (
                          <>
                            <ProformaForm
                              content={props}
                              exportData={exportData}
                              subTotal={subTotal}
                              error={error}
                              setError={setError}
                              currency={currency}
                              setCurrency={setCurrency}
                              setSubTotal={setSubTotal}
                              setExportData={setExportData}
                              setAmountPaid={setAmountPaid}
                              amountPaid={amountPaid}
                              handleDeleteSection={handleDeleteSection}
                              value={value}
                              setDate={setDate}
                              date={date}
                              setValue={setValue}
                              // handleClientChange={handleClientChange}
                              handleNewField={handleNewField}
                              handleFieldRemove={handleFieldRemove}
                              handleAdd={handleAdd}
                              handleRemove={handleRemove}
                              // submitClient={submitClient}
                              // handleChange={handleChange}
                              // handleNotifyRemove={handleNotifyRemove}
                              handleFieldChange={handleFieldChange}
                              calculateAmount={calculateAmount}
                              handleLabelChange={handleLabelChange}
                              handleNewSection={handleNewSection}
                              handleHeaderChange={handleHeaderChange}
                              // productAddToData={productAddToData}
                              handleProductAdd={handleProductAdd}
                              handleProductChange={handleProductChange}
                              handleProductLineChange={handleProductLineChange}
                            />
                            <div className="col-sm-12 float-right ">
                              <div className=" mt-5 float-right">
                                <button
                                  type="button"
                                  onClick={handleSave}
                                  className="btn btn-primary"
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <ExportPreview
                              data={data}
                              subTotal={subTotal}
                              calculateAmount={calculateAmount}
                            /> */}
                            <PreviewPdf
                              data={data}
                              subTotal={subTotal}
                              currency={currency}
                              date={date}
                              createData={props.exportData}
                              usage="proforma"
                              amount_paid={amountPaid}
                            />

                            <div className="row float-right ">
                              <div className="mt-5 mr-3">
                                <button
                                  type="button"
                                  onClick={() => setStep(1)}
                                  className="btn btn-secondary"
                                >
                                  <i
                                    class="fa fa-arrow-left"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Back
                                </button>
                              </div>
                              {/* <PDFDownloadLink
                                className="btn btn-success mt-5 mr-3"
                                document={
                                  <MyDocument
                                    data={data}
                                    subTotal={subTotal}
                                    currency={currency}
                                    date={date}
                                    createData={props.exportData}
                                  />
                                }
                                fileName={`EX-INV-${format(
                                  new Date(),
                                  "MMM/dd"
                                )}.pdf`}
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download"
                                }
                              </PDFDownloadLink> */}
                              <div className=" mt-5 float-right">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  disabled={spinbtn}
                                  className="btn btn-primary"
                                >
                                  {spinbtn ? (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Saving...
                                    </>
                                  ) : (
                                    "Save"
                                  )}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* {props.exportData.isExportCreated ? (
                        <>
                          <div
                            className={`modal fade ${open && "show"}`}
                            id="exampleModal"
                            tabIndex="-1"
                            style={{
                              display: `${open ? "block" : "none"}`,
                            }}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog  modal-dialog-scrollable modal-lg">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Export Preview
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                      setOpen(false);
                                      history.push("/export/list");
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <PDFViewer width="800px" height="800px">
                                    <MyDocument
                                      data={data}
                                      createData={props.exportData}
                                    />
                                  </PDFViewer>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                      setOpen(false);
                                      history.push("/export/list");
                                    }}
                                  >
                                    Close
                                  </button>
                                  <PDFDownloadLink
                                    className="btn btn-success"
                                    document={
                                      <MyDocument
                                        data={data}
                                        createData={props.exportData}
                                      />
                                    }
                                    fileName={`EX-INV-${
                                      props.exportData.addResponse.data
                                        ?.invoiceNo
                                    }-${exportData.clientCode}-${format(
                                      new Date(),
                                      "MMM/dd"
                                    )}.pdf`}
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? "Loading document..." : "Send"
                                    }
                                  </PDFDownloadLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
