import axios from "axios";
import { serverAddress } from "../../constants.js";
const sessionValue = JSON.parse(localStorage.getItem("data"));
const token = sessionValue?.loginData.user.token;
const utils = {
  getAllProforma: (params) => {
    let url;
    if (params.id) {
      url =
        serverAddress +
        `/proforma/all?page=${params.page}&limit=${params.limit}&search=${
          params.search ? params.search : ""
        }&final=${params.final}&_id=${params.id}
      `;
    } else {
      url =
        serverAddress +
        `/proforma/all?page=${params.page}&limit=${params.limit}&search=${
          params.search ? params.search : ""
        }&final=${params.final}
      `;
    }

    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  addFilesToProforma: (params) => {
    let url = serverAddress + `/proforma/files/${params.export_id}`;
    return axios.put(url, params);
  },
  getAllBanks: (params) => {
    let url = serverAddress + "/banks/all";
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getProformaById: (params) => {
    let url = serverAddress + `/proforma/${params.id}?final=${params.final}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  createProforma: (params) => {
    let url = serverAddress + "/proforma/new";
    return axios.post(url, params, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  createProformaRevision: (params) => {
    let url = serverAddress + `/proforma/${params._id}`;
    return axios.put(url, params, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  createFinalExport: (params) => {
    let url =
      serverAddress + `/exports/download/${params.id}?final=${params.final}`;
    return axios.get(url, params, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  getDownloadLink: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/exports/download`;
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
};

export default utils;
