import * as types from "../../constants";
import OrderAPI from "../../utils/api/OrderAPI";

export const addNewJobOrder = (params) => {
  return {
    type: types.ADD_NEW_ORDER,
    payload: OrderAPI.addNewJobOrder(params).then((response) => {
      return response.data;
    }),
  };
};

export function getOrderStatusCount(params) {
  return {
    type: types.GET_ORDER_STATUS_COUNT,
    payload: OrderAPI.getOrderStatusCount(params).then((response) => {
      return response.data;
    }),
  };
}

export function getEmployeeOrderStatusCount(params) {
  return {
    type: types.GET_EMPLOYEE_ORDER_STATUS_COUNT,
    payload: OrderAPI.getEmployeeOrderStatusCount(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllOrders(params) {
  return {
    type: types.GET_ORDER_LIST,
    payload: OrderAPI.getAllOrders(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedOrders(params) {
  return {
    type: types.GET_UPDATED_ORDER_LIST,
    payload: OrderAPI.getAllOrders(params).then((response) => {
      return response.data;
    }),
  };
}

export function getTotalOrders(params) {
  return {
    type: types.GET_TOTAL_ORDERS,
    payload: OrderAPI.getTotalOrders(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

export const updateJobOrder = (params) => {
  return {
    type: types.UPDATE_JOB_ORDER,
    payload: OrderAPI.updateJobOrder(params).then((response) => {
      return response.data;
    }),
  };
};

export const deleteJobOrder = (params) => {
  return {
    type: types.DELETE_JOB_ORDER,
    payload: OrderAPI.deleteJobOrder(params).then((response) => {
      return response.data;
    }),
  };
};

export const updateOrderSubject = (params) => {
  return {
    type: types.UPDATE_JOB_ORDER_SUBJECT,
    payload: OrderAPI.updateOrderSubject(params).then((response) => {
      return response.data;
    }),
  };
};
export function updateOrderStatus(params) {
  return {
    type: types.UPDATE_ORDER_STATUS,
    payload: OrderAPI.updateOrderStatus(params).then((response) => {
      return response.data;
    }),
  };
}

export function updateCaseOwner(params) {
  return {
    type: types.UPDATE_CASE_OWNER,
    payload: OrderAPI.updateCaseOwnerAPI(params).then((response) => {
      return response.data;
    }),
  };
}

export function cancelOrder(params) {
  return {
    type: types.CANCEL_ORDER,
    payload: OrderAPI.cancelOrder(params).then((response) => {
      return response.data;
    }),
  };
}

export function getOrderById(params) {
  return {
    type: types.GET_JOB_ORDER_BY_ID,
    payload: OrderAPI.getOrderById(params).then((response) => {
      return response.data;
    }),
  };
}

export function deleteJobFile(params) {
  return {
    type: types.DELETE_JOB_ORDER_FILE,
    payload: OrderAPI.deleteJobOrderFile(params).then((response) => {
      return response.data;
    }),
  };
}

export function linkLeadToOrder(params) {
  return {
    type: types.LINK_LEAD_TO_ORDER,
    payload: OrderAPI.linkLeadToOrder(params).then((response) => {
      return response.data;
    }),
  };
}

export function addFilesToOrder(params, token) {
  return {
    type: types.ADD_FILES_TO_ORDER,
    payload: OrderAPI.addFilesToOrder(params, token).then((response) => {
      return response.data;
    }),
  };
}

export function generateQrCode(params) {
  return {
    type: types.GENERATE_QR_CODE,
    payload: OrderAPI.generateQrCode(params).then((response) => {
      return response.data;
    }),
  };
}

export function downloadPdf(params) {
  return {
    type: types.DOWNLOAD_JOB_FILES,
    payload: OrderAPI.downloadPdf(params).then((response) => {
      return response.data;
    }),
  };
}

export function createDocument(params) {
  return {
    type: types.CREATE_DOCUMENT,
    payload: OrderAPI.createDocumentAPI(params).then((response) => {
      return response.data;
    }),
  };
}

export function updateDocument(params) {
  return {
    type: types.UPDATE_DOCUMENT,
    payload: OrderAPI.updateDocumentAPI(params).then((response) => {
      return response.data;
    }),
  };
}
