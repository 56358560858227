import React, { Component } from "react";
import Lost from "../assets/images/lost.png";
import { serverAddress } from "../constants";
import axios from "axios";
export const NetworkCheck = (ComposedComponent) => {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
    };

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener("online", this.handleConnectionChange);
      window.addEventListener("offline", this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener("online", this.handleConnectionChange);
      window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      //console.log(condition);
      if (condition === "online") {
        const webPing = setInterval(() => {
          axios
            .get(serverAddress + "/health")
            // fetch(`${serverAddress}/ping`, {
            //   mode: "no-cors",
            // })
            .then(() => {
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing);
              });
            })
            .catch(() => this.setState({ isDisconnected: true }));
        }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true });
    };

    render() {
      const { isDisconnected } = this.state;
      //console.log(isDisconnected);
      return (
        <>
          {isDisconnected ? (
            <div className="vh-100 ">
              <img
                className="d-flex  mx-auto"
                src={Lost}
                alt="connection Lost"
              />
              <h1 className="mt-5 fill text-center">
                Internet Connection Lost!
              </h1>
            </div>
          ) : (
            // <div>
            //   <ComposedComponent {...this.props} />
            // </div>
            <div>
              <ComposedComponent {...this.props} />
            </div>
          )}
        </>
      );
    }
  }

  return NetworkDetector;
};
