import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  fileCategories: [],
  fileAddResponce: [],
  filesByCategory: [],
  fileDetails: [],
  files: [],
  isCategoryAdded: false,
  isCategoriesFetched: false,
  isCategoryFilesFetched: false,
  isFilesFetched: false,
  isFileDetailsFetched: false,
  isFileUploaded: false,
  receivedResponse: false,
  message_error: "An error occured please try again",
};

export default function FileReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.ADD_NEW_FILE_CATEGORY_FULFILLED:
      state = {
        ...state,
        loading: false,
        fileAddResponce: action.payload,
        isCategoryAdded: true,
        receivedResponse: 200,
      };
      break;
    case types.ADD_NEW_FILE_CATEGORY_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isCategoryAdded: false,
      };
      break;
    case types.ADD_NEW_FILE_CATEGORY_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    case types.GET_FILE_CATEGORIES_FULFILLED:
      state = {
        ...state,
        loading: false,
        fileCategories: action.payload,
        isCategoriesFetched: true,
        receivedResponse: 200,
      };
      break;
    case types.GET_FILE_CATEGORIES_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isCategoriesFetched: false,
      };
      break;
    case types.GET_FILE_CATEGORIES_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    case types.GET_ALL_FILES_FULFILLED:
      state = {
        ...state,
        loading: false,
        files: action.payload,
        isFilesFetched: true,
        receivedResponse: 200,
      };
      break;
    case types.GET_ALL_FILES_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isFilesFetched: false,
      };
      break;
    case types.GET_ALL_FILES_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    case types.GET_FILES_BY_CATEGORY_FULFILLED:
      state = {
        ...state,
        loading: false,
        filesByCategory: action.payload,
        isCategoryFilesFetched: true,
        receivedResponse: 200,
      };
      break;
    case types.GET_FILES_BY_CATEGORY_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isCategoryFilesFetched: false,
      };
      break;
    case types.GET_FILES_BY_CATEGORY_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    case types.GET_FILE_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        fileDetails: action.payload,
        isFileDetailsFetched: true,
        receivedResponse: 200,
      };
      break;
    case types.GET_FILE_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isFileDetailsFetched: false,
      };
      break;
    case types.GET_FILE_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    case types.ADD_NEW_FILE_FULFILLED:
      state = {
        ...state,
        loading: false,
        fileAddResponce: action.payload,
        isFileUploaded: true,
        receivedResponse: 200,
      };
      break;
    case types.ADD_NEW_FILE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isFileUploaded: false,
      };
      break;
    case types.ADD_NEW_FILE_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        message_error: "An error occured please try again",
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
