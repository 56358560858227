import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  message_error: "",
  receivedResponse: false,
  ItemAddResponse: [],
  StockAddResponse: [],
  AssignStockResponse: [],
  ItemList: [],
  ItemLedgerList: [],
  StockList: [],
  ItemDetails: [],
  isItemDetailsFetched: false,
  isItemAdded: false,
  isStockAdded: false,
  isItemFetched: false,
  isStockFetched: false,
  isStockAssigned: false,
};

export default function InventoryReducer(
  state = initialFetchDataState,
  action
) {
  switch (action.type) {
    case types.ADD_NEW_ITEM_FULFILLED:
      state = {
        ...state,
        loading: false,
        ItemAddResponse: action.payload,
        receivedResponse: 200,
        isItemAdded: true,
      };
      break;
    case types.ADD_NEW_ITEM_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isItemAdded: false,
      };
      break;
    case types.ADD_NEW_ITEM_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isItemAdded: false,
      };
      break;
    case types.GET_ITEMS_FULFILLED:
      // console.log("in case:", action.payload);
      state = {
        ...state,
        loading: false,
        ItemList: action.payload,
        receivedResponse: 200,
        isItemFetched: true,
      };
      break;
    case types.GET_ITEMS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isItemFetched: false,
      };
      break;
    case types.GET_ITEMS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isItemFetched: false,
      };
      break;

    case types.GET_ALL_ITEMS_FULFILLED:
      state = {
        ...state,
        loading: false,
        AllItemList: action.payload,
        receivedResponse: 200,
        isItemFetched: true,
      };
      break;
    case types.GET_ALL_ITEMS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isItemFetched: false,
      };
      break;
    case types.GET_ALL_ITEMS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isItemFetched: false,
      };
      break;

    /* For Item Ledger */
    case types.GET_ITEMS_LEDGER_FULFILLED:
      state = {
        ...state,
        loading: false,
        ItemLedgerList: action.payload,
        receivedResponse: 200,
        isItemLedgerFetched: true,
      };
      break;
    case types.GET_ITEMS_LEDGER_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isItemLedgerFetched: false,
      };
      break;
    case types.GET_ITEMS_LEDGER_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isItemLedgerFetched: false,
      };
      break;
    /* End Here */

    case types.GET_UPDATED_ITEMS_FULFILLED:
      // console.log("in case:", action.payload);
      state = {
        ...state,
        loading: false,
        ItemList: action.payload,
        receivedResponse: 200,
        // isItemFetched: true,
      };
      break;
    case types.GET_UPDATED_ITEMS_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isItemFetched: false,
      };
      break;
    case types.GET_UPDATED_ITEMS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        // isItemFetched: false,
      };
      break;
    case types.GET_ITEM_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        ItemDetails: action.payload,
        receivedResponse: 200,
        isItemDetailsFetched: true,
      };
      break;
    case types.GET_ITEM_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isItemDetailsFetched: false,
      };
      break;
    case types.GET_ITEM_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isItemDetailsFetched: false,
      };
      break;
    case types.GET_STOCK_FULFILLED:
      state = {
        ...state,
        loading: false,
        StockList: action.payload,
        receivedResponse: 200,
        isStockFetched: true,
      };
      break;
    case types.GET_STOCK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isStockFetched: false,
      };
      break;
    case types.GET_STOCK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isStockFetched: false,
      };
      break;
    case types.ADD_STOCK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        StockAddResponse: action.payload,
        isStockAdded: true,
      };
      break;

    case types.ADD_STOCK_PENDING:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isStockAdded: false,
      };
      break;
    case types.ASSIGN_STOCK_FULFILLED:
      state = {
        ...state,
        loading: false,
        AssignStockResponse: action.payload,
        receivedResponse: 200,
        isStockAssigned: true,
      };
      break;
    case types.ASSIGN_STOCK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isStockAssigned: false,
      };
      break;
    case types.ASSIGN_STOCK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isStockAssigned: false,
      };
      break;

    default:
      state = {
        ...state,
      };
  }
  return state;
}
