import React from "react";
import _ from "underscore";
import { Tooltip } from "antd";
import { logOut } from "../../redux/actions/LoginActions";
import { toogleSidebar } from "../../redux/actions/SidebarActions";
import { connect } from "react-redux";
import Profile from "../../assets/images/avatar5.png";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const history = useHistory();

  const handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    if (!_.isEmpty(data)) {
      localStorage.clear();
      history.push("/");
      props.logOut();
      window.location.reload();
    } else {
      history.push("/");
    }
  };

  const sessionValue = JSON.parse(localStorage.getItem("data"));

  return (
    <header className="main-header">
      <nav className="navbar navbar-expand-lg navbar-expand py-0">
        <div className="container-fluid">
          <div className="col-md-1">
            <Tooltip title="Toggle Sidebar">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => props.toogleSidebar()}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
            </Tooltip>
          </div>
          <div className="collapse navbar-collapse navbar-custom-menu">
            <ul className="navbar-nav ml-auto">
              <div className="row">
                <div className="col-md-8 ">
                  <a
                    className="nav-link"
                    role="button"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      color: "black",
                      float: "right",
                      fontWeight: "600",
                    }}
                  >
                    Welcome, {sessionValue.loginData.user.user_name}
                  </a>
                </div>
                <div className="col-md-4">
                  <a
                    className="dropdown-item"
                    href=""
                    onClick={(event) => handleSignOut(event)}
                    style={{
                      textAlign: "left",
                      marginLeft: "-3rem",
                      marginTop: "4px",
                    }} // Align the logout button to the right
                  >
                    <i className="fa fa-sign-out" /> Logout
                  </a>
                </div>
              </div>
              <li className="nav-item dropdown dropdown-user"></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    userData: state.LoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut()),
    toogleSidebar: () => dispatch(toogleSidebar()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
