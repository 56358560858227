const currencyCodes = [
  {
    AlphabeticCode: "AFN",
    Currency: "Afghani",
    Entity: "AFGHANISTAN",
    MinorUnit: "2",
    NumericCode: 971,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "ÅLAND ISLANDS",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ALL",
    Currency: "Lek",
    Entity: "ALBANIA",
    MinorUnit: "2",
    NumericCode: 8,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DZD",
    Currency: "Algerian Dinar",
    Entity: "ALGERIA",
    MinorUnit: "2",
    NumericCode: 12,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "AMERICAN SAMOA",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "ANDORRA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AOA",
    Currency: "Kwanza",
    Entity: "ANGOLA",
    MinorUnit: "2",
    NumericCode: 973,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "ANGUILLA",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: null,
    Currency: "No universal currency",
    Entity: "ANTARCTICA",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "ANTIGUA AND BARBUDA",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ARS",
    Currency: "Argentine Peso",
    Entity: "ARGENTINA",
    MinorUnit: "2",
    NumericCode: 32,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AMD",
    Currency: "Armenian Dram",
    Entity: "ARMENIA",
    MinorUnit: "2",
    NumericCode: 51,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AWG",
    Currency: "Aruban Florin",
    Entity: "ARUBA",
    MinorUnit: "2",
    NumericCode: 533,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "AUSTRALIA",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "AUSTRIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AZN",
    Currency: "Azerbaijan Manat",
    Entity: "AZERBAIJAN",
    MinorUnit: "2",
    NumericCode: 944,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BSD",
    Currency: "Bahamian Dollar",
    Entity: "BAHAMAS (THE)",
    MinorUnit: "2",
    NumericCode: 44,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BHD",
    Currency: "Bahraini Dinar",
    Entity: "BAHRAIN",
    MinorUnit: "3",
    NumericCode: 48,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BDT",
    Currency: "Taka",
    Entity: "BANGLADESH",
    MinorUnit: "2",
    NumericCode: 50,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BBD",
    Currency: "Barbados Dollar",
    Entity: "BARBADOS",
    MinorUnit: "2",
    NumericCode: 52,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BYN",
    Currency: "Belarusian Ruble",
    Entity: "BELARUS",
    MinorUnit: "2",
    NumericCode: 933,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "BELGIUM",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BZD",
    Currency: "Belize Dollar",
    Entity: "BELIZE",
    MinorUnit: "2",
    NumericCode: 84,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "BENIN",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BMD",
    Currency: "Bermudian Dollar",
    Entity: "BERMUDA",
    MinorUnit: "2",
    NumericCode: 60,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "INR",
    Currency: "Indian Rupee",
    Entity: "BHUTAN",
    MinorUnit: "2",
    NumericCode: 356,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BTN",
    Currency: "Ngultrum",
    Entity: "BHUTAN",
    MinorUnit: "2",
    NumericCode: 64,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BOB",
    Currency: "Boliviano",
    Entity: "BOLIVIA (PLURINATIONAL STATE OF)",
    MinorUnit: "2",
    NumericCode: 68,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BOV",
    Currency: "Mvdol",
    Entity: "BOLIVIA (PLURINATIONAL STATE OF)",
    MinorUnit: "2",
    NumericCode: 984,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "BONAIRE, SINT EUSTATIUS AND SABA",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BAM",
    Currency: "Convertible Mark",
    Entity: "BOSNIA AND HERZEGOVINA",
    MinorUnit: "2",
    NumericCode: 977,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BWP",
    Currency: "Pula",
    Entity: "BOTSWANA",
    MinorUnit: "2",
    NumericCode: 72,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NOK",
    Currency: "Norwegian Krone",
    Entity: "BOUVET ISLAND",
    MinorUnit: "2",
    NumericCode: 578,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BRL",
    Currency: "Brazilian Real",
    Entity: "BRAZIL",
    MinorUnit: "2",
    NumericCode: 986,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "BRITISH INDIAN OCEAN TERRITORY (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BND",
    Currency: "Brunei Dollar",
    Entity: "BRUNEI DARUSSALAM",
    MinorUnit: "2",
    NumericCode: 96,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BGN",
    Currency: "Bulgarian Lev",
    Entity: "BULGARIA",
    MinorUnit: "2",
    NumericCode: 975,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "BURKINA FASO",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "BIF",
    Currency: "Burundi Franc",
    Entity: "BURUNDI",
    MinorUnit: "0",
    NumericCode: 108,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CVE",
    Currency: "Cabo Verde Escudo",
    Entity: "CABO VERDE",
    MinorUnit: "2",
    NumericCode: 132,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KHR",
    Currency: "Riel",
    Entity: "CAMBODIA",
    MinorUnit: "2",
    NumericCode: 116,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "CAMEROON",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CAD",
    Currency: "Canadian Dollar",
    Entity: "CANADA",
    MinorUnit: "2",
    NumericCode: 124,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KYD",
    Currency: "Cayman Islands Dollar",
    Entity: "CAYMAN ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 136,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "CENTRAL AFRICAN REPUBLIC (THE)",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "CHAD",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CLP",
    Currency: "Chilean Peso",
    Entity: "CHILE",
    MinorUnit: "0",
    NumericCode: 152,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CLF",
    Currency: "Unidad de Fomento",
    Entity: "CHILE",
    MinorUnit: "4",
    NumericCode: 990,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CNY",
    Currency: "Yuan Renminbi",
    Entity: "CHINA",
    MinorUnit: "2",
    NumericCode: 156,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "CHRISTMAS ISLAND",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "COCOS (KEELING) ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "COP",
    Currency: "Colombian Peso",
    Entity: "COLOMBIA",
    MinorUnit: "2",
    NumericCode: 170,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "COU",
    Currency: "Unidad de Valor Real",
    Entity: "COLOMBIA",
    MinorUnit: "2",
    NumericCode: 970,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KMF",
    Currency: "Comorian Franc",
    Entity: "COMOROS (THE)",
    MinorUnit: "0",
    NumericCode: 174,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CDF",
    Currency: "Congolese Franc",
    Entity: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    MinorUnit: "2",
    NumericCode: 976,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "CONGO (THE)",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NZD",
    Currency: "New Zealand Dollar",
    Entity: "COOK ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 554,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CRC",
    Currency: "Costa Rican Colon",
    Entity: "COSTA RICA",
    MinorUnit: "2",
    NumericCode: 188,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "CÔTE D'IVOIRE",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "HRK",
    Currency: "Kuna",
    Entity: "CROATIA",
    MinorUnit: "2",
    NumericCode: 191,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CUP",
    Currency: "Cuban Peso",
    Entity: "CUBA",
    MinorUnit: "2",
    NumericCode: 192,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CUC",
    Currency: "Peso Convertible",
    Entity: "CUBA",
    MinorUnit: "2",
    NumericCode: 931,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ANG",
    Currency: "Netherlands Antillean Guilder",
    Entity: "CURAÇAO",
    MinorUnit: "2",
    NumericCode: 532,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "CYPRUS",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CZK",
    Currency: "Czech Koruna",
    Entity: "CZECHIA",
    MinorUnit: "2",
    NumericCode: 203,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DKK",
    Currency: "Danish Krone",
    Entity: "DENMARK",
    MinorUnit: "2",
    NumericCode: 208,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DJF",
    Currency: "Djibouti Franc",
    Entity: "DJIBOUTI",
    MinorUnit: "0",
    NumericCode: 262,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "DOMINICA",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DOP",
    Currency: "Dominican Peso",
    Entity: "DOMINICAN REPUBLIC (THE)",
    MinorUnit: "2",
    NumericCode: 214,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "ECUADOR",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EGP",
    Currency: "Egyptian Pound",
    Entity: "EGYPT",
    MinorUnit: "2",
    NumericCode: 818,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SVC",
    Currency: "El Salvador Colon",
    Entity: "EL SALVADOR",
    MinorUnit: "2",
    NumericCode: 222,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "EL SALVADOR",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "EQUATORIAL GUINEA",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ERN",
    Currency: "Nakfa",
    Entity: "ERITREA",
    MinorUnit: "2",
    NumericCode: 232,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "ESTONIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SZL",
    Currency: "Lilangeni",
    Entity: "ESWATINI",
    MinorUnit: "2",
    NumericCode: 748,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ETB",
    Currency: "Ethiopian Birr",
    Entity: "ETHIOPIA",
    MinorUnit: "2",
    NumericCode: 230,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "EUROPEAN UNION",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "FKP",
    Currency: "Falkland Islands Pound",
    Entity: "FALKLAND ISLANDS (THE) [MALVINAS]",
    MinorUnit: "2",
    NumericCode: 238,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DKK",
    Currency: "Danish Krone",
    Entity: "FAROE ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 208,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "FJD",
    Currency: "Fiji Dollar",
    Entity: "FIJI",
    MinorUnit: "2",
    NumericCode: 242,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "FINLAND",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "FRANCE",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "FRENCH GUIANA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XPF",
    Currency: "CFP Franc",
    Entity: "FRENCH POLYNESIA",
    MinorUnit: "0",
    NumericCode: 953,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "FRENCH SOUTHERN TERRITORIES (THE)",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAF",
    Currency: "CFA Franc BEAC",
    Entity: "GABON",
    MinorUnit: "0",
    NumericCode: 950,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GMD",
    Currency: "Dalasi",
    Entity: "GAMBIA (THE)",
    MinorUnit: "2",
    NumericCode: 270,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GEL",
    Currency: "Lari",
    Entity: "GEORGIA",
    MinorUnit: "2",
    NumericCode: 981,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "GERMANY",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GHS",
    Currency: "Ghana Cedi",
    Entity: "GHANA",
    MinorUnit: "2",
    NumericCode: 936,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GIP",
    Currency: "Gibraltar Pound",
    Entity: "GIBRALTAR",
    MinorUnit: "2",
    NumericCode: 292,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "GREECE",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "DKK",
    Currency: "Danish Krone",
    Entity: "GREENLAND",
    MinorUnit: "2",
    NumericCode: 208,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "GRENADA",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "GUADELOUPE",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "GUAM",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GTQ",
    Currency: "Quetzal",
    Entity: "GUATEMALA",
    MinorUnit: "2",
    NumericCode: 320,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GBP",
    Currency: "Pound Sterling",
    Entity: "GUERNSEY",
    MinorUnit: "2",
    NumericCode: 826,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GNF",
    Currency: "Guinean Franc",
    Entity: "GUINEA",
    MinorUnit: "0",
    NumericCode: 324,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "GUINEA-BISSAU",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GYD",
    Currency: "Guyana Dollar",
    Entity: "GUYANA",
    MinorUnit: "2",
    NumericCode: 328,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "HTG",
    Currency: "Gourde",
    Entity: "HAITI",
    MinorUnit: "2",
    NumericCode: 332,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "HAITI",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "HEARD ISLAND AND McDONALD ISLANDS",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "HOLY SEE (THE)",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "HNL",
    Currency: "Lempira",
    Entity: "HONDURAS",
    MinorUnit: "2",
    NumericCode: 340,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "HKD",
    Currency: "Hong Kong Dollar",
    Entity: "HONG KONG",
    MinorUnit: "2",
    NumericCode: 344,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "HUF",
    Currency: "Forint",
    Entity: "HUNGARY",
    MinorUnit: "2",
    NumericCode: 348,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ISK",
    Currency: "Iceland Krona",
    Entity: "ICELAND",
    MinorUnit: "0",
    NumericCode: 352,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "INR",
    Currency: "Indian Rupee",
    Entity: "INDIA",
    MinorUnit: "2",
    NumericCode: 356,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "IDR",
    Currency: "Rupiah",
    Entity: "INDONESIA",
    MinorUnit: "2",
    NumericCode: 360,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XDR",
    Currency: "SDR (Special Drawing Right)",
    Entity: "INTERNATIONAL MONETARY FUND (IMF)",
    MinorUnit: "-",
    NumericCode: 960,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "IRR",
    Currency: "Iranian Rial",
    Entity: "IRAN (ISLAMIC REPUBLIC OF)",
    MinorUnit: "2",
    NumericCode: 364,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "IQD",
    Currency: "Iraqi Dinar",
    Entity: "IRAQ",
    MinorUnit: "3",
    NumericCode: 368,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "IRELAND",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GBP",
    Currency: "Pound Sterling",
    Entity: "ISLE OF MAN",
    MinorUnit: "2",
    NumericCode: 826,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ILS",
    Currency: "New Israeli Sheqel",
    Entity: "ISRAEL",
    MinorUnit: "2",
    NumericCode: 376,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "ITALY",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "JMD",
    Currency: "Jamaican Dollar",
    Entity: "JAMAICA",
    MinorUnit: "2",
    NumericCode: 388,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "JPY",
    Currency: "Yen",
    Entity: "JAPAN",
    MinorUnit: "0",
    NumericCode: 392,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GBP",
    Currency: "Pound Sterling",
    Entity: "JERSEY",
    MinorUnit: "2",
    NumericCode: 826,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "JOD",
    Currency: "Jordanian Dinar",
    Entity: "JORDAN",
    MinorUnit: "3",
    NumericCode: 400,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KZT",
    Currency: "Tenge",
    Entity: "KAZAKHSTAN",
    MinorUnit: "2",
    NumericCode: 398,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KES",
    Currency: "Kenyan Shilling",
    Entity: "KENYA",
    MinorUnit: "2",
    NumericCode: 404,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "KIRIBATI",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KPW",
    Currency: "North Korean Won",
    Entity: "KOREA (THE DEMOCRATIC PEOPLE'S REPUBLIC OF)",
    MinorUnit: "2",
    NumericCode: 408,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KRW",
    Currency: "Won",
    Entity: "KOREA (THE REPUBLIC OF)",
    MinorUnit: "0",
    NumericCode: 410,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KWD",
    Currency: "Kuwaiti Dinar",
    Entity: "KUWAIT",
    MinorUnit: "3",
    NumericCode: 414,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "KGS",
    Currency: "Som",
    Entity: "KYRGYZSTAN",
    MinorUnit: "2",
    NumericCode: 417,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LAK",
    Currency: "Lao Kip",
    Entity: "LAO PEOPLE'S DEMOCRATIC REPUBLIC (THE)",
    MinorUnit: "2",
    NumericCode: 418,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "LATVIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LBP",
    Currency: "Lebanese Pound",
    Entity: "LEBANON",
    MinorUnit: "2",
    NumericCode: 422,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LSL",
    Currency: "Loti",
    Entity: "LESOTHO",
    MinorUnit: "2",
    NumericCode: 426,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ZAR",
    Currency: "Rand",
    Entity: "LESOTHO",
    MinorUnit: "2",
    NumericCode: 710,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LRD",
    Currency: "Liberian Dollar",
    Entity: "LIBERIA",
    MinorUnit: "2",
    NumericCode: 430,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LYD",
    Currency: "Libyan Dinar",
    Entity: "LIBYA",
    MinorUnit: "3",
    NumericCode: 434,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CHF",
    Currency: "Swiss Franc",
    Entity: "LIECHTENSTEIN",
    MinorUnit: "2",
    NumericCode: 756,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "LITHUANIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "LUXEMBOURG",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MOP",
    Currency: "Pataca",
    Entity: "MACAO",
    MinorUnit: "2",
    NumericCode: 446,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MKD",
    Currency: "Denar",
    Entity: "NORTH MACEDONIA",
    MinorUnit: "2",
    NumericCode: 807,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MGA",
    Currency: "Malagasy Ariary",
    Entity: "MADAGASCAR",
    MinorUnit: "2",
    NumericCode: 969,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MWK",
    Currency: "Malawi Kwacha",
    Entity: "MALAWI",
    MinorUnit: "2",
    NumericCode: 454,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MYR",
    Currency: "Malaysian Ringgit",
    Entity: "MALAYSIA",
    MinorUnit: "2",
    NumericCode: 458,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MVR",
    Currency: "Rufiyaa",
    Entity: "MALDIVES",
    MinorUnit: "2",
    NumericCode: 462,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "MALI",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "MALTA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "MARSHALL ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "MARTINIQUE",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MRU",
    Currency: "Ouguiya",
    Entity: "MAURITANIA",
    MinorUnit: "2",
    NumericCode: 929,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MUR",
    Currency: "Mauritius Rupee",
    Entity: "MAURITIUS",
    MinorUnit: "2",
    NumericCode: 480,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "MAYOTTE",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XUA",
    Currency: "ADB Unit of Account",
    Entity: "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
    MinorUnit: "-",
    NumericCode: 965,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MXN",
    Currency: "Mexican Peso",
    Entity: "MEXICO",
    MinorUnit: "2",
    NumericCode: 484,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MXV",
    Currency: "Mexican Unidad de Inversion (UDI)",
    Entity: "MEXICO",
    MinorUnit: "2",
    NumericCode: 979,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "MICRONESIA (FEDERATED STATES OF)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MDL",
    Currency: "Moldovan Leu",
    Entity: "MOLDOVA (THE REPUBLIC OF)",
    MinorUnit: "2",
    NumericCode: 498,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "MONACO",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MNT",
    Currency: "Tugrik",
    Entity: "MONGOLIA",
    MinorUnit: "2",
    NumericCode: 496,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "MONTENEGRO",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "MONTSERRAT",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MAD",
    Currency: "Moroccan Dirham",
    Entity: "MOROCCO",
    MinorUnit: "2",
    NumericCode: 504,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MZN",
    Currency: "Mozambique Metical",
    Entity: "MOZAMBIQUE",
    MinorUnit: "2",
    NumericCode: 943,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MMK",
    Currency: "Kyat",
    Entity: "MYANMAR",
    MinorUnit: "2",
    NumericCode: 104,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NAD",
    Currency: "Namibia Dollar",
    Entity: "NAMIBIA",
    MinorUnit: "2",
    NumericCode: 516,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ZAR",
    Currency: "Rand",
    Entity: "NAMIBIA",
    MinorUnit: "2",
    NumericCode: 710,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "NAURU",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NPR",
    Currency: "Nepalese Rupee",
    Entity: "NEPAL",
    MinorUnit: "2",
    NumericCode: 524,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "NETHERLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XPF",
    Currency: "CFP Franc",
    Entity: "NEW CALEDONIA",
    MinorUnit: "0",
    NumericCode: 953,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NZD",
    Currency: "New Zealand Dollar",
    Entity: "NEW ZEALAND",
    MinorUnit: "2",
    NumericCode: 554,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NIO",
    Currency: "Cordoba Oro",
    Entity: "NICARAGUA",
    MinorUnit: "2",
    NumericCode: 558,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "NIGER (THE)",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NGN",
    Currency: "Naira",
    Entity: "NIGERIA",
    MinorUnit: "2",
    NumericCode: 566,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NZD",
    Currency: "New Zealand Dollar",
    Entity: "NIUE",
    MinorUnit: "2",
    NumericCode: 554,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "NORFOLK ISLAND",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "NORTHERN MARIANA ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NOK",
    Currency: "Norwegian Krone",
    Entity: "NORWAY",
    MinorUnit: "2",
    NumericCode: 578,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "OMR",
    Currency: "Rial Omani",
    Entity: "OMAN",
    MinorUnit: "3",
    NumericCode: 512,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PKR",
    Currency: "Pakistan Rupee",
    Entity: "PAKISTAN",
    MinorUnit: "2",
    NumericCode: 586,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "PALAU",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: null,
    Currency: "No universal currency",
    Entity: "PALESTINE, STATE OF",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PAB",
    Currency: "Balboa",
    Entity: "PANAMA",
    MinorUnit: "2",
    NumericCode: 590,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "PANAMA",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PGK",
    Currency: "Kina",
    Entity: "PAPUA NEW GUINEA",
    MinorUnit: "2",
    NumericCode: 598,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PYG",
    Currency: "Guarani",
    Entity: "PARAGUAY",
    MinorUnit: "0",
    NumericCode: 600,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PEN",
    Currency: "Sol",
    Entity: "PERU",
    MinorUnit: "2",
    NumericCode: 604,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PHP",
    Currency: "Philippine Peso",
    Entity: "PHILIPPINES (THE)",
    MinorUnit: "2",
    NumericCode: 608,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NZD",
    Currency: "New Zealand Dollar",
    Entity: "PITCAIRN",
    MinorUnit: "2",
    NumericCode: 554,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "PLN",
    Currency: "Zloty",
    Entity: "POLAND",
    MinorUnit: "2",
    NumericCode: 985,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "PORTUGAL",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "PUERTO RICO",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "QAR",
    Currency: "Qatari Rial",
    Entity: "QATAR",
    MinorUnit: "2",
    NumericCode: 634,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "RÉUNION",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "RON",
    Currency: "Romanian Leu",
    Entity: "ROMANIA",
    MinorUnit: "2",
    NumericCode: 946,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "RUB",
    Currency: "Russian Ruble",
    Entity: "RUSSIAN FEDERATION (THE)",
    MinorUnit: "2",
    NumericCode: 643,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "RWF",
    Currency: "Rwanda Franc",
    Entity: "RWANDA",
    MinorUnit: "0",
    NumericCode: 646,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SAINT BARTHÉLEMY",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SHP",
    Currency: "Saint Helena Pound",
    Entity: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    MinorUnit: "2",
    NumericCode: 654,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "SAINT KITTS AND NEVIS",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "SAINT LUCIA",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SAINT MARTIN (FRENCH PART)",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SAINT PIERRE AND MIQUELON",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XCD",
    Currency: "East Caribbean Dollar",
    Entity: "SAINT VINCENT AND THE GRENADINES",
    MinorUnit: "2",
    NumericCode: 951,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "WST",
    Currency: "Tala",
    Entity: "SAMOA",
    MinorUnit: "2",
    NumericCode: 882,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SAN MARINO",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "STN",
    Currency: "Dobra",
    Entity: "SAO TOME AND PRINCIPE",
    MinorUnit: "2",
    NumericCode: 930,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SAR",
    Currency: "Saudi Riyal",
    Entity: "SAUDI ARABIA",
    MinorUnit: "2",
    NumericCode: 682,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "SENEGAL",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "RSD",
    Currency: "Serbian Dinar",
    Entity: "SERBIA",
    MinorUnit: "2",
    NumericCode: 941,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SCR",
    Currency: "Seychelles Rupee",
    Entity: "SEYCHELLES",
    MinorUnit: "2",
    NumericCode: 690,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SLL",
    Currency: "Leone",
    Entity: "SIERRA LEONE",
    MinorUnit: "2",
    NumericCode: 694,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SGD",
    Currency: "Singapore Dollar",
    Entity: "SINGAPORE",
    MinorUnit: "2",
    NumericCode: 702,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ANG",
    Currency: "Netherlands Antillean Guilder",
    Entity: "SINT MAARTEN (DUTCH PART)",
    MinorUnit: "2",
    NumericCode: 532,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XSU",
    Currency: "Sucre",
    Entity: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    MinorUnit: "-",
    NumericCode: 994,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SLOVAKIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SLOVENIA",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SBD",
    Currency: "Solomon Islands Dollar",
    Entity: "SOLOMON ISLANDS",
    MinorUnit: "2",
    NumericCode: 90,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SOS",
    Currency: "Somali Shilling",
    Entity: "SOMALIA",
    MinorUnit: "2",
    NumericCode: 706,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ZAR",
    Currency: "Rand",
    Entity: "SOUTH AFRICA",
    MinorUnit: "2",
    NumericCode: 710,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: null,
    Currency: "No universal currency",
    Entity: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SSP",
    Currency: "South Sudanese Pound",
    Entity: "SOUTH SUDAN",
    MinorUnit: "2",
    NumericCode: 728,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SPAIN",
    MinorUnit: "2",
    NumericCode: 978,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "LKR",
    Currency: "Sri Lanka Rupee",
    Entity: "SRI LANKA",
    MinorUnit: "2",
    NumericCode: 144,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SDG",
    Currency: "Sudanese Pound",
    Entity: "SUDAN (THE)",
    MinorUnit: "2",
    NumericCode: 938,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SRD",
    Currency: "Surinam Dollar",
    Entity: "SURINAME",
    MinorUnit: "2",
    NumericCode: 968,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NOK",
    Currency: "Norwegian Krone",
    Entity: "SVALBARD AND JAN MAYEN",
    MinorUnit: "2",
    NumericCode: 578,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SEK",
    Currency: "Swedish Krona",
    Entity: "SWEDEN",
    MinorUnit: "2",
    NumericCode: 752,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CHF",
    Currency: "Swiss Franc",
    Entity: "SWITZERLAND",
    MinorUnit: "2",
    NumericCode: 756,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CHE",
    Currency: "WIR Euro",
    Entity: "SWITZERLAND",
    MinorUnit: "2",
    NumericCode: 947,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "CHW",
    Currency: "WIR Franc",
    Entity: "SWITZERLAND",
    MinorUnit: "2",
    NumericCode: 948,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "SYP",
    Currency: "Syrian Pound",
    Entity: "SYRIAN ARAB REPUBLIC",
    MinorUnit: "2",
    NumericCode: 760,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TWD",
    Currency: "New Taiwan Dollar",
    Entity: "TAIWAN (PROVINCE OF CHINA)",
    MinorUnit: "2",
    NumericCode: 901,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TJS",
    Currency: "Somoni",
    Entity: "TAJIKISTAN",
    MinorUnit: "2",
    NumericCode: 972,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TZS",
    Currency: "Tanzanian Shilling",
    Entity: "TANZANIA, UNITED REPUBLIC OF",
    MinorUnit: "2",
    NumericCode: 834,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "THB",
    Currency: "Baht",
    Entity: "THAILAND",
    MinorUnit: "2",
    NumericCode: 764,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "TIMOR-LESTE",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XOF",
    Currency: "CFA Franc BCEAO",
    Entity: "TOGO",
    MinorUnit: "0",
    NumericCode: 952,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "NZD",
    Currency: "New Zealand Dollar",
    Entity: "TOKELAU",
    MinorUnit: "2",
    NumericCode: 554,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TOP",
    Currency: "Pa'anga",
    Entity: "TONGA",
    MinorUnit: "2",
    NumericCode: 776,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TTD",
    Currency: "Trinidad and Tobago Dollar",
    Entity: "TRINIDAD AND TOBAGO",
    MinorUnit: "2",
    NumericCode: 780,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TND",
    Currency: "Tunisian Dinar",
    Entity: "TUNISIA",
    MinorUnit: "3",
    NumericCode: 788,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TRY",
    Currency: "Turkish Lira",
    Entity: "TURKEY",
    MinorUnit: "2",
    NumericCode: 949,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "TMT",
    Currency: "Turkmenistan New Manat",
    Entity: "TURKMENISTAN",
    MinorUnit: "2",
    NumericCode: 934,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "TURKS AND CAICOS ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AUD",
    Currency: "Australian Dollar",
    Entity: "TUVALU",
    MinorUnit: "2",
    NumericCode: 36,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UGX",
    Currency: "Uganda Shilling",
    Entity: "UGANDA",
    MinorUnit: "0",
    NumericCode: 800,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UAH",
    Currency: "Hryvnia",
    Entity: "UKRAINE",
    MinorUnit: "2",
    NumericCode: 980,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AED",
    Currency: "UAE Dirham",
    Entity: "UNITED ARAB EMIRATES (THE)",
    MinorUnit: "2",
    NumericCode: 784,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "GBP",
    Currency: "Pound Sterling",
    Entity: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
    MinorUnit: "2",
    NumericCode: 826,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "UNITED STATES OF AMERICA (THE)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USN",
    Currency: "US Dollar (Next day)",
    Entity: "UNITED STATES OF AMERICA (THE)",
    MinorUnit: "2",
    NumericCode: 997,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UYU",
    Currency: "Peso Uruguayo",
    Entity: "URUGUAY",
    MinorUnit: "2",
    NumericCode: 858,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UYI",
    Currency: "Uruguay Peso en Unidades Indexadas (UI)",
    Entity: "URUGUAY",
    MinorUnit: "0",
    NumericCode: 940,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UYW",
    Currency: "Unidad Previsional",
    Entity: "URUGUAY",
    MinorUnit: "4",
    NumericCode: 927,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "UZS",
    Currency: "Uzbekistan Sum",
    Entity: "UZBEKISTAN",
    MinorUnit: "2",
    NumericCode: 860,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "VUV",
    Currency: "Vatu",
    Entity: "VANUATU",
    MinorUnit: "0",
    NumericCode: 548,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "VES",
    Currency: "Bolívar Soberano",
    Entity: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    MinorUnit: "2",
    NumericCode: 928,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "VND",
    Currency: "Dong",
    Entity: "VIET NAM",
    MinorUnit: "0",
    NumericCode: 704,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "VIRGIN ISLANDS (BRITISH)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "USD",
    Currency: "US Dollar",
    Entity: "VIRGIN ISLANDS (U.S.)",
    MinorUnit: "2",
    NumericCode: 840,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XPF",
    Currency: "CFP Franc",
    Entity: "WALLIS AND FUTUNA",
    MinorUnit: "0",
    NumericCode: 953,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "MAD",
    Currency: "Moroccan Dirham",
    Entity: "WESTERN SAHARA",
    MinorUnit: "2",
    NumericCode: 504,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "YER",
    Currency: "Yemeni Rial",
    Entity: "YEMEN",
    MinorUnit: "2",
    NumericCode: 886,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ZMW",
    Currency: "Zambian Kwacha",
    Entity: "ZAMBIA",
    MinorUnit: "2",
    NumericCode: 967,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "ZWL",
    Currency: "Zimbabwe Dollar",
    Entity: "ZIMBABWE",
    MinorUnit: "2",
    NumericCode: 932,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XBA",
    Currency: "Bond Markets Unit European Composite Unit (EURCO)",
    Entity: "ZZ01_Bond Markets Unit European_EURCO",
    MinorUnit: "-",
    NumericCode: 955,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XBB",
    Currency: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
    Entity: "ZZ02_Bond Markets Unit European_EMU-6",
    MinorUnit: "-",
    NumericCode: 956,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XBC",
    Currency: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
    Entity: "ZZ03_Bond Markets Unit European_EUA-9",
    MinorUnit: "-",
    NumericCode: 957,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XBD",
    Currency: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
    Entity: "ZZ04_Bond Markets Unit European_EUA-17",
    MinorUnit: "-",
    NumericCode: 958,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XTS",
    Currency: "Codes specifically reserved for testing purposes",
    Entity: "ZZ06_Testing_Code",
    MinorUnit: "-",
    NumericCode: 963,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XXX",
    Currency:
      "The codes assigned for transactions where no currency is involved",
    Entity: "ZZ07_No_Currency",
    MinorUnit: "-",
    NumericCode: 999,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAU",
    Currency: "Gold",
    Entity: "ZZ08_Gold",
    MinorUnit: "-",
    NumericCode: 959,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XPD",
    Currency: "Palladium",
    Entity: "ZZ09_Palladium",
    MinorUnit: "-",
    NumericCode: 964,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XPT",
    Currency: "Platinum",
    Entity: "ZZ10_Platinum",
    MinorUnit: "-",
    NumericCode: 962,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "XAG",
    Currency: "Silver",
    Entity: "ZZ11_Silver",
    MinorUnit: "-",
    NumericCode: 961,
    WithdrawalDate: null,
  },
  {
    AlphabeticCode: "AFA",
    Currency: "Afghani",
    Entity: "AFGHANISTAN",
    MinorUnit: null,
    NumericCode: 4,
    WithdrawalDate: "2003-01",
  },
  {
    AlphabeticCode: "FIM",
    Currency: "Markka",
    Entity: "ÅLAND ISLANDS",
    MinorUnit: null,
    NumericCode: 246,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "ALK",
    Currency: "Old Lek",
    Entity: "ALBANIA",
    MinorUnit: null,
    NumericCode: 8,
    WithdrawalDate: "1989-12",
  },
  {
    AlphabeticCode: "ADP",
    Currency: "Andorran Peseta",
    Entity: "ANDORRA",
    MinorUnit: null,
    NumericCode: 20,
    WithdrawalDate: "2003-07",
  },
  {
    AlphabeticCode: "ESP",
    Currency: "Spanish Peseta",
    Entity: "ANDORRA",
    MinorUnit: null,
    NumericCode: 724,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "ANDORRA",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "AOK",
    Currency: "Kwanza",
    Entity: "ANGOLA",
    MinorUnit: null,
    NumericCode: 24,
    WithdrawalDate: "1991-03",
  },
  {
    AlphabeticCode: "AON",
    Currency: "New Kwanza",
    Entity: "ANGOLA",
    MinorUnit: null,
    NumericCode: 24,
    WithdrawalDate: "2000-02",
  },
  {
    AlphabeticCode: "AOR",
    Currency: "Kwanza Reajustado",
    Entity: "ANGOLA",
    MinorUnit: null,
    NumericCode: 982,
    WithdrawalDate: "2000-02",
  },
  {
    AlphabeticCode: "ARA",
    Currency: "Austral",
    Entity: "ARGENTINA",
    MinorUnit: null,
    NumericCode: 32,
    WithdrawalDate: "1992-01",
  },
  {
    AlphabeticCode: "ARP",
    Currency: "Peso Argentino",
    Entity: "ARGENTINA",
    MinorUnit: null,
    NumericCode: 32,
    WithdrawalDate: "1985-07",
  },
  {
    AlphabeticCode: "ARY",
    Currency: "Peso",
    Entity: "ARGENTINA",
    MinorUnit: null,
    NumericCode: 32,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "ARMENIA",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-08",
  },
  {
    AlphabeticCode: "ATS",
    Currency: "Schilling",
    Entity: "AUSTRIA",
    MinorUnit: null,
    NumericCode: 40,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "AYM",
    Currency: "Azerbaijan Manat",
    Entity: "AZERBAIJAN",
    MinorUnit: null,
    NumericCode: 945,
    WithdrawalDate: "2005-10",
  },
  {
    AlphabeticCode: "AZM",
    Currency: "Azerbaijanian Manat",
    Entity: "AZERBAIJAN",
    MinorUnit: null,
    NumericCode: 31,
    WithdrawalDate: "2005-12",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "AZERBAIJAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-08",
  },
  {
    AlphabeticCode: "BYB",
    Currency: "Belarusian Ruble",
    Entity: "BELARUS",
    MinorUnit: null,
    NumericCode: 112,
    WithdrawalDate: "2001-01",
  },
  {
    AlphabeticCode: "BYR",
    Currency: "Belarusian Ruble",
    Entity: "BELARUS",
    MinorUnit: null,
    NumericCode: 974,
    WithdrawalDate: "2017-01",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "BELARUS",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-06",
  },
  {
    AlphabeticCode: "BEC",
    Currency: "Convertible Franc",
    Entity: "BELGIUM",
    MinorUnit: null,
    NumericCode: 993,
    WithdrawalDate: "1990-03",
  },
  {
    AlphabeticCode: "BEF",
    Currency: "Belgian Franc",
    Entity: "BELGIUM",
    MinorUnit: null,
    NumericCode: 56,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "BEL",
    Currency: "Financial Franc",
    Entity: "BELGIUM",
    MinorUnit: null,
    NumericCode: 992,
    WithdrawalDate: "1990-03",
  },
  {
    AlphabeticCode: "BOP",
    Currency: "Peso boliviano",
    Entity: "BOLIVIA",
    MinorUnit: null,
    NumericCode: 68,
    WithdrawalDate: "1987-02",
  },
  {
    AlphabeticCode: "BAD",
    Currency: "Dinar",
    Entity: "BOSNIA AND HERZEGOVINA",
    MinorUnit: null,
    NumericCode: 70,
    WithdrawalDate: "1998-07",
  },
  {
    AlphabeticCode: "BRB",
    Currency: "Cruzeiro",
    Entity: "BRAZIL",
    MinorUnit: null,
    NumericCode: 76,
    WithdrawalDate: "1986-03",
  },
  {
    AlphabeticCode: "BRC",
    Currency: "Cruzado",
    Entity: "BRAZIL",
    MinorUnit: null,
    NumericCode: 76,
    WithdrawalDate: "1989-02",
  },
  {
    AlphabeticCode: "BRE",
    Currency: "Cruzeiro",
    Entity: "BRAZIL",
    MinorUnit: null,
    NumericCode: 76,
    WithdrawalDate: "1993-03",
  },
  {
    AlphabeticCode: "BRN",
    Currency: "New Cruzado",
    Entity: "BRAZIL",
    MinorUnit: null,
    NumericCode: 76,
    WithdrawalDate: "1990-03",
  },
  {
    AlphabeticCode: "BRR",
    Currency: "Cruzeiro Real",
    Entity: "BRAZIL",
    MinorUnit: null,
    NumericCode: 987,
    WithdrawalDate: "1994-07",
  },
  {
    AlphabeticCode: "BGJ",
    Currency: "Lev A/52",
    Entity: "BULGARIA",
    MinorUnit: null,
    NumericCode: 100,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "BGK",
    Currency: "Lev A/62",
    Entity: "BULGARIA",
    MinorUnit: null,
    NumericCode: 100,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "BGL",
    Currency: "Lev",
    Entity: "BULGARIA",
    MinorUnit: null,
    NumericCode: 100,
    WithdrawalDate: "2003-11",
  },
  {
    AlphabeticCode: "BUK",
    Currency: "Kyat",
    Entity: "BURMA",
    MinorUnit: null,
    NumericCode: 104,
    WithdrawalDate: "1990-02",
  },
  {
    AlphabeticCode: "HRD",
    Currency: "Croatian Dinar",
    Entity: "CROATIA",
    MinorUnit: null,
    NumericCode: 191,
    WithdrawalDate: "1995-01",
  },
  {
    AlphabeticCode: "HRK",
    Currency: "Croatian Kuna",
    Entity: "CROATIA",
    MinorUnit: null,
    NumericCode: 191,
    WithdrawalDate: "2015-06",
  },
  {
    AlphabeticCode: "CYP",
    Currency: "Cyprus Pound",
    Entity: "CYPRUS",
    MinorUnit: null,
    NumericCode: 196,
    WithdrawalDate: "2008-01",
  },
  {
    AlphabeticCode: "CSJ",
    Currency: "Krona A/53",
    Entity: "CZECHOSLOVAKIA",
    MinorUnit: null,
    NumericCode: 203,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "CSK",
    Currency: "Koruna",
    Entity: "CZECHOSLOVAKIA",
    MinorUnit: null,
    NumericCode: 200,
    WithdrawalDate: "1993-03",
  },
  {
    AlphabeticCode: "ECS",
    Currency: "Sucre",
    Entity: "ECUADOR",
    MinorUnit: null,
    NumericCode: 218,
    WithdrawalDate: "2000-09",
  },
  {
    AlphabeticCode: "ECV",
    Currency: "Unidad de Valor Constante (UVC)",
    Entity: "ECUADOR",
    MinorUnit: null,
    NumericCode: 983,
    WithdrawalDate: "2000-09",
  },
  {
    AlphabeticCode: "GQE",
    Currency: "Ekwele",
    Entity: "EQUATORIAL GUINEA",
    MinorUnit: null,
    NumericCode: 226,
    WithdrawalDate: "1986-06",
  },
  {
    AlphabeticCode: "EEK",
    Currency: "Kroon",
    Entity: "ESTONIA",
    MinorUnit: null,
    NumericCode: 233,
    WithdrawalDate: "2011-01",
  },
  {
    AlphabeticCode: "XEU",
    Currency: "European Currency Unit (E.C.U)",
    Entity: "EUROPEAN MONETARY CO-OPERATION FUND (EMCF)",
    MinorUnit: null,
    NumericCode: 954,
    WithdrawalDate: "1999-01",
  },
  {
    AlphabeticCode: "FIM",
    Currency: "Markka",
    Entity: "FINLAND",
    MinorUnit: null,
    NumericCode: 246,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "FRANCE",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "FRENCH GUIANA",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "FRENCH SOUTHERN TERRITORIES",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "GEK",
    Currency: "Georgian Coupon",
    Entity: "GEORGIA",
    MinorUnit: null,
    NumericCode: 268,
    WithdrawalDate: "1995-10",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "GEORGIA",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-04",
  },
  {
    AlphabeticCode: "DDM",
    Currency: "Mark der DDR",
    Entity: "GERMAN DEMOCRATIC REPUBLIC",
    MinorUnit: null,
    NumericCode: 278,
    WithdrawalDate: "1990-07 to 1990-09",
  },
  {
    AlphabeticCode: "DEM",
    Currency: "Deutsche Mark",
    Entity: "GERMANY",
    MinorUnit: null,
    NumericCode: 276,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "GHC",
    Currency: "Cedi",
    Entity: "GHANA",
    MinorUnit: null,
    NumericCode: 288,
    WithdrawalDate: "2008-01",
  },
  {
    AlphabeticCode: "GHP",
    Currency: "Ghana Cedi",
    Entity: "GHANA",
    MinorUnit: null,
    NumericCode: 939,
    WithdrawalDate: "2007-06",
  },
  {
    AlphabeticCode: "GRD",
    Currency: "Drachma",
    Entity: "GREECE",
    MinorUnit: null,
    NumericCode: 300,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "GUADELOUPE",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "GNE",
    Currency: "Syli",
    Entity: "GUINEA",
    MinorUnit: null,
    NumericCode: 324,
    WithdrawalDate: "1989-12",
  },
  {
    AlphabeticCode: "GNS",
    Currency: "Syli",
    Entity: "GUINEA",
    MinorUnit: null,
    NumericCode: 324,
    WithdrawalDate: "1986-02",
  },
  {
    AlphabeticCode: "GWE",
    Currency: "Guinea Escudo",
    Entity: "GUINEA-BISSAU",
    MinorUnit: null,
    NumericCode: 624,
    WithdrawalDate: "1978 to 1981",
  },
  {
    AlphabeticCode: "GWP",
    Currency: "Guinea-Bissau Peso",
    Entity: "GUINEA-BISSAU",
    MinorUnit: null,
    NumericCode: 624,
    WithdrawalDate: "1997-05",
  },
  {
    AlphabeticCode: "ITL",
    Currency: "Italian Lira",
    Entity: "HOLY SEE (VATICAN CITY STATE)",
    MinorUnit: null,
    NumericCode: 380,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "ISJ",
    Currency: "Old Krona",
    Entity: "ICELAND",
    MinorUnit: null,
    NumericCode: 352,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "IEP",
    Currency: "Irish Pound",
    Entity: "IRELAND",
    MinorUnit: null,
    NumericCode: 372,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "ILP",
    Currency: "Pound",
    Entity: "ISRAEL",
    MinorUnit: null,
    NumericCode: 376,
    WithdrawalDate: "1978 to 1981",
  },
  {
    AlphabeticCode: "ILR",
    Currency: "Old Shekel",
    Entity: "ISRAEL",
    MinorUnit: null,
    NumericCode: 376,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "ITL",
    Currency: "Italian Lira",
    Entity: "ITALY",
    MinorUnit: null,
    NumericCode: 380,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "KAZAKHSTAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-05",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "KYRGYZSTAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1993-01",
  },
  {
    AlphabeticCode: "LAJ",
    Currency: "Pathet Lao Kip",
    Entity: "LAO",
    MinorUnit: null,
    NumericCode: 418,
    WithdrawalDate: "1979-12",
  },
  {
    AlphabeticCode: "LVL",
    Currency: "Latvian Lats",
    Entity: "LATVIA",
    MinorUnit: null,
    NumericCode: 428,
    WithdrawalDate: "2014-01",
  },
  {
    AlphabeticCode: "LVR",
    Currency: "Latvian Ruble",
    Entity: "LATVIA",
    MinorUnit: null,
    NumericCode: 428,
    WithdrawalDate: "1994-12",
  },
  {
    AlphabeticCode: "LSM",
    Currency: "Loti",
    Entity: "LESOTHO",
    MinorUnit: null,
    NumericCode: 426,
    WithdrawalDate: "1985-05",
  },
  {
    AlphabeticCode: "ZAL",
    Currency: "Financial Rand",
    Entity: "LESOTHO",
    MinorUnit: null,
    NumericCode: 991,
    WithdrawalDate: "1995-03",
  },
  {
    AlphabeticCode: "LTL",
    Currency: "Lithuanian Litas",
    Entity: "LITHUANIA",
    MinorUnit: null,
    NumericCode: 440,
    WithdrawalDate: "2014-12",
  },
  {
    AlphabeticCode: "LTT",
    Currency: "Talonas",
    Entity: "LITHUANIA",
    MinorUnit: null,
    NumericCode: 440,
    WithdrawalDate: "1993-07",
  },
  {
    AlphabeticCode: "LUC",
    Currency: "Luxembourg Convertible Franc",
    Entity: "LUXEMBOURG",
    MinorUnit: null,
    NumericCode: 989,
    WithdrawalDate: "1990-03",
  },
  {
    AlphabeticCode: "LUF",
    Currency: "Luxembourg Franc",
    Entity: "LUXEMBOURG",
    MinorUnit: null,
    NumericCode: 442,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "LUL",
    Currency: "Luxembourg Financial Franc",
    Entity: "LUXEMBOURG",
    MinorUnit: null,
    NumericCode: 988,
    WithdrawalDate: "1990-03",
  },
  {
    AlphabeticCode: "MGF",
    Currency: "Malagasy Franc",
    Entity: "MADAGASCAR",
    MinorUnit: null,
    NumericCode: 450,
    WithdrawalDate: "2004-12",
  },
  {
    AlphabeticCode: "MWK",
    Currency: "Kwacha",
    Entity: "MALAWI",
    MinorUnit: null,
    NumericCode: 454,
    WithdrawalDate: "2016-02",
  },
  {
    AlphabeticCode: "MVQ",
    Currency: "Maldive Rupee",
    Entity: "MALDIVES",
    MinorUnit: null,
    NumericCode: 462,
    WithdrawalDate: "1989-12",
  },
  {
    AlphabeticCode: "MLF",
    Currency: "Mali Franc",
    Entity: "MALI",
    MinorUnit: null,
    NumericCode: 466,
    WithdrawalDate: "1984-11",
  },
  {
    AlphabeticCode: "MTL",
    Currency: "Maltese Lira",
    Entity: "MALTA",
    MinorUnit: null,
    NumericCode: 470,
    WithdrawalDate: "2008-01",
  },
  {
    AlphabeticCode: "MTP",
    Currency: "Maltese Pound",
    Entity: "MALTA",
    MinorUnit: null,
    NumericCode: 470,
    WithdrawalDate: "1983-06",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "MARTINIQUE",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "MRO",
    Currency: "Ouguiya",
    Entity: "MAURITANIA",
    MinorUnit: null,
    NumericCode: 478,
    WithdrawalDate: "2017-12",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "MAYOTTE",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "MXP",
    Currency: "Mexican Peso",
    Entity: "MEXICO",
    MinorUnit: null,
    NumericCode: 484,
    WithdrawalDate: "1993-01",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "MOLDOVA, REPUBLIC OF",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1993-12",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "MONACO",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "MZE",
    Currency: "Mozambique Escudo",
    Entity: "MOZAMBIQUE",
    MinorUnit: null,
    NumericCode: 508,
    WithdrawalDate: "1978 to 1981",
  },
  {
    AlphabeticCode: "MZM",
    Currency: "Mozambique Metical",
    Entity: "MOZAMBIQUE",
    MinorUnit: null,
    NumericCode: 508,
    WithdrawalDate: "2006-06",
  },
  {
    AlphabeticCode: "NLG",
    Currency: "Netherlands Guilder",
    Entity: "NETHERLANDS",
    MinorUnit: null,
    NumericCode: 528,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "ANG",
    Currency: "Netherlands Antillean Guilder",
    Entity: "NETHERLANDS ANTILLES",
    MinorUnit: null,
    NumericCode: 532,
    WithdrawalDate: "2010-10",
  },
  {
    AlphabeticCode: "NIC",
    Currency: "Cordoba",
    Entity: "NICARAGUA",
    MinorUnit: null,
    NumericCode: 558,
    WithdrawalDate: "1990-10",
  },
  {
    AlphabeticCode: "PEH",
    Currency: "Sol",
    Entity: "PERU",
    MinorUnit: null,
    NumericCode: 604,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "PEI",
    Currency: "Inti",
    Entity: "PERU",
    MinorUnit: null,
    NumericCode: 604,
    WithdrawalDate: "1991-07",
  },
  {
    AlphabeticCode: "PEN",
    Currency: "Nuevo Sol",
    Entity: "PERU",
    MinorUnit: null,
    NumericCode: 604,
    WithdrawalDate: "2015-12",
  },
  {
    AlphabeticCode: "PES",
    Currency: "Sol",
    Entity: "PERU",
    MinorUnit: null,
    NumericCode: 604,
    WithdrawalDate: "1986-02",
  },
  {
    AlphabeticCode: "PLZ",
    Currency: "Zloty",
    Entity: "POLAND",
    MinorUnit: null,
    NumericCode: 616,
    WithdrawalDate: "1997-01",
  },
  {
    AlphabeticCode: "PTE",
    Currency: "Portuguese Escudo",
    Entity: "PORTUGAL",
    MinorUnit: null,
    NumericCode: 620,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "RÉUNION",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "ROK",
    Currency: "Leu A/52",
    Entity: "ROMANIA",
    MinorUnit: null,
    NumericCode: 642,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "ROL",
    Currency: "Old Leu",
    Entity: "ROMANIA",
    MinorUnit: null,
    NumericCode: 642,
    WithdrawalDate: "2005-06",
  },
  {
    AlphabeticCode: "RON",
    Currency: "New Romanian Leu",
    Entity: "ROMANIA",
    MinorUnit: null,
    NumericCode: 946,
    WithdrawalDate: "2015-06",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "RUSSIAN FEDERATION",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "2004-01",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "SAINT MARTIN",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "1999-01",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "SAINT PIERRE AND MIQUELON",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "FRF",
    Currency: "French Franc",
    Entity: "SAINT-BARTHÉLEMY",
    MinorUnit: null,
    NumericCode: 250,
    WithdrawalDate: "1999-01",
  },
  {
    AlphabeticCode: "ITL",
    Currency: "Italian Lira",
    Entity: "SAN MARINO",
    MinorUnit: null,
    NumericCode: 380,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "STD",
    Currency: "Dobra",
    Entity: "SAO TOME AND PRINCIPE",
    MinorUnit: null,
    NumericCode: 678,
    WithdrawalDate: "2017-12",
  },
  {
    AlphabeticCode: "CSD",
    Currency: "Serbian Dinar",
    Entity: "SERBIA AND MONTENEGRO",
    MinorUnit: null,
    NumericCode: 891,
    WithdrawalDate: "2006-10",
  },
  {
    AlphabeticCode: "EUR",
    Currency: "Euro",
    Entity: "SERBIA AND MONTENEGRO",
    MinorUnit: null,
    NumericCode: 978,
    WithdrawalDate: "2006-10",
  },
  {
    AlphabeticCode: "SKK",
    Currency: "Slovak Koruna",
    Entity: "SLOVAKIA",
    MinorUnit: null,
    NumericCode: 703,
    WithdrawalDate: "2009-01",
  },
  {
    AlphabeticCode: "SIT",
    Currency: "Tolar",
    Entity: "SLOVENIA",
    MinorUnit: null,
    NumericCode: 705,
    WithdrawalDate: "2007-01",
  },
  {
    AlphabeticCode: "ZAL",
    Currency: "Financial Rand",
    Entity: "SOUTH AFRICA",
    MinorUnit: null,
    NumericCode: 991,
    WithdrawalDate: "1995-03",
  },
  {
    AlphabeticCode: "SDG",
    Currency: "Sudanese Pound",
    Entity: "SOUTH SUDAN",
    MinorUnit: null,
    NumericCode: 938,
    WithdrawalDate: "2012-09",
  },
  {
    AlphabeticCode: "RHD",
    Currency: "Rhodesian Dollar",
    Entity: "SOUTHERN RHODESIA",
    MinorUnit: null,
    NumericCode: 716,
    WithdrawalDate: "1978 to 1981",
  },
  {
    AlphabeticCode: "ESA",
    Currency: "Spanish Peseta",
    Entity: "SPAIN",
    MinorUnit: null,
    NumericCode: 996,
    WithdrawalDate: "1978 to 1981",
  },
  {
    AlphabeticCode: "ESB",
    Currency: '"A" Account (convertible Peseta Account)',
    Entity: "SPAIN",
    MinorUnit: null,
    NumericCode: 995,
    WithdrawalDate: "1994-12",
  },
  {
    AlphabeticCode: "ESP",
    Currency: "Spanish Peseta",
    Entity: "SPAIN",
    MinorUnit: null,
    NumericCode: 724,
    WithdrawalDate: "2002-03",
  },
  {
    AlphabeticCode: "SDD",
    Currency: "Sudanese Dinar",
    Entity: "SUDAN",
    MinorUnit: null,
    NumericCode: 736,
    WithdrawalDate: "2007-07",
  },
  {
    AlphabeticCode: "SDP",
    Currency: "Sudanese Pound",
    Entity: "SUDAN",
    MinorUnit: null,
    NumericCode: 736,
    WithdrawalDate: "1998-06",
  },
  {
    AlphabeticCode: "SRG",
    Currency: "Surinam Guilder",
    Entity: "SURINAME",
    MinorUnit: null,
    NumericCode: 740,
    WithdrawalDate: "2003-12",
  },
  {
    AlphabeticCode: "SZL",
    Currency: "Lilangeni",
    Entity: "SWAZILAND",
    MinorUnit: null,
    NumericCode: 748,
    WithdrawalDate: "2018-08",
  },
  {
    AlphabeticCode: "CHC",
    Currency: "WIR Franc (for electronic)",
    Entity: "SWITZERLAND",
    MinorUnit: null,
    NumericCode: 948,
    WithdrawalDate: "2004-11",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "TAJIKISTAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1995-05",
  },
  {
    AlphabeticCode: "TJR",
    Currency: "Tajik Ruble",
    Entity: "TAJIKISTAN",
    MinorUnit: null,
    NumericCode: 762,
    WithdrawalDate: "2001-04",
  },
  {
    AlphabeticCode: "IDR",
    Currency: "Rupiah",
    Entity: "TIMOR-LESTE",
    MinorUnit: null,
    NumericCode: 360,
    WithdrawalDate: "2002-07",
  },
  {
    AlphabeticCode: "TPE",
    Currency: "Timor Escudo",
    Entity: "TIMOR-LESTE",
    MinorUnit: null,
    NumericCode: 626,
    WithdrawalDate: "2002-11",
  },
  {
    AlphabeticCode: "TRL",
    Currency: "Old Turkish Lira",
    Entity: "TURKEY",
    MinorUnit: null,
    NumericCode: 792,
    WithdrawalDate: "2005-12",
  },
  {
    AlphabeticCode: "TRY",
    Currency: "New Turkish Lira",
    Entity: "TURKEY",
    MinorUnit: null,
    NumericCode: 949,
    WithdrawalDate: "2009-01",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "TURKMENISTAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1993-10",
  },
  {
    AlphabeticCode: "TMM",
    Currency: "Turkmenistan Manat",
    Entity: "TURKMENISTAN",
    MinorUnit: null,
    NumericCode: 795,
    WithdrawalDate: "2009-01",
  },
  {
    AlphabeticCode: "UGS",
    Currency: "Uganda Shilling",
    Entity: "UGANDA",
    MinorUnit: null,
    NumericCode: 800,
    WithdrawalDate: "1987-05",
  },
  {
    AlphabeticCode: "UGW",
    Currency: "Old Shilling",
    Entity: "UGANDA",
    MinorUnit: null,
    NumericCode: 800,
    WithdrawalDate: "1989 to 1990",
  },
  {
    AlphabeticCode: "UAK",
    Currency: "Karbovanet",
    Entity: "UKRAINE",
    MinorUnit: null,
    NumericCode: 804,
    WithdrawalDate: "1996-09",
  },
  {
    AlphabeticCode: "SUR",
    Currency: "Rouble",
    Entity: "UNION OF SOVIET SOCIALIST REPUBLICS",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1990-12",
  },
  {
    AlphabeticCode: "USS",
    Currency: "US Dollar (Same day)",
    Entity: "UNITED STATES",
    MinorUnit: null,
    NumericCode: 998,
    WithdrawalDate: "2014-03",
  },
  {
    AlphabeticCode: "UYN",
    Currency: "Old Uruguay Peso",
    Entity: "URUGUAY",
    MinorUnit: null,
    NumericCode: 858,
    WithdrawalDate: "1989-12",
  },
  {
    AlphabeticCode: "UYP",
    Currency: "Uruguayan Peso",
    Entity: "URUGUAY",
    MinorUnit: null,
    NumericCode: 858,
    WithdrawalDate: "1993-03",
  },
  {
    AlphabeticCode: "RUR",
    Currency: "Russian Ruble",
    Entity: "UZBEKISTAN",
    MinorUnit: null,
    NumericCode: 810,
    WithdrawalDate: "1994-07",
  },
  {
    AlphabeticCode: "VEB",
    Currency: "Bolivar",
    Entity: "VENEZUELA",
    MinorUnit: null,
    NumericCode: 862,
    WithdrawalDate: "2008-01",
  },
  {
    AlphabeticCode: "VEF",
    Currency: "Bolivar Fuerte",
    Entity: "VENEZUELA",
    MinorUnit: null,
    NumericCode: 937,
    WithdrawalDate: "2011-12",
  },
  {
    AlphabeticCode: "VEF",
    Currency: "Bolivar",
    Entity: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    MinorUnit: null,
    NumericCode: 937,
    WithdrawalDate: "2016-02",
  },
  {
    AlphabeticCode: "VEF",
    Currency: "Bolívar",
    Entity: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    MinorUnit: null,
    NumericCode: 937,
    WithdrawalDate: "2018-08",
  },
  {
    AlphabeticCode: "VNC",
    Currency: "Old Dong",
    Entity: "VIETNAM",
    MinorUnit: null,
    NumericCode: 704,
    WithdrawalDate: "1989-1990",
  },
  {
    AlphabeticCode: "YDD",
    Currency: "Yemeni Dinar",
    Entity: "YEMEN, DEMOCRATIC",
    MinorUnit: null,
    NumericCode: 720,
    WithdrawalDate: "1991-09",
  },
  {
    AlphabeticCode: "YUD",
    Currency: "New Yugoslavian Dinar",
    Entity: "YUGOSLAVIA",
    MinorUnit: null,
    NumericCode: 890,
    WithdrawalDate: "1990-01",
  },
  {
    AlphabeticCode: "YUM",
    Currency: "New Dinar",
    Entity: "YUGOSLAVIA",
    MinorUnit: null,
    NumericCode: 891,
    WithdrawalDate: "2003-07",
  },
  {
    AlphabeticCode: "YUN",
    Currency: "Yugoslavian Dinar",
    Entity: "YUGOSLAVIA",
    MinorUnit: null,
    NumericCode: 890,
    WithdrawalDate: "1995-11",
  },
  {
    AlphabeticCode: "ZRN",
    Currency: "New Zaire",
    Entity: "ZAIRE",
    MinorUnit: null,
    NumericCode: 180,
    WithdrawalDate: "1999-06",
  },
  {
    AlphabeticCode: "ZRZ",
    Currency: "Zaire",
    Entity: "ZAIRE",
    MinorUnit: null,
    NumericCode: 180,
    WithdrawalDate: "1994-02",
  },
  {
    AlphabeticCode: "ZMK",
    Currency: "Zambian Kwacha",
    Entity: "ZAMBIA",
    MinorUnit: null,
    NumericCode: 894,
    WithdrawalDate: "2012-12",
  },
  {
    AlphabeticCode: "ZWC",
    Currency: "Rhodesian Dollar",
    Entity: "ZIMBABWE",
    MinorUnit: null,
    NumericCode: 716,
    WithdrawalDate: "1989-12",
  },
  {
    AlphabeticCode: "ZWD",
    Currency: "Zimbabwe Dollar (old)",
    Entity: "ZIMBABWE",
    MinorUnit: null,
    NumericCode: 716,
    WithdrawalDate: "2006-08",
  },
  {
    AlphabeticCode: "ZWD",
    Currency: "Zimbabwe Dollar",
    Entity: "ZIMBABWE",
    MinorUnit: null,
    NumericCode: 716,
    WithdrawalDate: "2008-08",
  },
  {
    AlphabeticCode: "ZWN",
    Currency: "Zimbabwe Dollar (new)",
    Entity: "ZIMBABWE",
    MinorUnit: null,
    NumericCode: 942,
    WithdrawalDate: "2006-09",
  },
  {
    AlphabeticCode: "ZWR",
    Currency: "Zimbabwe Dollar",
    Entity: "ZIMBABWE",
    MinorUnit: null,
    NumericCode: 935,
    WithdrawalDate: "2009-06",
  },
  {
    AlphabeticCode: "XFO",
    Currency: "Gold-Franc",
    Entity: "ZZ01_Gold-Franc",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: "2006-10",
  },
  {
    AlphabeticCode: "XRE",
    Currency: "RINET Funds Code",
    Entity: "ZZ02_RINET Funds Code",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: "1999-11",
  },
  {
    AlphabeticCode: "XFU",
    Currency: "UIC-Franc",
    Entity: "ZZ05_UIC-Franc",
    MinorUnit: null,
    NumericCode: null,
    WithdrawalDate: "2013-11",
  },
];

export default currencyCodes;
