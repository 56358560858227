import * as types from "../../constants";
import MrnAPI from "../../utils/api/MrnAPI";

export function createNewMRN(params) {
  return {
    type: types.CREATE_MRN,
    payload: MrnAPI.createNewMRN(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllMRN(params) {
  // console.log(params, "adnakj");
  return {
    type: types.GET_MRN_LIST,
    payload: MrnAPI.getAllMRN(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedMRN(params) {
  // console.log(params, "adnakj");
  return {
    type: types.GET_UPDATED_MRN_LIST,
    payload: MrnAPI.getAllMRN(params).then((response) => {
      return response.data;
    }),
  };
}

export function getMRNDetails(params) {
  return {
    type: types.GET_MRN_DETAILS,
    payload: MrnAPI.getMRNDetails(params).then((response) => {
      return response.data;
    }),
  };
}

export function updateMRN(params) {
  return {
    type: types.UPDATE_MRN,
    payload: MrnAPI.updateMRN(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetState() {
  return {
    type: types.RESET_MRN,
  };
}
