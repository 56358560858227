import * as types from "../../constants";
import ClientAPI from "../../utils/api/ClientAPI";

export function addNewClient(params) {
  console.log("line 45", params);
  return {
    type: types.ADD_NEW_CLIENT,
    payload: ClientAPI.addNewClient(params).then((response) => {
      return response.data;
    }),
  };
}
export function getClientCode() {
  return {
    type: types.GET_CLIENT_CODE,
    payload: ClientAPI.getClientCode().then((response) => {
      return response.data;
    }),
  };
}

export function getTotalClients(params) {
  return {
    type: types.GET_TOTAL_CLIENTS,
    payload: ClientAPI.getTotalClients(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllClientNames(params) {
  return {
    type: types.GET_CLIENT_NAMES,
    payload: ClientAPI.getAllClientNames(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllClients(params) {
  return {
    type: types.GET_CLIENT_LIST,
    payload: ClientAPI.getAllClients(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedClients(params) {
  return {
    type: types.GET_UPDATED_CLIENT_LIST,
    payload: ClientAPI.getAllClients(params).then((response) => {
      return response.data;
    }),
  };
}

export function getClientDetails(params) {
  return {
    type: types.GET_CLIENT_DETAILS,
    payload: ClientAPI.getClientDetails(params).then((response) => {
      return response.data;
    }),
  };
}

export function addNewContact(params) {
  return {
    type: types.ADD_NEW_CONTACT,
    payload: ClientAPI.addNewContact(params).then((response) => {
      return response.data;
    }),
  };
}

export function updateClientData(params) {
  return {
    type: types.UPDATE_CLIENT_DETAILS,
    payload: ClientAPI.updateClientData(params).then((response) => {
      return response.data;
    }),
  };
}

export function updateClientCont(params) {
  return {
    type: types.UPDATE_CLIENT_CONTACT,
    payload: ClientAPI.updateClientCont(params).then((response) => {
      return response.data;
    }),
  };
}

export function deleteClient(params) {
  return {
    type: types.DELETE_CLIENT,
    payload: ClientAPI.deleteClient(params).then((response) => {
      return response.data;
    }),
  };
}

export function deleteClientContact(params) {
  return {
    type: types.DELETE_CLIENT_CONTACT,
    payload: ClientAPI.deleteClientContact(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetState() {
  return {
    type: types.RESET_CLIENT_STATE,
  };
}

export function resetNameList() {
  return {
    type: types.RESET_CLIENT_NAME_LIST,
  };
}

export function getClientDetailsById(params) {
  return {
    type: types.GET_CLIENT_DETAILS_BY_ID,
    payload: ClientAPI.getClientDetailsById(params).then((response) => {
      return response.data;
    }),
  };
}
