import axios from "axios";
import { serverAddress } from "../../constants.js";
const sessionValue = JSON.parse(localStorage.getItem("data"));
const token = sessionValue?.loginData?.user?.token;
const utils = {
  getAllOrders: (params) => {
    let url =
      serverAddress +
      `/order/joborders?page=${params.page}&limit=${
        params.limit
      }&filterStatus=${
        params.filterStatus ? params.filterStatus : ""
      }&caseOwner=${params.caseOwner ? params.caseOwner : ""}&search=${
        params.search ? params.search : ""
      }`;

    return axios.post(url, params.filterStatus, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  getTotalOrders: (params) => {
    let url = serverAddress + "/order/total";
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  updateJobOrder: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/joborder";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  updateOrderSubject: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/order/ordersubject`;
      return axios.post(
        url,
        {
          id: params.id,
          order_subject: params.order_subject,
        },
        {
          headers: { Authorization: `Bearer ${params.token || token}` },
        }
      );
    }
  },

  deleteJobOrder: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/order/${params.id}`;
      return axios.delete(url, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  deleteJobOrderFile: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/order/removefile`;
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  addNewJobOrder: (params) => {
    if (params.clientName !== null) {
      //code to call server api
      let url = serverAddress + "/order/create";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  getLeadDetails: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/client/client-information/${params}`;
      return axios.get(url);
    }
  },
  getClientLeads: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/lead/client/${params}`;
      return axios.get(url, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  updateOrderStatus: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/status";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  updateCaseOwnerAPI: (params) => {
    if (params !== undefined) {
      const url = serverAddress + `/order/caseOwner/${params.id}`;
      return axios.put(
        url,
        { case_owner: params.case_owner },
        {
          headers: { Authorization: `Bearer ${params.token || token}` },
        }
      );
    }
  },

  createDocumentAPI: (params) => {
    const url = `${serverAddress}/jobOrder/document/create`;
    // const formData = new FormData();
    // formData.append("job_order_id", params.job_order_id);
    // formData.append("uploaded_by", params.uploaded_by);
    // formData.append("document_type", params.document_type);

    // params.documents.forEach((doc, index) => {
    //   doc.files.forEach((file) => {
    //     formData.append("files", file);
    //     formData.append(`file_name_${index + 1}`, doc.name);
    //   });
    // });

    return axios.post(url, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateDocumentAPI: (params) => {
    const url = `${serverAddress}/jobOrder/document/update`;
    // const formData = new FormData();
    // formData.append("job_order_id", params.job_order_id);
    // formData.append("document_name", params.document_name);

    // params.files.forEach((file) => {
    //   formData.append("file", file);
    // });

    return axios.put(url, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },

  cancelOrder: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/cancel";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  getOrderById: (params) => {
    let url = serverAddress + `/order/job_order_details/${params.id}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  linkLeadToOrder: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/linklead";
      return axios.put(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  addFilesToOrder: (params, paramToken) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/addfiles";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${paramToken || token}` },
      });
    }
  },

  generateQrCode: (params) => {
    // console.log(params)
    if (params !== undefined) {
      let url = serverAddress + "/order/qrcode";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
  downloadPdf: (params) => {
    // console.log(params)
    if (params !== undefined) {
      let url = serverAddress + "/order/download";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  getOrderStatusCount: (params) => {
    // return axios.post(`${serverAddress}/order/statuscount`, params);
    if (params !== undefined) {
      let url = serverAddress + "/order/orderstatuscount";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },

  getEmployeeOrderStatusCount: (params) => {
    if (params !== undefined) {
      let url = serverAddress + "/order/employeeOrderStatusCount";
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token || token}` },
      });
    }
  },
};

export default utils;
