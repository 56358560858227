import axios from "axios";
import { serverAddress } from "../../constants.js";
const sessionValue = JSON.parse(localStorage.getItem("data"));
const token = sessionValue?.loginData.user.token;

const utils = {
  getAllExports: (params) => {
    let url =
      serverAddress +
      `/exports/all?page=${params.page}&limit=${params.limit}&search=${
        params.search ? params.search : ""
      }&final=${params.final}
      `;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
    //   let url =
    //   serverAddress +
    //   `/exports/all?page=${params.page}&limit=${params.limit}&search=${
    //     params.search ? params.search : ""
    //   }`;
    // return axios.get(url, {
    //   headers: { Authorization: `Bearer ${params.token}` },
    // });
  },
  addFilesToExport: (params) => {
    let url = serverAddress + `/exports/files/${params.export_id}`;
    return axios.put(url, params, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getAllBanks: (params) => {
    let url = serverAddress + "/banks/all";
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  getExportById: (params) => {
    let url = serverAddress + `/exports/${params.id}?final=${params.final}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  createExport: (params) => {
    let url = serverAddress + "/exports/new";
    return axios.post(url, params, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  createExportRevisiom: (params) => {
    let url = serverAddress + `/exports/${params._id}`;
    return axios.put(url, params, {
      headers: { Authorization: `Bearer ${params.token}` },
    });
  },
  createFinalExport: (params) => {
    let url =
      serverAddress + `/exports/download/${params.id}?final=${params.final}`;
    return axios.get(url, {
      headers: { Authorization: `Bearer ${params.token || token}` },
    });
  },
  getDownloadLink: (params) => {
    if (params !== undefined) {
      let url = serverAddress + `/exports/download`;
      return axios.post(url, params, {
        headers: { Authorization: `Bearer ${params.token}` },
      });
    }
  },
};

export default utils;
