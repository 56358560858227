import React from "react";
import { Link } from "react-router-dom";
import { Files } from "../pages/jobOrder/Files";
export const FileManager = (props) => {
  return (
    <div className="content-wrapper theme-primary">
      <header className="main-header">
        <nav className="navbar navbar-expand-lg navbar-expand py-0">
          <div className="container-fluid">
            <div class="logo ">
              <Link class="navbar-brand logo col-md-12" to="/admin">
                <img
                  src="/powertech.png"
                  class="d-flex mx-auto "
                  alt="logo"
                  width="220px"
                />
              </Link>
            </div>

            {/* <div className="col-md-1">
        <button type="button" className="btn btn-outline-primary">
          <i className="fa fa-bars" aria-hidden="true" />
        </button>
      </div> */}
            <div className="collapse navbar-collapse navbar-custom-menu">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown dropdown-user">
                  <a
                    className="nav-link"
                    role="button"
                    data-toggle="dropdown"
                    id="dropdownMenuButton"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div
                    className="dropdown-menu drop_down"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="menus">
                      <a className="dropdown-item" href>
                        <i className="fa fa-user" /> User Profile{" "}
                      </a>
                      <a className="dropdown-item" href>
                        <i className="fa fa-inbox" /> Inbox{" "}
                      </a>
                      <a className="dropdown-item" href>
                        <i className="fa fa-sign-out" /> Signout{" "}
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <section className="content-header" style={{ minHeight: "100vh " }}>
        <div className="row">
          {/* <div className="col-md-1 pr-0 mt-1">
             <button
              type="button"
              className="btn btn-outline-primary mt-1"
              onClick={() => history.goBack()}
              style={{
                padding: "6px 10px",
                borderRadius: "10px",
              }}
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>  
          </div> */}
          <div className="col-md-12 mr-2 ml-n4">
            <div
              className="header-icon mt-1"
              style={{
                "max-width": "2.5rem",
                "max-heigth": "2rem",
                fontSize: "2.5rem",
              }}
            >
              <i className="fa fa-dashboard" />
            </div>
            <div className="header-title ml-5 mt-1 ">
              <h1>All Job Files</h1>
              <small>Production</small>
            </div>
          </div>
        </div>
        <Files content={props} />
      </section>
    </div>
  );
};
