import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  message_error: "",
  receivedResponse: false,
  EmployeeAddResponse: [],
  EmployeeList: [],
  RoleList: [], // Add this line
  isEmployeeAdded: false,
  isEmployeeListFetched: false,
  isEmployeeUpdated: false,
  isRoleListFetched: false, // Add this line
  isPasswordUpdated: false,
  isEmployeeDeleted: false,
};

export default function LeadsReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_ALL_ROLES_FULFILLED:
      return {
        ...state,
        loading: false,
        RoleList: action.payload,
        receivedResponse: 200,
        isRoleListFetched: true,
      };
    case types.GET_ALL_ROLES_PENDING:
      return {
        ...state,
        loading: true,
        receivedResponse: false,
        isRoleListFetched: false,
      };
    case types.GET_ALL_ROLES_REJECTED:
      return {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
    case types.GET_SALES_EMPLOYEES_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        SaleEmployeeList: action.payload,
        receivedResponse: 200,
        isSaleEmployeeListFetched: true,
      };
      break;
    case types.GET_SALES_EMPLOYEES_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isSaleEmployeeListFetched: false,
      };
      break;
    case types.GET_SALES_EMPLOYEES_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.ADD_NEW_EMPLOYEE_FULFILLED:
      state = {
        ...state,
        loading: false,
        EmployeeAddResponse: action.payload,
        receivedResponse: 200,
        isEmployeeAdded: true,
      };
      break;
    case types.ADD_NEW_EMPLOYEE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isEmployeeAdded: false,
      };
      break;
    case types.ADD_NEW_EMPLOYEE_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;

    case types.GET_EMPLOYEES_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        EmployeeList: action.payload,
        receivedResponse: 200,
        isEmployeeListFetched: true,
      };
      break;
    case types.GET_EMPLOYEES_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isEmployeeListFetched: false,
      };
      break;
    case types.GET_EMPLOYEES_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.GET_UPDATED_EMPLOYEES_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        EmployeeList: action.payload,
        receivedResponse: 200,
        // isEmployeeListFetched: true,
      };
      break;
    case types.GET_UPDATED_EMPLOYEES_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isEmployeeListFetched: false,
      };
      break;
    case types.GET_UPDATED_EMPLOYEES_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
      };
      break;
    case types.UPDATE_EMPLOYEE_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isEmployeeUpdated: true,
      };
      break;

    case types.UPDATE_EMPLOYEE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isEmployeeUpdated: false,
      };
      break;

    case types.UPDATE_EMPLOYEE_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isEmployeeUpdated: false,
      };
      break;

    case types.UPDATE_PASSWORD_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isPasswordUpdated: true,
      };
      break;

    case types.UPDATE_PASSWORD_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isPasswordUpdated: false,
      };
      break;

    case types.UPDATE_PASSWORD_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.SOMETHING_WENT_WRONG,
        isPasswordUpdated: false,
      };
      break;

    case types.DELETE_EMPLOYEE_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isEmployeeDeleted: true,
      };
      break;
    case types.DELETE_EMPLOYEE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: 200,
        isEmployeeDeleted: false,
      };
      break;
    case types.DELETE_EMPLOYEE_REJECTED:
      state = {
        ...state,
        loading: false,
        isEmployeeDeleted: false,
      };
      break;

    default:
      state = {
        ...state,
      };
  }
  return state;
}
