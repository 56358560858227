import React, { useEffect, useState } from "react";
import { serverAddress } from "../../../constants.js";
import { Button, Typography } from "antd";
import { FileAddOutlined, CloudDownloadOutlined } from "@ant-design/icons";
export const Files = (props) => {
  let url_path = props.content.path.path;
  let uuid = props.content.filesData?.Order?.jobOrder?.uuid;
  let allFiles = JSON.parse(props.content.filesData?.Order?.jobOrder?.files);
  let merged_link = props.content.filesData?.Order?.jobOrder?.merged_link;
  const [data, setData] = useState(allFiles);
  const [deleteFileLink, setDeleteFileLink] = useState("");
  const { Text } = Typography;
  const [filePath, setFilePath] = useState("");
  const [preview, setPreview] = useState(false);
  const [nonpdf, setNonPdf] = useState(false);
  const [reason, setReason] = useState(false);
  const [error, setError] = useState(false);
  const [files, setFile] = useState();
  const [load, setLoad] = useState(false);
  const [isMergedFile, setMergedFile] = useState(false);
  useEffect(() => {
    setData(allFiles);
    if (url_path.indexOf("job/viewer/files") > 0) {
      setMergedFile(false);
    } else {
      setMergedFile(true);
    }
  }, []);
  const [active, setActive] = useState(0);

  function handleDelete(e) {
    e.preventDefault();
    if (reason === "" || reason !== "delete") {
      setError(true);
    } else {
      setError(false);
      let params = {
        id: uuid,
        link: deleteFileLink,
      };
      props.content.deleteFile(params);
      let filter_file = data.filter((file) => file.link !== deleteFileLink);
      setData(filter_file);
      setActive(0);
      setFilePath(filter_file[0].link);
    }
  }

  const onFileChange = (e) => {
    setFile(e.target.files);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setLoad(true);
    props.content.handleFileUpload(files);
  };

  useEffect(() => {
    if (props.content.filesData.isFilesUploaded) {
      document.getElementById("closejobModal").click();
      setFile(null);
    } else {
      setLoad(false);
    }
    isMergedFile && isMergedFile
      ? setFilePath(merged_link)
      : setFilePath(data[active]?.link);
    setPreview(true);
  }, [props.content.filesData.isFilesUploaded]);

  return (
    <>
      <div className="row mt-5">
        <div className="col-md-4"></div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <Button
            className="dropdown-item mb-2 mt-2 ant-btn ant-btn-primary ant-btn-block"
            type="button"
            onClick={() =>
              props.content.downloadFile({
                link: `${isMergedFile ? merged_link : data[active]?.link}`,
              })
            }
            style={{
              width: "22%",
              position: "relative",
              left: `${isMergedFile ? "57%" : "33%"}`,
            }}
            icon={<CloudDownloadOutlined />}
          >
            Download
          </Button>
          {allFiles?.length > 0 && !isMergedFile && (
            <>
              <Button
                className="dropdown-item mb-2 mt-2 ant-btn ant-btn-primary ant-btn-block"
                type="button"
                style={{
                  width: "22%",
                  position: "relative",
                  left: "33%",
                  marginLeft: "12px",
                }}
                data-toggle="modal"
                data-target="#addFileModal"
                icon={<FileAddOutlined />}
              >
                Add File
              </Button>
            </>
          )}
        </div>
        <div className="col-md-4 bg-white p-2">
          {isMergedFile && isMergedFile ? (
            <Button
              className="mt-3"
              style={{ width: `${isMergedFile ? "100%" : "90%"}` }}
              type={"primary"}
              block
            >
              {merged_link.split("/")[1]}
            </Button>
          ) : (
            data?.map((file, index) => (
              <>
                <Button
                  className="mt-3"
                  style={{ width: "90%" }}
                  type={active == index && "primary"}
                  onClick={() => {
                    setActive(index);
                    setPreview(true);
                    setFilePath(file?.link);
                  }}
                  block
                >
                  {file.name}
                </Button>
                {!isMergedFile && (
                  <span
                    className="col-md-1 bg-white p-2"
                    data-target="#deletefile"
                    data-toggle="modal"
                    onClick={() => {
                      setDeleteFileLink(file.link);
                      setActive(index);
                      // setPreview(false);
                    }}
                  >
                    <i
                      className="fa fa-trash btn btn-sm btn-danger"
                      style={{ fontSize: "18px", cursor: "pointer" }}
                    ></i>
                  </span>
                )}
              </>
            ))
          )}
        </div>

        {preview ? (
          <div className="col-md-7 bg-white ml-3 vh-100">
            {/* <Button
              type="primary"
              className="float-right position-absolute fileGoBack"
              onClick={() => setPreview(false)}
              icon={<RollbackOutlined />}
            >
              Go Back
            </Button> */}

            {/* {allFiles?.length > 0 && (
              <Button
                className="dropdown-item mb-2 mt-2 ant-btn ant-btn-primary ant-btn-block"
                type="button"
                style={{ width: "11%" }}
                data-toggle="modal"
                data-target="#addFileModal"
                icon={<FileAddOutlined />}
              >
                Add File
              </Button>
            )} */}

            {filePath != "" && (
              <iframe
                src={`${serverAddress}/file/single?file_path=${
                  isMergedFile && isMergedFile ? merged_link : filePath
                }`}
                width={"100%"}
                height={"100%"}
              />
            )}
          </div>
        ) : (
          <div className="col-md-7 bg-white ml-3">
            <div className="row mt-3">
              {allFiles?.length > 0 && (
                <Button
                  className="dropdown-item ml-3 mt-2 ant-btn ant-btn-primary ant-btn-block"
                  type="button"
                  style={{ width: "11%" }}
                  data-toggle="modal"
                  data-target="#addFileModal"
                  icon={<FileAddOutlined />}
                >
                  Add File
                </Button>
              )}
            </div>
            {/* <div className="mt-4">
            {data?.length > 0 && (
              <ul className="files">
              
              <li
                className="file-box"
                key={active}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setFilePath(data[active]?.link);
                  setPreview(true);
                }}
              >
                <div className="file-top">
                  <i
                    className="fa fa-file-pdf-o"
                    style={{ color: "red" }}
                  ></i>
                  <i
                    className="fa fa-eye ellips"
                    style={{ fontSize: "14px" }}
                  ></i>
                </div>
                <div className="file-bottom">
                  <h6>{data[active]?.name} </h6>
                  <Text
                    style={{ width: 100 }}
                    ellipsis={{
                      tooltip: data[active]?.name,
                    }}
                  >
                    {data[active]?.name}
                  </Text>
                  <p className="mb-1">{data[active]?.size}</p>
                
                </div>
              </li>
      
        </ul>
            )}
             
            </div> */}
          </div>
        )}
      </div>
      <div
        class="modal fade"
        id="addFileModal"
        tabindex="-1"
        aria-labelledby="addFileModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addFileModalLabel">
                Add files to job order
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                id="closejobModal"
                onClick={() => {
                  setFile(null);
                  setNonPdf(false);
                  setLoad(false);
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body container">
              <p className="mt-n1">
                <span className="text-danger ">Tip:</span> Press{" "}
                <code>ctrl</code> and select the files for multiple files upload
              </p>

              <div className="form-group">
                <input
                  type="file"
                  name="imgCollection"
                  className={`form-control ${nonpdf && "is-invalid"}`}
                  accept=".pdf"
                  onChange={onFileChange}
                  multiple
                />
              </div>
              {files?.length > 0 && (
                <div>
                  <h6>Files choosen</h6>
                  <ol>
                    {Object.keys(files).map((f) => (
                      <li key={f}>{files[f].name}</li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => {
                  setFile(null);
                  setNonPdf(false);
                  setLoad(false);
                }}
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleUpload}
                disabled={load || nonpdf || files?.length == undefined}
              >
                {load ? (
                  <>
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Uploading...
                  </>
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Model */}
      <div
        className="modal fade"
        id="deletefile"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete File
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setReason("");
                  setError(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="form-group">
                To confirm enter <code>delete</code> to delete the file.
                <form>
                  <input
                    type="text"
                    onChange={(event) => {
                      setReason(event.target.value);
                    }}
                    // value={this.state.reason}
                    style={{ width: "100%" }}
                    className="form-control mt-1"
                  />
                  {error && (
                    <p className="text-danger mx-auto col-md-10">
                      Please enter <mark>delete</mark> to delete the file
                    </p>
                  )}
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                // id={lead.id}
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  setReason("");
                  setError(false);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss={`${
                  reason === "" || reason !== "delete" ? "" : "modal"
                }`}
                onClick={(e) => handleDelete(e)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* end here */}
    </>
  );
};
