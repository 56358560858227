// /*server address for api call*/

// export const serverAddress = "https://api-dev.assertit.io/powertech";
// export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS;
// export const serverAddress = "https://api.assertit.io/powertech";
// export const serverAddress = "http://localhost:8008/powertech";
// export const serverAddress = "http://65.0.20.207:3000";
// export const serverAddress = "http://13.232.217.16:3000";
// export const serverAddress = "http://13.232.217.16:3000";
export const serverAddress = "https://server.assertit.io/powertech";

// export const mongoserverAddress = "http://localhost:3000";
export const mongoserverAddress = "http://163.53.86.182:3002";

/* user roles  */
export const ROLE = {
  SuperAdmin: "superadmin",
  Admin: "admin",
  DEO: "deo",
  Sales: "sales",
  purchase: "purchase",
  Orders: "orders",
  Store: "store",
  MRN: "mrn",
  StorePurchase: "storepurchase",
};
export const STATUS = {
  "TO-DO": "TO-DO",
  "IN-PROGRESS": "IN-PROGRESS",
  DONE: "DONE",
  CLOSED: "CLOSED",
};
export const leadLabel = {
  todo: "TO DO",
  inProgress: "IN PROGRESS",
  done: "DONE",
  closed: "CLOSED",
};

export const FOLLOWUP_STATUS = {
  TOFOLLOWUP: "TO FOLLOW UP",
  COMPLETED: "COMPLETED",
  NOTCOMPLETED: "NOT COMPLETED",
};

export const NOTIFICATION = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const NOTIFICATION_FOLLOWUP_TYPE = {
  call: "CALL",
  callForCourse: "CALL_FOR_COURSE",
  visit: "VISIT",
};

export const ERROR_MESSAGES = {
  BULK_UPLOAD_FILE_REQUIRED:
    "Please, select an excel file and Unique text to upload bulk leads.",
  NO_DATA_IN_CURRENT_SECTION: "There is no data in this section.",
  DATA_NOT_FOUND: "Data is not found.",
  ATLEAST_ONE_FILE_REQUIRED: "Please, select at least one file.",
  UN_AUTHORISED: "Please login and try again",
  SOMETHING_WENT_WRONG: "Something went wrong. Please, try again.",
  BAD_REQUEST: "Something went wrong. Please, try again.",
  UN_AUTHORISED_PAGE: "You Are not allowed to view this page",
  MAX_100: "Discount percentage can't be greater than 100",
  MAX_TAX_100: "Tax percentage can't be greater than 100",
  RECEIVED_QUAN_ERROR:
    "Received quantity cannot be greater than available quantity",
  ADMIN_RESTRICTED: "Only admin is allowed to do this action",
  CLIENT_ALREADY_EXISTS: "Client with this name already exists",
};

export const SUCCESS_MESSAGES = {
  BULK_UPLOAD_FILE_SUCCESS: "File data has been uploaded successfully.",
  DOCUMENT_ADDED_SUCCESSFULLY: "Document Added Successfully",
  DOCUMENT_UPDATED_SUCCESSFULLY: "Document Added Successfully",
  CASE_OWNER_UPDATED_SUCCESSFULLY: "Case Owner Updated Successfully",
  LEAD_UPDATED_SUCCESSFULLY: "Lead has been updated successfully.",
  LEAD_STATE_UPDATED_SUCCESSFULLY: "Lead status has been updated successfully.",
  PURCHASE_STATE_UPDATED_SUCCESSFULLY:
    "Purchase Status has been updated successfully",
  CREATE_FINAL_EXPORT_SUCCESS: "Final Export has been created successfully",
  LEAD_REVIEW_UPDATED_SUCCESSFULLY:
    "Lead review has been updated successfully.",
  LEAD_HANDLED_UPDATED_SUCCESSFULLY:
    "Lead handledBy has been updated successfully.",
  LEAD_DELETED_SUCCESSFULLY: "Lead has been deleted successfully.",
  VENDOR_DELETED_SUCCESSFULLY: "Vendor has been deleted successfully.",
  CLIENT_ADDED_SUCCESSFULLY: "client has been added successfully.",
  LEAD_CREATED_SUCCESSFULLY: "Lead has been created successfully.",
  LEAD_MIGRATED_SUCCESSFULLY: "Lead has been migrated successfully.",
  RAW_LEAD_ASSIGNED_SUCCESSFULLY:
    "Selected leads has been assigned successfully.",
  SAVED_SUCCESSFULLY: "Record has been saved successfully.",
  VENDOR_ADDED_SUCCESSFULLY: "vendor has been added successfully.",
  QUOTATION_ADDED_SUCCESSFULLY: "Quotation has been saved successfully",
  QUOTATION_REVISED_SUCCESSFULLY: "Quotation has been revised successfully",
  PURCHASE_REVISION_DELETE_SUCCESSFULLY:
    "Purchase revision has been deleted successfully",
  PURCHASE_ADDED_SUCCESSFULLY: "Purchase Order has been saved successfully",
  PURCHASE_LIST_DELETE_SUCCESSFULY:
    "Purchase list has been deleted successfully",
  QUOTATION_REVISION_DELETED_SUCCESSFULLY:
    "Quotation revision has been deleted successfully",
  FILE_CATEGORY_ADDED_SUCCESSFULLY: "File category has been added successfully",
  EXPORT_FILES_ADDED_SUCCESSFULLY: "Export files has been added successfully",
  CONTACT_ADDED_SUCCESSFULLY: "New Contact details added successfully",
  CLIENT_CONTACT_DELETE_SUCCESSFULLY: "Client contact deleted successfully",
  PURCHASE_REVIEW_UPDATED_SUCCESSFULLY:
    "Purchase review has been updated successfully.",
  FILE_ADDED_SUCCESSFULLY: "File has been added successfully",
  FILE_DELETED_SUCCESSFULLY: "File has been deleted successfully",
  VENDOR_DETAILS_UPDATED_SUCCESSFULLY: "Vendor details updated successfully",
  // VENDOR_DELETED_SUCCESSFULLY: "Vendor deleted successfully",
  CLIENT_DELETED_SUCCESSFULLY: "Client deleted successfully",
  CONTACT_DETAILS_UPDATED_SUCCESSFULLY: "Contact details updated successfully",
  CONTACT_CONTACT_UPDATED_SUCCESSFULLY: "Contact contact updated successfully",

  //Success Message for Employee
  EMPLOYEE_ADDED_SUCCESSFULLY: "Employee has been added successfully.",
  EMPLOYEE_UPDATED_SUCCESSFULLY: "Employee has been updated successfully.",
  EMPLOYEE_PASSWORD_UPDATED_SUCCESSFULLY:
    "Employee password has been updated successfully.",
  EMPLOYEE_DELETED_SUCCESSFULLY: "Employee has been deleted successfully.",

  //Success Message for Job Order
  JOB_ORDER_ADDED_SUCCESSFULLY: "Job Order has been created successfully.",
  JOB_ORDER_UPDATED_SUCCESSFULLY: "Job Order has been updated successfully.",
  ADDED_FILES_JOB_ORDER_SUCCESSFULLY:
    "Files has been added successfully to Job Order",
  JOB_ORDER_CANCELED_SUCCESSFULLY: "Job Order has been canceled successfully.",
  JOB_ORDER_DELETED_SUCCESSFULLY: "Job Order has been deleted successfully.",
  QR_CODE_GENERATED_SUCCESSFULLY: "QR Code has been generated successfully",

  MRN_CREATED_SUCCESSFULLY: "MRN has been created successfully.",
  MRN_UPDATED_SUCCESSFULLY: "MRN has been updated successfully.",
  //Success Message for Inventory
  ITEM_ADDED_SUCCESSFULLY: "Item has been created successfully.",
  STOCK_ADDED_SUCCESSFULLY: "Stock has been added successfully.",
  STOCK_ASSIGN_SUCCESSFULLY: "Stock has been assigned successfully.",
  EXPORT_ADDED_SUCCESSFULLY: "Export invoice has been created successfully.",
  PROFORMA_ADDED_SUCCESSFULLY:
    "Proforma invoice has been created successfully.",
  PROFORMA_UPDATED_SUCCESSFULLY:
    "Proforma invoice has been updated successfully.",
  PACKING_ADDED_SUCCESSFULLY: "PackingList has been created successfully.",
  EXPORT_REVISED_SUCCESSFULLY:
    "Export invoice  revision has been done successfully.",
  PACKING_REVISED_SUCCESSFULLY:
    "PackingList revision has been done successfully.",
};

export const VALIDATION_MESSAGES = {
  FIELD_REQUIRED: "This field is required.",
  CHECK_FORM_FIELDS: "Please, check form fields",
  ATLEAST_ONE_FILE_REQUIRED: "Please, select at least one file.",
  CHECK_DISCOUNT_FIELDS: "Invalid Discount",
  CHECK_FORM_FIELDS_CLIENT:
    'Please, check form fields."Company name and Type is mandatory" ',
  NAME_IS_REQUIRED: "Name required.",
  NAME_IS_INVALID: "Invalid name.",
  EMAIL_IS_REQUIRED: "Email address required.",
  EMAIL_IS_INVALID: "Invalid email address.",
  MOBILE_IS_REQUIRED: "Mobile number required.",
  MOBILE_IS_INVALID: "Invalid mobile number.",
  SCHOOL_NAME_IS_REQUIRED: "School name required.",
  EDUCATION_IS_REQUIRED: "Education required.",
  DATE_OF_BIRTH_IS_REQUIRED: "Date of birth required.",
  CITY_IS_REQUIRED: "City required.",
  STATE_IS_REQUIRED: "State required.",
  SELECT_USER_TO_ASSIGN_LEADS:
    "Please, select a user to assign selected leads.",
  SELECT_STATUS_TO_UPDATE: "Please, select a status.",
  CHECK_EMPLOYEE_FORM_FIELDS:
    'Please, check form fields. "user name, password, designation, department, phone number,email and address" are mandatory ',
  CHECK_LEAD_FORM_FIELDS:
    'Please, check form fields. "Client name, Lead Handled and Project Type" are mandatory ',
  CHECK_PURCHASE_FORM_FIELDS:
    'Please, check form fields. "Vendor name, Job No. and Currency" are mandatory ',
  CHECK_QUOTATION_FORM_FIELDS:
    'Please, check form fields. "Client name, lead and Currency" are mandatory ',
  CHECK_ORDER_FORM_FIELDS:
    'Please, check form fields. "Client name, Case Owner and Production At" are mandatory ',
  CHECK_MRN_FORM_FIELDS: "Please, give acknowledgement of atleast one product",
  CHECK_ITEM_FORM_FIELD:
    'Please, check form fields. "Item Name, Quantity in Stock" are mandatory ',
  CHECK_ASSIGN_QUANTITY:
    "Please, check that the assign quantity should be less than available quantity",
  CHECK_PACKING_FORM_FIELDS:
    'Please, check form fields. "Dimensions and packing with " are mandatory ',
};
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";

/* role constants */
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_FULFILLED = "GET_ALL_ROLES_FULFILLED";
export const GET_ALL_ROLES_PENDING = "GET_ALL_ROLES_PENDING";
export const GET_ALL_ROLES_REJECTED = "GET_ALL_ROLES_REJECTED";

/*File Constants */

export const ADD_NEW_FILE = "ADD_NEW_FILE";
export const ADD_NEW_FILE_FULFILLED = "ADD_NEW_FILE_FULFILLED";
export const ADD_NEW_FILE_PENDING = "ADD_NEW_FILE_PENDING";
export const ADD_NEW_FILE_REJECTED = "ADD_NEW_FILE_REJECTED";

export const ADD_NEW_FILE_CATEGORY = "ADD_NEW_FILE_CATEGORY";
export const ADD_NEW_FILE_CATEGORY_FULFILLED =
  "ADD_NEW_FILE_CATEGORY_FULFILLED";
export const ADD_NEW_FILE_CATEGORY_PENDING = "ADD_NEW_FILE_CATEGORY_PENDING";
export const ADD_NEW_FILE_CATEGORY_REJECTED = "ADD_NEW_FILE_CATEGORY_REJECTED";

export const GET_ALL_FILES = "GET_ALL_FILES";
export const GET_ALL_FILES_FULFILLED = "GET_ALL_FILES_FULFILLED";
export const GET_ALL_FILES_PENDING = "GET_ALL_FILES_PENDING";
export const GET_ALL_FILES_REJECTED = "GET_ALL_FILES_REJECTED";

export const ADD_FILES_TO_EXPORT = "ADD_FILES_TO_EXPORT";
export const ADD_FILES_TO_EXPORT_FULFILLED = "ADD_FILES_TO_EXPORT_FULFILLED";
export const ADD_FILES_TO_EXPORT_PENDING = "ADD_FILES_TO_EXPORT_PENDING";
export const ADD_FILES_TO_EXPORT_REJECTED = "ADD_FILES_TO_EXPORT_REJECTED";

export const CREATE_FINAL_EXPORT = "CREATE_FINAL_EXPORT";
export const CREATE_FINAL_EXPORT_FULFILLED = "CREATE_FINAL_EXPORT_FULFILLED";
export const CREATE_FINAL_EXPORT_PENDING = "CREATE_FINAL_EXPORT_PENDING";
export const CREATE_FINAL_EXPORT_REJECTED = "CREATE_FINAL_EXPORT_REJECTED";

export const GET_FILE_CATEGORIES = "GET_FILE_CATEGORIES";
export const GET_FILE_CATEGORIES_FULFILLED = "GET_FILE_CATEGORIES_FULFILLED";
export const GET_FILE_CATEGORIES_PENDING = "GET_FILE_CATEGORIES_PENDING";
export const GET_FILE_CATEGORIES_REJECTED = "GET_FILE_CATEGORIES_REJECTED";

export const GET_FILES_BY_CATEGORY = "GET_FILES_BY_CATEGORY";
export const GET_FILES_BY_CATEGORY_FULFILLED =
  "GET_FILES_BY_CATEGORY_FULFILLED";
export const GET_FILES_BY_CATEGORY_PENDING = "GET_FILES_BY_CATEGORY_PENDING";
export const GET_FILES_BY_CATEGORY_REJECTED = "GET_FILES_BY_CATEGORY_REJECTED";

export const GET_FILE_DETAILS = "GET_FILE_DETAILS";
export const GET_FILE_DETAILS_FULFILLED = "GET_FILE_DETAILS_FULFILLED";
export const GET_FILE_DETAILS_PENDING = "GET_FILE_DETAILS_PENDING";
export const GET_FILE_DETAILS_REJECTED = "GET_FILE_DETAILS_REJECTED";
/* Employee Constants */
export const ADD_NEW_EMPLOYEE = "ADD_NEW_EMPLOYEE";
export const ADD_NEW_EMPLOYEE_FULFILLED = "ADD_NEW_EMPLOYEE_FULFILLED";
export const ADD_NEW_EMPLOYEE_PENDING = "ADD_NEW_EMPLOYEE_PENDING";
export const ADD_NEW_EMPLOYEE_REJECTED = "ADD_NEW_EMPLOYEE_REJECTED";

export const GET_SALES_EMPLOYEES_LIST = "GET_SALES_EMPLOYEES_LIST";
export const GET_SALES_EMPLOYEES_LIST_FULFILLED =
  "GET_SALES_EMPLOYEES_LIST_FULFILLED";
export const GET_SALES_EMPLOYEES_LIST_PENDING =
  "GET_SALES_EMPLOYEES_LIST_PENDING";
export const GET_SALES_EMPLOYEES_LIST_REJECTED =
  "GET_SALES_EMPLOYEES_LIST_REJECTED";

export const GET_EMPLOYEES_LIST = "GET_EMPLOYEES_LIST";
export const GET_EMPLOYEES_LIST_FULFILLED = "GET_EMPLOYEES_LIST_FULFILLED";
export const GET_EMPLOYEES_LIST_PENDING = "GET_EMPLOYEES_LIST_PENDING";
export const GET_EMPLOYEES_LIST_REJECTED = "GET_EMPLOYEES_LIST_REJECTED";

export const GET_UPDATED_EMPLOYEES_LIST = "GET_UPDATED_EMPLOYEES_LIST";
export const GET_UPDATED_EMPLOYEES_LIST_FULFILLED =
  "GET_UPDATED_EMPLOYEES_LIST_FULFILLED";
export const GET_UPDATED_EMPLOYEES_LIST_PENDING =
  "GET_UPDATED_EMPLOYEES_LIST_PENDING";
export const GET_UPDATED_EMPLOYEES_LIST_REJECTED =
  "GET_UPDATED_EMPLOYEES_LIST_REJECTED";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_FULFILLED = "UPDATE_EMPLOYEE_FULFILLED";
export const UPDATE_EMPLOYEE_PENDING = "UPDATE_EMPLOYEE_PENDING";
export const UPDATE_EMPLOYEE_REJECTED = "UPDATE_EMPLOYEE_REJECTED";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_FULFILLED = "UPDATE_PASSWORD_FULFILLED";
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING";
export const UPDATE_PASSWORD_REJECTED = "UPDATE_PASSWORD_REJECTED";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_FULFILLED = "DELETE_EMPLOYEE_FULFILLED";
export const DELETE_EMPLOYEE_PENDING = "DELETE_EMPLOYEE_PENDING";
export const DELETE_EMPLOYEE_REJECTED = "DELETE_EMPLOYEE_REJECTED";

/* Job Order */
export const ADD_NEW_JOB_ORDER = "ADD_NEW_JOB_ORDER";
export const ADD_NEW_JOB_ORDER_FULFILLED = "ADD_NEW_JOB_ORDER_FULFILLED";
export const ADD_NEW_JOB_ORDER_PENDING = "ADD_NEW_JOB_ORDER_PENDING";
export const ADD_NEW_JOB_ORDER_REJECTED = "ADD_NEW_JOB_ORDER_REJECTED";

export const GET_JOB_ORDERS = "GET_JOB_ORDERS";
export const GET_JOB_ORDERS_FULFILLED = "GET_JOB_ORDERS_FULFILLED";
export const GET_JOB_ORDERS_PENDING = "GET_JOB_ORDERS_PENDING";
export const GET_JOB_ORDERS_REJECTED = "GET_JOB_ORDERS_REJECTED";

export const ADD_FILES_TO_ORDER = "ADD_FILES_TO_ORDER";
export const ADD_FILES_TO_ORDER_FULFILLED = "ADD_FILES_TO_ORDER_FULFILLED";
export const ADD_FILES_TO_ORDER_PENDING = "ADD_FILES_TO_ORDER_PENDING";
export const ADD_FILES_TO_ORDER_REJECTED = "ADD_FILES_TO_ORDER_REJECTED";

export const EXPORT_JOB_ORDERS = "EXPORT_JOB_ORDERS";
export const EXPORT_JOB_ORDERS_FULFILLED = "EXPORT_JOB_ORDERS_FULFILLED";
export const EXPORT_JOB_ORDERS_PENDING = "EXPORT_JOB_ORDERS_PENDING";
export const EXPORT_JOB_ORDERS_REJECTED = "EXPORT_JOB_ORDERS_REJECTED";

export const UPDATE_JOB_ORDER = "UPDATE_JOB_ORDER";
export const UPDATE_JOB_ORDER_FULFILLED = "UPDATE_JOB_ORDER_FULFILLED";
export const UPDATE_JOB_ORDER_PENDING = "UPDATE_JOB_ORDER_PENDING";
export const UPDATE_JOB_ORDER_REJECTED = "UPDATE_JOB_ORDER_REJECTED";

export const GENERATE_QR_CODE = "GENERATE_QR_CODE";
export const GENERATE_QR_CODE_FULFILLED = "GENERATE_QR_CODE_FULFILLED";
export const GENERATE_QR_CODE_PENDING = "GENERATE_QR_CODE_PENDING";
export const GENERATE_QR_CODE_REJECTED = "GENERATE_QR_CODE_REJECTED";

export const DOWNLOAD_JOB_FILES = "DOWNLOAD_JOB_FILES";
export const DOWNLOAD_JOB_FILES_FULFILLED = "DOWNLOAD_JOB_FILES_FULFILLED";
export const DOWNLOAD_JOB_FILES_PENDING = "DOWNLOAD_JOB_FILES_PENDING";
export const DOWNLOAD_JOB_FILES_REJECTED = "DOWNLOAD_JOB_FILES_REJECTED";

export const LINK_LEAD_TO_ORDER = "LINK_LEAD_TO_ORDER";
export const LINK_LEAD_TO_ORDER_FULFILLED = "LINK_LEAD_TO_ORDER_FULFILLED";
export const LINK_LEAD_TO_ORDER_PENDING = "LINK_LEAD_TO_ORDER_PENDING";
export const LINK_LEAD_TO_ORDER_REJECTED = "LINK_LEAD_TO_ORDER_REJECTED";

export const DELETE_JOB_ORDER = "DELETE_JOB_ORDER";
export const DELETE_JOB_ORDER_FULFILLED = "DELETE_JOB_ORDER_FULFILLED";
export const DELETE_JOB_ORDER_PENDING = "DELETE_JOB_ORDER_PENDING";
export const DELETE_JOB_ORDER_REJECTED = "DELETE_JOB_ORDER_REJECTED";

export const CANCEL_JOB_ORDER = "CANCEL_JOB_ORDER";
export const CANCEL_JOB_ORDER_FULFILLED = "CANCEL_JOB_ORDER_FULFILLED";
export const CANCEL_JOB_ORDER_PENDING = "CANCEL_JOB_ORDER_PENDING";
export const CANCEL_JOB_ORDER_REJECTED = "CANCEL_JOB_ORDER_REJECTED";

/* Inventory Constants */
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const ADD_NEW_ITEM_FULFILLED = "ADD_NEW_ITEM_FULFILLED";
export const ADD_NEW_ITEM_PENDING = "ADD_NEW_ITEM_PENDING";
export const ADD_NEW_ITEM_REJECTED = "ADD_NEW_ITEM_REJECTED";

export const ADD_STOCK = "ADD_STOCK";
export const ADD_STOCK_FULFILLED = "ADD_STOCK_FULFILLED";
export const ADD_STOCK_PENDING = "ADD_STOCK_PENDING";
export const ADD_STOCK_REJECTED = "ADD_STOCK_REJECTED";

export const ASSIGN_STOCK = "ASSIGN_STOCK";
export const ASSIGN_STOCK_FULFILLED = "ASSIGN_STOCK_FULFILLED";
export const ASSIGN_STOCK_PENDING = "ASSIGN_STOCK_PENDING";
export const ASSIGN_STOCK_REJECTED = "ASSIGN_STOCK_REJECTED";

export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FULFILLED = "GET_ITEMS_FULFILLED";
export const GET_ITEMS_PENDING = "GET_ITEMS_PENDING";
export const GET_ITEMS_REJECTED = "GET_ITEMS_REJECTED";

export const GET_ALL_ITEMS = "GET_ALL_ITEMS";
export const GET_ALL_ITEMS_FULFILLED = "GET_ALL_ITEMS_FULFILLED";
export const GET_ALL_ITEMS_PENDING = "GET_ALL_ITEMS_PENDING";
export const GET_ALL_ITEMS_REJECTED = "GET_ALL_ITEMS_REJECTED";

export const GET_ITEMS_LEDGER = "GET_ITEMS_LEDGER";
export const GET_ITEMS_LEDGER_FULFILLED = "GET_ITEMS_LEDGER_FULFILLED";
export const GET_ITEMS_LEDGER_PENDING = "GET_ITEMS_LEDGER_PENDING";
export const GET_ITEMS_LEDGER_REJECTED = "GET_ITEMS_LEDGER_REJECTED";

export const GET_UPDATED_ITEMS_LEDGER = "GET_UPDATED_ITEMS_LEDGER";
export const GET_UPDATED_ITEMS_LEDGER_FULFILLED =
  "GET_UPDATED_ITEMS_LEDGER_FULFILLED";
export const GET_UPDATED_ITEMS_LEDGER_PENDING =
  "GET_UPDATED_ITEMS_LEDGER_PENDING";
export const GET_UPDATED_ITEMS_LEDGER_REJECTED =
  "GET_UPDATED_ITEMS_LEDGER_REJECTED";

export const GET_UPDATED_ITEMS = "GET_UPDATED_ITEMS";
export const GET_UPDATED_ITEMS_FULFILLED = "GET_UPDATED_ITEMS_FULFILLED";
export const GET_UPDATED_ITEMS_PENDING = "GET_UPDATED_ITEMS_PENDING";
export const GET_UPDATED_ITEMS_REJECTED = "GET_UPDATED_ITEMS_REJECTED";

export const GET_ITEM_DETAILS = "GET_ITEM_DETAILS";
export const GET_ITEM_DETAILS_FULFILLED = "GET_ITEM_DETAILS_FULFILLED";
export const GET_ITEM_DETAILS_PENDING = "GET_ITEM_DETAILS_PENDING";
export const GET_ITEM_DETAILS_REJECTED = "GET_ITEM_DETAILS_REJECTED";

export const GET_STOCK = "GET_STOCK";
export const GET_STOCK_FULFILLED = "GET_STOCK_FULFILLED";
export const GET_STOCK_PENDING = "GET_STOCK_PENDING";
export const GET_STOCK_REJECTED = "GET_STOCK_REJECTED";

/*Login Constants*/
export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_FULFILLED = "LOGIN_DATA_FULFILLED";
export const LOGIN_DATA_PENDING = "LOGIN_DATA_PENDING";
export const LOGIN_DATA_REJECTED = "LOGIN_DATA_REJECTED";

export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_FULFILLED = "VALIDATE_TOKEN_FULFILLED";
export const VALIDATE_TOKEN_PENDING = "VALIDATE_TOKEN_PENDING";
export const VALIDATE_TOKEN_REJECTED = "VALIDATE_TOKEN_REJECTED";

export const GET_SALES_DOWNLOAD_LINK = "GET_SALES_DOWNLOAD_LINK";
export const GET_SALES_DOWNLOAD_LINK_FULFILLED =
  "GET_SALES_DOWNLOAD_LINK_FULFILLED";
export const GET_SALES_DOWNLOAD_LINK_PENDING =
  "GET_SALES_DOWNLOAD_LINK_PENDING";
export const GET_SALES_DOWNLOAD_LINK_REJECTED =
  "GET_SALES_DOWNLOAD_LINK_REJECTED";
export const LOGOUT = "LOGOUT";

export const GET_PRODUCTION_DOWNLOAD_LINK = "GET_PRODUCTION_DOWNLOAD_LINK";
export const GET_PRODUCTION_DOWNLOAD_LINK_FULFILLED =
  "GET_PRODUCTION_DOWNLOAD_LINK_FULFILLED";
export const GET_PRODUCTION_DOWNLOAD_LINK_PENDING =
  "GET_PRODUCTION_DOWNLOAD_LINK_PENDING";
export const GET_PRODUCTION_DOWNLOAD_LINK_REJECTED =
  "GET_PRODUCTION_DOWNLOAD_LINK_REJECTED";

export const GET_EXPORT_DOWNLOAD_LINK = "GET_EXPORT_DOWNLOAD_LINK";
export const GET_EXPORT_DOWNLOAD_LINK_FULFILLED =
  "GET_EXPORT_DOWNLOAD_LINK_FULFILLED";
export const GET_EXPORT_DOWNLOAD_LINK_PENDING =
  "GET_EXPORT_DOWNLOAD_LINK_PENDING";
export const GET_EXPORT_DOWNLOAD_LINK_REJECTED =
  "GET_EXPORT_DOWNLOAD_LINK_REJECTED";

export const GET_PACKING_DOWNLOAD_LINK = "GET_PACKING_DOWNLOAD_LINK";
export const GET_PACKING_DOWNLOAD_LINK_FULFILLED =
  "GET_PACKING_DOWNLOAD_LINK_FULFILLED";
export const GET_PACKING_DOWNLOAD_LINK_PENDING =
  "GET_PACKING_DOWNLOAD_LINK_PENDING";
export const GET_PACKING_DOWNLOAD_LINK_REJECTED =
  "GET_PACKING_DOWNLOAD_LINK_REJECTED";
/*Client Constants*/
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_FULFILLED = "ADD_NEW_CLIENT_FULFILLED";
export const ADD_NEW_CLIENT_PENDING = "ADD_NEW_CLIENT_PENDING";
export const ADD_NEW_CLIENT_REJECTED = "ADD_NEW_CLIENT_REJECTED";

export const GET_CLIENT_CODE = "GET_CLIENT_CODE";
export const GET_CLIENT_CODE_FULFILLED = "GET_CLIENT_CODE_FULFILLED";
export const GET_CLIENT_CODE_PENDING = "GET_CLIENT_CODE_PENDING";
export const GET_CLIENT_CODE_REJECTED = "GET_CLIENT_CODE_REJECTED";

export const RESET_CLIENT_STATE = "RESET_CLIENT_STATE";
export const RESET_STATE = "RESET_STATE";
export const RESET_CLIENT_NAME_LIST = "RESET_CLIENT_NAME_LIST";

export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_FULFILLED = "GET_CLIENT_LIST_FULFILLED";
export const GET_CLIENT_LIST_PENDING = "GET_CLIENT_LIST_PENDING";
export const GET_CLIENT_LIST_REJECTED = "GET_CLIENT_LIST_REJECTED";

export const GET_UPDATED_CLIENT_LIST = "GET_UPDATED_CLIENT_LIST";
export const GET_UPDATED_CLIENT_LIST_FULFILLED =
  "GET_UPDATED_CLIENT_LIST_FULFILLED";
export const GET_UPDATED_CLIENT_LIST_PENDING =
  "GET_UPDATED_CLIENT_LIST_PENDING";
export const GET_UPDATED_CLIENT_LIST_REJECTED =
  "GET_UPDATED_CLIENT_LIST_REJECTED";

export const GET_CLIENT_LEADS = "GET_CLIENT_LEADS";
export const GET_CLIENT_LEADS_FULFILLED = "GET_CLIENT_LEADS_FULFILLED";
export const GET_CLIENT_LEADS_PENDING = "GET_CLIENT_LEADS_PENDING";
export const GET_CLIENT_LEADS_REJECTED = "GET_CLIENT_LEADS_REJECTED";

export const GET_CLIENT_NAMES = "GET_CLIENT_NAMES";
export const GET_CLIENT_NAMES_FULFILLED = "GET_CLIENT_NAMES_FULFILLED";
export const GET_CLIENT_NAMES_PENDING = "GET_CLIENT_NAMES_PENDING";
export const GET_CLIENT_NAMES_REJECTED = "GET_CLIENT_NAMES_REJECTED";

export const GET_CLIENT_DETAILS = "GET_CLIENT_DETAILS";
export const GET_CLIENT_DETAILS_FULFILLED = "GET_CLIENT_DETAILS_FULFILLED";
export const GET_CLIENT_DETAILS_PENDING = "GET_CLIENT_DETAILS_PENDING";
export const GET_CLIENT_DETAILS_REJECTED = "GET_CLIENT_DETAILS_REJECTED";

export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const ADD_NEW_CONTACT_FULFILLED = "ADD_NEW_CONTACT_FULFILLED";
export const ADD_NEW_CONTACT_PENDING = "ADD_NEW_CONTACT_PENDING";
export const ADD_NEW_CONTACT_REJECTED = "ADD_NEW_CONTACT_REJECTED";

export const UPDATE_CLIENT_DETAILS = "UPDATE_CLIENT_DETAILS";
export const UPDATE_CLIENT_DETAILS_FULFILLED =
  "UPDATE_CLIENT_DETAILS_FULFILLED";
export const UPDATE_CLIENT_DETAILS_PENDING = "UPDATE_CLIENT_DETAILS_PENDING";
export const UPDATE_CLIENT_DETAILS_REJECTED = "UPDATE_CLIENT_DETAILS_REJECTED";

export const UPDATE_CLIENT_CONTACT = "UPDATE_CLIENT_CONTACT";
export const UPDATE_CLIENT_CONTACT_FULFILLED =
  "UPDATE_CLIENT_CONTACT_FULFILLED";
export const UPDATE_CLIENT_CONTACT_PENDING = "UPDATE_CLIENT_CONTACT_PENDING";
export const UPDATE_CLIENT_CONTACT_REJECTED = "UPDATE_CLIENT_CONTACT_REJECTED";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_FULFILLED = "DELETE_CLIENT_FULFILLED";
export const DELETE_CLIENT_PENDING = "DELETE_CLIENT_PENDING";
export const DELETE_CLIENT_REJECTED = "DELETE_CLIENT_REJECTED";

export const DELETE_CLIENT_CONTACT = "DELETE_CLIENT_CONTACT";
export const DELETE_CLIENT_CONTACT_FULFILLED =
  "DELETE_CLIENT_CONTACT_FULFILLED";
export const DELETE_CLIENT_CONTACT_PENDING = "DELETE_CLIENT_CONTACT_PENDING";
export const DELETE_CLIENT_CONTACT_REJECTED = "DELETE_CLIENT_CONTACT_REJECTED";

export const GET_TOTAL_CLIENTS = "GET_TOTAL_CLIENTS";
export const GET_TOTAL_CLIENTS_FULFILLED = "GET_TOTAL_CLIENTS_FULFILLED";
export const GET_TOTAL_CLIENTS_PENDING = "GET_TOTAL_CLIENTS_PENDING";
export const GET_TOTAL_CLIENTS_REJECTED = "GET_TOTAL_CLIENTS_REJECTED";
/*Quotation Constants*/
export const GET_QUOTATION_LIST = "GET_QUOTATION_LIST";
export const GET_QUOTATION_LIST_FULFILLED = "GET_QUOTATION_LIST_FULFILLED";
export const GET_QUOTATION_LIST_PENDING = "GET_QUOTATION_LIST_PENDING";
export const GET_QUOTATION_LIST_REJECTED = "GET_QUOTATION_LIST_REJECTED";

export const GET_UPDATED_QUOTATION_LIST = "GET_UPDATED_QUOTATION_LIST";
export const GET_UPDATED_QUOTATION_LIST_FULFILLED =
  "GET_UPDATED_QUOTATION_LIST_FULFILLED";
export const GET_UPDATED_QUOTATION_LIST_PENDING =
  "GET_UPDATED_QUOTATION_LIST_PENDING";
export const GET_UPDATED_QUOTATION_LIST_REJECTED =
  "GET_UPDATED_QUOTATION_LIST_REJECTED";

export const ADD_NEW_QUOTATION = "ADD_NEW_QUOTATION";
export const ADD_NEW_QUOTATION_FULFILLED = "ADD_NEW_QUOTATION_FULFILLED";
export const ADD_NEW_QUOTATION_PENDING = "ADD_NEW_QUOTATION_PENDING";
export const ADD_NEW_QUOTATION_REJECTED = "ADD_NEW_QUOTATION_REJECTED";

export const GET_QUOTATION_DETAILS = "GET_QUOTATION_DETAILS";
export const GET_QUOTATION_DETAILS_FULFILLED =
  "GET_QUOTATION_DETAILS_FULFILLED";
export const GET_QUOTATION_DETAILS_PENDING = "GET_QUOTATION_DETAILS_PENDING";
export const GET_QUOTATION_DETAILS_REJECTED = "GET_QUOTATION_DETAILS_REJECTED";

export const GET_QUOTATION_DETAILS_BY_LEAD_ID =
  "GET_QUOTATION_DETAILS_BY_LEAD_ID";
export const GET_QUOTATION_DETAILS_BY_LEAD_ID_FULFILLED =
  "GET_QUOTATION_DETAILS_BY_LEAD_ID_FULFILLED";
export const GET_QUOTATION_DETAILS_BY_LEAD_ID_PENDING =
  "GET_QUOTATION_DETAILS_BY_LEAD_ID_PENDING";
export const GET_QUOTATION_DETAILS_BY_LEAD_ID_REJECTED =
  "GET_QUOTATION_DETAILS_BY_LEAD_ID_REJECTED";

export const RESET_QUOTATION = "RESET_QUOTATION";
export const RESET_PURCHASE_REVISION = "RESET_PURCHASE_REVISION";

export const CREATE_REVISION = "CREATE_REVISION";
export const CREATE_REVISION_FULFILLED = "CREATE_REVISION_FULFILLED";
export const CREATE_REVISION_PENDING = "CREATE_REVISION_PENDING";
export const CREATE_REVISION_REJECTED = "CREATE_REVISION_REJECTED";

export const DELETE_REVISION = "DELETE_REVISION";
export const DELETE_REVISION_FULFILLED = "DELETE_REVISION_FULFILLED";
export const DELETE_REVISION_PENDING = "DELETE_REVISION_PENDING";
export const DELETE_REVISION_REJECTED = "DELETE_REVISION_REJECTED";

/*Leads Constants*/
export const GET_LEADS_LIST = "GET_LEADS_LIST";
export const GET_LEADS_LIST_FULFILLED = "GET_LEADS_LIST_FULFILLED";
export const GET_LEADS_LIST_PENDING = "GET_LEADS_LIST_PENDING";
export const GET_LEADS_LIST_REJECTED = "GET_LEADS_LIST_REJECTED";

export const GET_UPDATED_LEADS = "GET_UPDATED_LEADS";
export const GET_UPDATED_LEADS_FULFILLED = "GET_UPDATED_LEADS_FULFILLED";
export const GET_UPDATED_LEADS_PENDING = "GET_UPDATED_LEADS_PENDING";
export const GET_UPDATED_LEADS_REJECTED = "GET_UPDATED_LEADS_REJECTED";

export const GET_EMPLOYEE_LEADS_LIST = "GET_EMPLOYEE_LEADS_LIST";
export const GET_EMPLOYEE_LEADS_LIST_FULFILLED =
  "GET_EMPLOYEE_LEADS_LIST_FULFILLED";
export const GET_EMPLOYEE_LEADS_LIST_PENDING =
  "GET_EMPLOYEE_LEADS_LIST_PENDING";
export const GET_EMPLOYEE_LEADS_LIST_REJECTED =
  "GET_EMPLOYEE_LEADS_LIST_REJECTED";

export const UPDATE_LEAD_STATUS = "UPDATE_LEAD_STATUS";
export const UPDATE_LEAD_STATUS_FULFILLED = "UPDATE_LEAD_STATUS_FULFILLED";
export const UPDATE_LEAD_STATUS_PENDING = "UPDATE_LEAD_STATUS_PENDING";
export const UPDATE_LEAD_STATUS_REJECTED = "UPDATE_LEAD_STATUS_REJECTED";

export const UPDATE_LEAD_HANDLEDBY = "UPDATE_LEAD_HANDLEDBY";
export const UPDATE_LEAD_HANDLEDBY_FULFILLED =
  "UPDATE_LEAD_HANDLEDBY_FULFILLED";
export const UPDATE_LEAD_HANDLEDBY_PENDING = "UPDATE_LEAD_HANDLEDBY_PENDING";
export const UPDATE_LEAD_HANDLEDBY_REJECTED = "UPDATE_LEAD_HANDLEDBY_REJECTED";

export const UPDATE_LEAD_REMARK = "UPDATE_LEAD_REMARK";
export const UPDATE_LEAD_REMARK_FULFILLED = "UPDATE_LEAD_REMARK  _FULFILLED";
export const UPDATE_LEAD_REMARK_PENDING = "UPDATE_LEAD_REMARK_PENDING";
export const UPDATE_LEAD_REMARK_REJECTED = "UPDATE_LEAD_REMARK   _REJECTED";

export const ADD_NEW_LEAD = "ADD_NEW_LEAD";
export const ADD_NEW_LEAD_FULFILLED = "ADD_NEW_LEAD_FULFILLED";
export const ADD_NEW_LEAD_PENDING = "ADD_NEW_LEAD_PENDING";
export const ADD_NEW_LEAD_REJECTED = "ADD_NEW_LEAD_REJECTED";

export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_FULFILLED = "DELETE_LEAD_FULFILLED";
export const DELETE_LEAD_PENDING = "DELETE_LEAD_PENDING";
export const DELETE_LEAD_REJECTED = "DELETE_LEAD_REJECTED";

export const GET_CLOSED_LEADS = "GET_CLOSED_LEADS";
export const GET_CLOSED_LEADS_FULFILLED = "GET_CLOSED_LEADS_FULFILLED";
export const GET_CLOSED_LEADS_PENDING = "GET_CLOSED_LEADS_PENDING";
export const GET_CLOSED_LEADS_REJECTED = "GET_CLOSED_LEADS_REJECTED";

export const GET_LEADS_COUNT = "GET_LEADS_COUNT";
export const GET_ALL_LEAD_COUNT_FULFILLED = "GET_ALL_LEAD_COUNT_FULFILLED";
export const GET_ALL_LEAD_COUNT_PENDING = "GET_ALL_LEAD_COUNT_PENDING";
export const GET_ALL_LEAD_COUNT_REJECTED = "GET_ALL_LEAD_COUNT_REJECTED";

export const GET_PENDING_LEADS = "GET_PENDING_LEADS";
export const GET_PENDING_LEADS_FULFILLED = "GET_PENDING_LEADS_FULFILLED";
export const GET_PENDING_LEADS_PENDING = "GET_PENDING_LEADS_PENDING";
export const GET_PENDING_LEADS_REJECTED = "GET_PENDING_LEADS_REJECTED";

export const GET_TOTAL_OFFER_SEND = "GET_TOTAL_OFFER_SEND";
export const GET_TOTAL_OFFER_SEND_FULFILLED = "GET_TOTAL_OFFER_SEND_FULFILLED";
export const GET_TOTAL_OFFER_SEND_PENDING = "GET_TOTAL_OFFER_SEND_PENDING";
export const GET_TOTAL_OFFER_SEND_REJECTED = "GET_TOTAL_OFFER_SEND_REJECTED";

export const GET_LEAD_STATUS_COUNT = "GET_LEAD_STATUS_COUNT";
export const GET_LEAD_STATUS_COUNT_FULFILLED =
  "GET_LEAD_STATUS_COUNT_FULFILLED";
export const GET_LEAD_STATUS_COUNT_PENDING = "GET_LEAD_STATUS_COUNT_PENDING";
export const GET_LEAD_STATUS_COUNT_REJECTED = "GET_LEAD_STATUS_COUNT_REJECTED";

export const GET_EMPLOYEE_LEAD_STATUS_COUNT = "GET_EMPLOYEE_LEAD_STATUS_COUNT";
export const GET_EMPLOYEE_LEAD_STATUS_COUNT_FULFILLED =
  "GET_EMPLOYEE_LEAD_STATUS_COUNT_FULFILLED";
export const GET_EMPLOYEE_LEAD_STATUS_COUNT_PENDING =
  "GET_EMPLOYEE_LEAD_STATUS_COUNT_PENDING";
export const GET_EMPLOYEE_LEAD_STATUS_COUNT_REJECTED =
  "GET_EMPLOYEE_LEAD_STATUS_COUNT_REJECTED";

export const GET_REVISED_QUOTATION_OFFER_SEND =
  "GET_REVISED_QUOTATION_OFFER_SEND";
export const GET_REVISED_QUOTATION_OFFER_SEND_FULFILLED =
  "GET_REVISED_QUOTATION_OFFER_SEND_FULFILLED";
export const GET_REVISED_QUOTATION_OFFER_SEND_PENDING =
  "GET_REVISED_QUOTATION_OFFER_SEND_PENDING";
export const GGET_REVISED_QUOTATION_OFFER_SEND_REJECTED =
  "GET_REVISED_QUOTATION_OFFER_SEND_REJECTED";
/* Vendor Constants*/
export const GET_VENDORS_LIST = "GET_VENDORS_LIST";
export const GET_VENDORS_LIST_FULFILLED = "GET_VENDORS_LIST_FULFILLED";
export const GET_VENDORS_LIST_PENDING = "GET_VENDORS_LIST_PENDING";
export const GET_VENDORS_LIST_REJECTED = "GET_VENDORS_LIST_REJECTED";

export const GET_UPDATED_VENDORS_LIST = "GET_UPDATED_VENDORS_LIST";
export const GET_UPDATED_VENDORS_LIST_FULFILLED =
  "GET_UPDATED_VENDORS_LIST_FULFILLED";
export const GET_UPDATED_VENDORS_LIST_PENDING =
  "GET_UPDATED_VENDORS_LIST_PENDING";
export const GET_UPDATED_VENDORS_LIST_REJECTED =
  "GET_UPDATED_VENDORS_LIST_REJECTED";

export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_FULFILLED = "DELETE_VENDOR_FULFILLED";
export const DELETE_VENDOR_PENDING = "DELETE_VENDOR_PENDING";
export const DELETE_VENDOR_REJECTED = "DELETE_VENDOR_REJECTED";

export const ADD_NEW_VENDOR = "ADD_NEW_VENDOR";
export const ADD_NEW_VENDOR_FULFILLED = "ADD_NEW_VENDOR_FULFILLED";
export const ADD_NEW_VENDOR_PENDING = "ADD_NEW_VENDOR_PENDING";
export const ADD_NEW_VENDOR_REJECTED = "ADD_NEW_VENDOR_REJECTED";

export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const GET_VENDOR_DETAILS_FULFILLED = "GET_VENDOR_DETAILS_FULFILLED";
export const GET_VENDOR_DETAILS_PENDING = "GET_VENDOR_DETAILS_PENDING";
export const GET_VENDOR_DETAILS_REJECTED = "GET_VENDOR_DETAILS_REJECTED";

export const GET_VENDOR_DETAILS_PURCHASES = "GET_VENDOR_DETAILS_PURCHASES";
export const GET_VENDOR_DETAILS_PURCHASES_FULFILLED =
  "GET_VENDOR_DETAILS_PURCHASES_FULFILLED";
export const GET_VENDOR_DETAILS_PURCHASES_PENDING =
  "GET_VENDOR_DETAILS_PURCHASES_PENDING";
export const GET_VENDOR_DETAILS_PURCHASES_REJECTED =
  "GET_VENDOR_DETAILS_PURCHASES_REJECTED";

export const UPDATE_VENDOR_DETAILS = "UPDATE_VENDOR_DETAILS";
export const UPDATE_VENDOR_DETAILS_FULFILLED =
  "UPDATE_VENDOR_DETAILS_FULFILLED";
export const UPDATE_VENDOR_DETAILS_PENDING = "UPDATE_VENDOR_DETAILS_PENDING";
export const UPDATE_VENDOR_DETAILS_REJECTED = "UPDATE_VENDOR_DETAILS_REJECTED";

export const RESET_VENDOR = "RESET_VENDOR";
export const RESET_VENDOR_LIST = "RESET_VENDOR_LIST";
/*Purchase Constants */
export const GET_PURCHASE_LIST = "GET_PURCHASE_LIST";
export const GET_PURCHASE_LIST_FULFILLED = "GET_PURCHASE_LIST_FULFILLED";
export const GET_PURCHASE_LIST_PENDING = "GET_PURCHASE_LIST_PENDING";
export const GET_PURCHASE_LIST_REJECTED = "GET_PURCHASE_LIST_REJECTED";

export const GET_UPDATED_PURCHASE_LIST = "GET_UPDATED_PURCHASE_LIST";
export const GET_UPDATED_PURCHASE_LIST_FULFILLED =
  "GET_UPDATED_PURCHASE_LIST_FULFILLED";
export const GET_UPDATED_PURCHASE_LIST_PENDING =
  "GET_UPDATED_PURCHASE_LIST_PENDING";
export const GET_UPDATED_PURCHASE_LIST_REJECTED =
  "GET_UPDATED_PURCHASE_LIST_REJECTED";

export const PURCHASE_REVISION_DELELTE = "PURCHASE_REVISION_DELELTE";
export const PURCHASE_REVISION_DELELTE_FULFILLED =
  "PURCHASE_REVISION_DELELTE_FULFILLED";
export const PURCHASE_REVISION_DELELTE_PENDING =
  "PURCHASE_REVISION_DELELTE_PENDING";
export const PURCHASE_REVISION_DELELTE_REJECTED =
  "PURCHASE_REVISION_DELELTE_REJECTED";

export const PURCHASE_SINGLE_REVISION_DELELTE =
  "PURCHASE_SINGLE_REVISION_DELELTE";
export const PURCHASE_SINGLE_REVISION_DELELTE_FULFILLED =
  "PURCHASE_SINGLE_REVISION_DELELTE_FULFILLED";
export const PURCHASE_SINGLE_REVISION_DELELTE_PENDING =
  "PURCHASE_SINGLE_REVISION_DELELTE_PENDING";
export const PURCHASE_SINGLE_REVISION_DELELTE_REJECTED =
  "PURCHASE_SINGLE_REVISION_DELELTE_REJECTED";

export const ADD_NEW_PURCHASE_ORDER = "ADD_NEW_PURCHASE_ORDER";
export const ADD_NEW_PURCHASE_ORDER_FULFILLED =
  "ADD_NEW_PURCHASE_ORDER_FULFILLED";
export const ADD_NEW_PURCHASE_ORDER_PENDING = "ADD_NEW_PURCHASE_ORDER_PENDING";
export const ADD_NEW_PURCHASE_ORDER_REJECTED =
  "ADD_NEW_PURCHASE_ORDER_REJECTED";

export const RESET_PURCHASE = "RESET_PURCHASE";

export const GET_PURCHASE_DETAILS = "GET_PURCHASE_DETAILS";
export const GET_PURCHASE_DETAILS_FULFILLED = "GET_PURCHASE_DETAILS_FULFILLED";
export const GET_PURCHASE_DETAILS_PENDING = "GET_PURCHASE_DETAILS_PENDING";
export const GET_PURCHASE_DETAILS_REJECTED = "GET_PURCHASE_DETAILS_REJECTED";

export const GET_JOB_ORDER_PURPOSE_COUNT = "GET_JOB_ORDER_PURPOSE_COUNT";
export const GET_JOB_ORDER_PURPOSE_COUNT_FULFILLED =
  "GET_JOB_ORDER_PURPOSE_COUNT_FULFILLED";
export const GET_JOB_ORDER_PURPOSE_COUNT_PENDING =
  "GET_JOB_ORDER_PURPOSE_COUNT_PENDING";
export const GET_JOB_ORDER_PURPOSE_COUNT_REJECTED =
  "GET_JOB_ORDER_PURPOSE_COUNT_REJECTED";

export const GET_PURCHASE_ORDER_STATUS_COUNT =
  "GET_PURCHASE_ORDER_STATUS_COUNT";
export const GET_PURCHASE_ORDER_STATUS_COUNT_FULFILLED =
  "GET_PURCHASE_ORDER_STATUS_COUNT_FULFILLED";
export const GET_PURCHASE_ORDER_STATUS_COUNT_PENDING =
  "GET_PURCHASE_ORDER_STATUS_COUNT_PENDING";
export const GET_PURCHASE_ORDER_STATUS_COUNT_REJECTED =
  "GET_PURCHASE_ORDER_STATUS_COUNT_REJECTED";

export const UPDATE_PURCHASE_STATUS = "UPDATE_PURCHASE_STATUS";
export const UPDATE_PURCHASE_STATUS_FULFILLED =
  "UPDATE_PURCHASE_STATUS_FULFILLED";
export const UPDATE_PURCHASE_STATUS_PENDING = "UPDATE_PURCHASE_STATUS_PENDING";
export const UPDATE_PURCHASE_STATUS_REJECTED =
  "UPDATE_PURCHASE_STATUS_REJECTED";

export const UPDATE_PURCHASE_REMARKS = "UPDATE_PURCHASE_REMARKS";
export const UPDATE_PURCHASE_REMARKS_FULFILLED =
  "UPDATE_PURCHASE_REMARKS_FULFILLED";
export const UPDATE_PURCHASE_REMARKS_PENDING =
  "UPDATE_PURCHASE_REMARKS_PENDING";
export const UPDATE_PURCHASE_REMARKS_REJECTED =
  "UPDATE_PURCHASE_REMARKS_REJECTED";

export const CREATE_PURCHASE_REVISION = "CREATE_PURCHASE_REVISION";
export const CREATE_PURCHASE_REVISION_FULFILLED =
  "CREATE_PURCHASE_REVISION_FULFILLED";
export const CREATE_PURCHASE_REVISION_PENDING =
  "CREATE_PURCHASE_REVISION_PENDING";
export const CREATE_PURCHASE_REVISION_REJECTED =
  "CREATE_PURCHASE_REVISION_REJECTED";

/*Order constants */

export const GET_ORDER_STATUS_COUNT = "GET_ORDER_STATUS_COUNT";
export const GET_ORDER_STATUS_COUNT_FULFILLED =
  "GET_ORDER_STATUS_COUNT_FULFILLED";
export const GET_ORDER_STATUS_COUNT_PENDING = "GET_ORDER_STATUS_COUNT_PENDING";
export const GET_ORDER_STATUS_COUNT_REJECTED =
  "GET_ORDER_STATUS_COUNT_REJECTED";

export const GET_EMPLOYEE_ORDER_STATUS_COUNT =
  "GET_EMPLOYEE_ORDER_STATUS_COUNT";
export const GET_EMPLOYEE_ORDER_STATUS_COUNT_FULFILLED =
  "GET_EMPLOYEE_ORDER_STATUS_COUNT_FULFILLED";
export const GET_EMPLOYEE_ORDER_STATUS_COUNT_PENDING =
  "GET_EMPLOYEE_ORDER_STATUS_COUNT_PENDING";
export const GET_EMPLOYEE_ORDER_STATUS_COUNT_REJECTED =
  "GET_EMPLOYEE_ORDER_STATUS_COUNT_REJECTED";

export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const GET_ORDER_LIST_FULFILLED = "GET_ORDER_LIST_FULFILLED";
export const GET_ORDER_LIST_PENDING = "GET_ORDER_LIST_PENDING";
export const GET_ORDER_LIST_REJECTED = "GET_ORDER_LIST_REJECTED";

export const GET_UPDATED_ORDER_LIST = "GET_UPDATED_ORDER_LIST";
export const GET_UPDATED_ORDER_LIST_FULFILLED =
  "GET_UPDATED_ORDER_LIST_FULFILLED";
export const GET_UPDATED_ORDER_LIST_PENDING = "GET_UPDATED_ORDER_LIST_PENDING";
export const GET_UPDATED_ORDER_LIST_REJECTED =
  "GET_UPDATED_ORDER_LIST_REJECTED";

export const ADD_NEW_ORDER = "ADD_NEW_ORDER";
export const ADD_NEW_ORDER_FULFILLED = "ADD_NEW_ORDER_FULFILLED";
export const ADD_NEW_ORDER_PENDING = "ADD_NEW_ORDER_PENDING";
export const ADD_NEW_ORDER_REJECTED = "ADD_NEW_ORDER_REJECTED";

export const UPDATE_CASE_OWNER = "UPDATE_CASE_OWNER";
export const UPDATE_CASE_OWNER_FULFILLED = "UPDATE_CASE_OWNER_FULFILLED";
export const UPDATE_CASE_OWNER_PENDING = "UPDATE_CASE_OWNER_PENDING";
export const UPDATE_CASE_OWNER_REJECTED = "UPDATE_CASE_OWNER_REJECTED";

export const CREATE_DOCUMENT = "CREATE_INTERNAL_DOCUMENT";
export const CREATE_DOCUMENT_FULFILLED = "CREATE_INTERNAL_DOCUMENT_FULFILLED";
export const CREATE_DOCUMENT_PENDING = "CREATE_INTERNAL_DOCUMENT_PENDING";
export const CREATE_DOCUMENT_REJECTED = "CREATE_INTERNAL_DOCUMENT_REJECTED";

export const UPDATE_DOCUMENT = "UPDATE_INTERNAL_DOCUMENT";
export const UPDATE_DOCUMENT_FULFILLED = "UPDATE_INTERNAL_DOCUMENT_FULFILLED";
export const UPDATE_DOCUMENT_PENDING = "UPDATE_INTERNAL_DOCUMENT_PENDING";
export const UPDATE_DOCUMENT_REJECTED = "UPDATE_INTERNAL_DOCUMENT_REJECTED";

export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const UPDATE_ORDER_STATUS_FULFILLED = "UPDATE_ORDER_STATUS_FULFILLED";
export const UPDATE_ORDER_STATUS_PENDING = "UPDATE_ORDER_STATUS_PENDING";
export const UPDATE_ORDER_STATUS_REJECTED = "UPDATE_ORDER_STATUS_REJECTED";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_FULFILLED = "CANCEL_ORDER_FULFILLED";
export const CANCEL_ORDER_PENDING = "CANCEL_ORDER_PENDING";
export const CANCEL_ORDER_REJECTED = "CANCEL_ORDER_REJECTED";

export const GET_TOTAL_ORDERS = "GET_TOTAL_ORDERS";
export const GET_TOTAL_ORDERS_FULFILLED = "GET_TOTAL_ORDERS_FULFILLED";
export const GET_TOTAL_ORDERS_PENDING = "GET_TOTAL_ORDERS_PENDING";
export const GET_TOTAL_ORDERS_REJECTED = "GET_TOTAL_ORDERS_REJECTED";
/* MRN constants */

export const CREATE_MRN = "CREATE_MRN";
export const CREATE_MRN_FULFILLED = "CREATE_MRN_FULFILLED";
export const CREATE_MRN_PENDING = "CREATE_MRN_PENDING";
export const CREATE_MRN_REJECTED = "CREATE_MRN_REJECTED";

export const GET_MRN_DETAILS = "GET_MRN_DETAILS";
export const GET_MRN_DETAILS_FULFILLED = "GET_MRN_DETAILS_FULFILLED";
export const GET_MRN_DETAILS_PENDING = "GET_MRN_DETAILS_PENDING";
export const GET_MRN_DETAILS_REJECTED = "GET_MRN_DETAILS_REJECTED";

export const GET_MRN_LIST = "GET_MRN_LIST";
export const GET_MRN_LIST_FULFILLED = "GET_MRN_LIST_FULFILLED";
export const GET_MRN_LIST_PENDING = "GET_MRN_LIST_PENDING";
export const GET_MRN_LIST_REJECTED = "GET_MRN_LIST_REJECTED";

export const GET_UPDATED_MRN_LIST = "GET_UPDATED_MRN_LIST";
export const GET_UPDATED_MRN_LIST_FULFILLED = "GET_UPDATED_MRN_LIST_FULFILLED";
export const GET_UPDATED_MRN_LIST_PENDING = "GET_UPDATED_MRN_LIST_PENDING";
export const GET_UPDATED_MRN_LIST_REJECTED = "GET_UPDATED_MRN_LIST_REJECTED";

export const UPDATE_MRN = "UPDATE_MRN";
export const UPDATE_MRN_FULFILLED = "UPDATE_MRN_FULFILLED";
export const UPDATE_MRN_PENDING = "UPDATE_MRN_PENDING";
export const UPDATE_MRN_REJECTED = "UPDATE_MRN_REJECTED";

export const RESET_MRN = "RESET_MRN";

export const GET_JOB_ORDER_BY_ID = "GET_JOB_ORDER_BY_ID";
export const GET_JOB_ORDER_BY_ID_FULFILLED = "GET_JOB_ORDER_BY_ID_FULFILLED";
export const GET_JOB_ORDER_BY_ID_PENDING = "GET_JOB_ORDER_BY_ID_PENDING";
export const GET_JOB_ORDER_BY_ID_REJECTED = "GET_JOB_ORDER_BY_ID_REJECTED";

export const DELETE_JOB_ORDER_FILE = "DELETE_JOB_ORDER_FILE";
export const DELETE_JOB_ORDER_BY_ID_FULFILLED =
  "DELETE_JOB_ORDER_BY_ID_FULFILLED";
export const DELETE_JOB_ORDER_BY__PENDING = "DELETE_JOB_ORDER_BY__PENDING";
export const DELETE_JOB_ORDER_BY__REJECTED = "DELETE_JOB_ORDER_BY__REJECTED";

export const GET_CLIENT_DETAILS_BY_ID = "GET_CLIENT_DETAILS_BY_ID";
export const GET_CLIENT_DETAILS_BY_ID_FULFILLED =
  "GET_CLIENT_DETAILS_BY_ID_FULFILLED";
export const GET_CLIENT_DETAILS_BY_ID_PENDING =
  "GET_CLIENT_DETAILS_BY_ID_PENDING";
export const GET_CLIENT_DETAILS_BY_ID_REJECTED =
  "GET_CLIENT_DETAILS_BY_ID_REJECTED";

/*Export Contants */
export const CREATE_EXPORT = "CREATE_EXPORT";
export const CREATE_EXPORT_FULFILLED = "CREATE_EXPORT_FULFILLED";
export const CREATE_EXPORT_PENDING = "CREATE_EXPORT_PENDING";
export const CREATE_EXPORT_REJECTED = "CREATE_EXPORT_REJECTED";

export const GET_BANK_LIST = "GET_BANK_LIST";
export const GET_BANK_LIST_FULFILLED = "GET_BANK_LIST_FULFILLED";
export const GET_BANK_LIST_PENDING = "GET_BANK_LIST_PENDING";
export const GET_BANK_LIST_REJECTED = "GET_BANK_LIST_REJECTED";

export const CREATE_EXPORT_REVISION = "CREATE_EXPORT_REVISION";
export const CREATE_EXPORT_REVISION_FULFILLED =
  "CREATE_EXPORT_REVISION_FULFILLED";
export const CREATE_EXPORT_REVISION_PENDING = "CREATE_EXPORT_REVISION_PENDING";
export const CREATE_EXPORT_REVISION_REJECTED =
  "CREATE_EXPORT_REVISION_REJECTED";

export const GET_ALL_EXPORTS = "GET_ALL_EXPORTS";
export const GET_ALL_EXPORTS_FULFILLED = "GET_ALL_EXPORTS_FULFILLED";
export const GET_ALL_EXPORTS_PENDING = "GET_ALL_EXPORTS_PENDING";
export const GET_ALL_EXPORTS_REJECTED = "GET_ALL_EXPORTS_REJECTED";

export const GET_UPDATED_EXPORTS = "GET_UPDATED_EXPORTS";
export const GET_UPDATED_EXPORTS_FULFILLED = "GET_UPDATED_EXPORTS_FULFILLED";
export const GET_UPDATED_EXPORTS_PENDING = "GET_UPDATED_EXPORTS_PENDING";
export const GET_UPDATED_EXPORTS_REJECTED = "GET_UPDATED_EXPORTS_REJECTED";

export const GET_EXPORT_DETAILS = "GET_EXPORT_DETAILS";
export const GET_EXPORT_DETAILS_FULFILLED = "GET_EXPORT_DETAILS_FULFILLED";
export const GET_EXPORT_DETAILS_PENDING = "GET_EXPORT_DETAILS_PENDING";
export const GET_EXPORT_DETAILS_REJECTED = "GET_EXPORT_DETAILS_REJECTED";

export const GET_ALL_PROFORMA = "GET_ALL_PROFORMA";
export const GET_ALL_PROFORMA_FULFILLED = "GET_ALL_PROFORMA_FULFILLED";
export const GET_ALL_PROFORMA_PENDING = "GET_ALL_PROFORMA_PENDING";
export const GET_ALL_PROFORMA_REJECTED = "GET_ALL_PROFORMA_REJECTED";

export const GET_UPDATED_PROFORMAS = "GET_UPDATED_PROFORMAS";
export const GET_UPDATED_PROFORMAS_FULFILLED =
  "GET_UPDATED_PROFORMAS_FULFILLED";
export const GET_UPDATED_PROFORMAS_PENDING = "GET_UPDATED_PROFORMAS_PENDING";
export const GET_UPDATED_PROFORMAS_REJECTED = "GET_UPDATED_PROFORMAS_REJECTED";

export const GET_PROFORMA_DETAILS = "GET_PROFORMA_DETAILS";
export const GET_PROFORMA_DETAILS_FULFILLED = "GET_PROFORMA_DETAILS_FULFILLED";
export const GET_PROFORMA_DETAILS_PENDING = "GET_PROFORMA_DETAILS_PENDING";
export const GET_PROFORMA_DETAILS_REJECTED = "GET_PROFORMA_DETAILS_REJECTED";

export const CREATE_PROFORMA = "CREATE_PROFORMA";
export const CREATE_PROFORMA_FULFILLED = "CREATE_PROFORMA_FULFILLED";
export const CREATE_PROFORMA_PENDING = "CREATE_PROFORMA_PENDING";
export const CREATE_PROFORMA_REJECTED = "CREATE_PROFORMA_REJECTED";

export const CREATE_PROFORMA_REVISION = "CREATE_PROFORMA_REVISION";
export const CREATE_PROFORMA_REVISION_FULFILLED =
  "CREATE_PROFORMA_REVISION_FULFILLED";
export const CREATE_PROFORMA_REVISION_PENDING =
  "CREATE_PROFORMA_REVISION_PENDING";
export const CREATE_PROFORMA_REVISION_REJECTED =
  "CREATE_PROFORMA_REVISION_REJECTED";

export const RESET_EXPORT = "RESET_EXPORT";
export const RESET_PACKING = "RESET_PACKING";
export const RESET_EXPORT_DATA = "RESET_EXPORT_DATA";
export const CREATE_PACKING = "CREATE_PACKING";
export const CREATE_PACKING_FULFILLED = "CREATE_PACKING_FULFILLED";
export const CREATE_PACKING_PENDING = "CREATE_PACKING_PENDING";
export const CREATE_PACKING_REJECTED = "CREATE_PACKING_REJECTED";

export const CREATE_PACKING_REVISION = "CREATE_PACKING_REVISION";
export const CREATE_PACKING_REVISION_FULFILLED =
  "CREATE_PACKING_REVISION_FULFILLED";
export const CREATE_PACKING_REVISION_PENDING =
  "CREATE_PACKING_REVISION_PENDING";
export const CREATE_PACKING_REVISION_REJECTED =
  "CREATE_PACKING_REVISION_REJECTED";

export const GET_ALL_PACKING = "GET_ALL_PACKING";
export const GET_ALL_PACKING_FULFILLED = "GET_ALL_PACKING_FULFILLED";
export const GET_ALL_PACKING_PENDING = "GET_ALL_PACKING_PENDING";
export const GET_ALL_PACKING_REJECTED = "GET_ALL_PACKING_REJECTED";

export const GET_PACKING_DETAILS = "GET_PACKING_DETAILS";
export const GET_PACKING_DETAILS_FULFILLED = "GET_PACKING_DETAILS_FULFILLED";
export const GET_PACKING_DETAILS_PENDING = "GET_PACKING_DETAILS_PENDING";
export const GET_PACKING_DETAILS_REJECTED = "GET_PACKING_DETAILS_REJECTED";

// UPDATE ORDER SUBJECT

export const UPDATE_JOB_ORDER_SUBJECT = "UPDATE_JOB_ORDER_SUBJECT";
