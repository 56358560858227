import React, { lazy } from "react";
import { ROLE } from "../../constants";
import { connect } from "react-redux";
const StorePurchase = lazy(() => import("./StorePurchase"));
const Sales = lazy(() => import("./Sales"));
const Admin = lazy(() => import("./Admin"));
const Purchase = lazy(() => import("./Purchase"));
const JobOrder = lazy(() => import("./JobOrder"));
const Store = lazy(() => import("./Store"));

const SideBar = (props) => {
  const role = JSON.parse(localStorage.getItem("data"))?.loginData?.user?.role;

  // function toggleSideBarDropDown(e, category) {
  //   e.preventDefault();

  //   if (e.currentTarget.className.includes("active")) {
  //     e.currentTarget.className = e.currentTarget.className.replace(
  //       " active",
  //       ""
  //     );
  //     document.getElementById(category)?.click();
  //   } else {
  //     let lists = e.currentTarget.parentNode.children;
  //     // console.log(list);
  //     for (let i = 0; i < lists.length; i++) {
  //       if (lists.item(i).hasAttribute("class")) {
  //         lists.item(i).className = lists
  //           .item(i)
  //           .className.replace(" active", "");
  //         if (lists.item(i).childNodes.length >= 2) {
  //           lists.item(i).childNodes[1].className = lists
  //             .item(i)
  //             .childNodes[1].className.replace(" menu-open", "");
  //         }
  //       }
  //     }
  //     document.getElementById(category)?.click();
  //     e.currentTarget.className += " active";
  //     e.currentTarget.childNodes[1].className += " menu-open";
  //   }
  // }

  const menuItems = () => {
    switch (role?.toLowerCase()) {
      case ROLE.Admin:
        return <Admin role={role} sidebar={props.sideBar} />;

      case ROLE.Sales:
        return <Sales role={role} sidebar={props.sideBar} />;

      case ROLE.purchase:
        return <Purchase role={role} sidebar={props.sideBar} />;

      case ROLE.Orders:
        return <JobOrder role={role} sidebar={props.sideBar} />;

      case ROLE.Store:
        return <Store role={role} sidebar={props.sideBar} />;

      case ROLE.StorePurchase:
        return <StorePurchase role={role} sideBar={props.sideBar} />;
      default:
        return null;
    }
  };

  return <div>{menuItems()}</div>;
};

function mapStateToProps(state) {
  return {
    sideBar: state.SidebarReducer,
  };
}

export default connect(mapStateToProps)(SideBar);
