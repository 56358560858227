import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import moment from "moment";

import {
  NOTIFICATION,
  ERROR_MESSAGES,
  ROLE,
  SUCCESS_MESSAGES,
} from "../../../constants";
import { OpenNotification } from "../../../components/shared/OpenNotification";
import { getAllMRN } from "../../../redux/actions/MrnActions";
import {
  addStock,
  assignStock,
  getAllItem,
  getItem,
  getUpdatedItemsLedger,
  getItemLedger,
} from "../../../redux/actions/InventoryActions";
import { ItemLedgerdashboard } from "../../../components/store/ItemLedgerdashboard";
import { logOut, validateToken } from "../../../redux/actions/LoginActions";
import Loader from "../../../assets/images/powertech-loader-2.gif";
class ViewItemLedger extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: "",
      offset: 5,
      page: 1,
      created_at: "",
      export_requested: false,
      //   flag:false
    };
  }

  componentWillMount() {
    let sessionValue = JSON.parse(localStorage.getItem("data"));
    let params = {
      token: sessionValue?.loginData.user.token,
    };
    this.props
      .validateToken(params)
      .then((res) => {
        if (
          sessionValue.loginData.user.role.toLowerCase() !==
            ROLE.StorePurchase &&
          sessionValue.loginData.user.role.toLowerCase() !== ROLE.Store &&
          sessionValue.loginData.user.role.toLowerCase() !== ROLE.Admin &&
          res.value.success
        ) {
          const notificationData = {
            type: NOTIFICATION.ERROR,
            title: "Unauthorised",
            description: ERROR_MESSAGES.UN_AUTHORISED_PAGE,
            duration: 4,
          };
          OpenNotification(notificationData);

          this.props.history.push(
            `${sessionValue.loginData?.user?.role?.toLowerCase()}`
          );
        } else {
          this.setState(
            {
              token: sessionValue.loginData.user.token,
            },
            () => {
              this.props.getAllItem({ token: this.state.token });
            }
          );
        }
      })
      .catch((err) => this.props.history.push("/"));
  }

  handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    if (!_.isEmpty(data)) {
      // localStorage.clear();
      this.props.history.push("/");
      this.props.logOut();
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };

  handlePagination = (page, limit) => {
    this.setState(
      {
        id: this.state.id,
        created_at: this.state.created_at,
        page: page,
        offset: limit,
        export_requested: false,
      },
      () => {
        const params = {
          id: this.state.id,
          created_at: this.state.created_at,
          page: page,
          offset: limit,
          export_requested: false,
          token: this.state.token,
        };
        this.props.getItemLedger(params);
      }
    );
  };

  handleSearch = (search, recperpage) => {
    this.setState({ page: 1, offset: recperpage }, () => {
      const params = {
        page: this.state.page,
        limit: this.state.limit,
      };
      this.props.getUpdatedItemsLedger(params);
    });
  };

  handleLedgerItem = (created_at, item_id, activepage, recperpage) => {
    // Parse the incoming date string in "dd/mm/yy" format
    //  const parsedDate = moment(created_at, "DD/MM/YYYY");

    //  // Format the date to "YY/MM/DD" and set it in state
    const formattedDate = moment(created_at, "DD/MM/YYYY").format("YYYY/MM/DD");

    this.setState(
      {
        id: item_id,
        page: activepage,
        offset: recperpage,
        created_at: formattedDate,
        export_requested: false,
      },
      () => {
        const params = {
          id: item_id,
          page: activepage,
          offset: recperpage,
          created_at: formattedDate,
          export_requested: false,
          token: this.state.token,
        };
        this.props.getItemLedger(params);
      }
    );
  };

  render() {
    return (
      <div>
        {this.props.itemData ? (
          <ItemLedgerdashboard
            userData={this.props.userData}
            itemData={this.props.itemData}
            OpenNotification={OpenNotification}
            handleSearch={this.handleSearch}
            handleLedgerItem={this.handleLedgerItem}
            handlePagination={this.handlePagination}
            itemDetails={this.props.itemDetails}
            state={this.state}
          />
        ) : (
          <img
            src={Loader}
            alt="logo"
            className="mx-auto d-block loaderImage"
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    MrnData: state.MrnReducer,
    itemData: state.InventoryReducer,
    userData: state.LoginReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut()),
    getItem: (params) => dispatch(getItem(params)),
    getAllItem: (params) => dispatch(getAllItem(params)),
    getItemLedger: (params) => dispatch(getItemLedger(params)),
    addStock: (params) => dispatch(addStock(params)),
    assignStock: (params) => dispatch(assignStock(params)),
    validateToken: (params) => dispatch(validateToken(params)),
    getUpdatedItemsLedger: (params) => dispatch(getUpdatedItemsLedger(params)),
    getAllMRN: (params) => dispatch(getAllMRN(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewItemLedger);
