import * as types from "../../constants";
// import axios from "axios";
// import { serverAddress } from "../../constants";

let sessionValue = JSON.parse(localStorage.getItem("data"));
// let b = false;
// const checktoken = () => {
//   let sessionValue = JSON.parse(localStorage.getItem("data"));
//   let url = serverAddress + "/welcome";
//   let params = {
//     token: sessionValue?.loginData?.user?.token,
//   };
//   return axios
//     .post(url, params, {
//       headers: {
//         Authorization: `Bearer ${params.token}`,
//       },
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         return true;
//       } else {
//         return false;
//       }
//     })
//     .catch((err) => {
//       return false;
//     });
// };

// const authenticated = checktoken().then((res) => {
//   b = res;
//   return res;
// });
// console.log(authenticated,b);

const initialFetchDataState = {
  isLogin: false,
  loginData: sessionValue ? sessionValue.loginData : [],
  receivedResponse: sessionValue ? sessionValue.receivedResponse : false,
  message_error: sessionValue ? sessionValue.message_error : "",
  tokenValid: false,
};

export default function loginReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.LOGIN_DATA_FULFILLED:
      state = {
        ...state,
        isLogin: true,
        loginData: action.payload,
        receivedResponse: 200,
      };
      break;

    case types.LOGIN_DATA_PENDING:
      state = {
        ...state,
        isFetched: false,
        receivedResponse: false,
      };
      break;

    case types.LOGIN_DATA_REJECTED:
      state = {
        ...state,
        isFetched: false,
        receivedResponse: false,
        message_error: "Username or password invalid.",
      };
      break;

    case types.VALIDATE_TOKEN_FULFILLED:
      state = {
        ...state,
        tokenValid: true,
        isLogin: true,
        receivedResponse: 200,
      };
      break;

    case types.VALIDATE_TOKEN_PENDING:
      state = {
        ...state,
        tokenValid: false,
        receivedResponse: false,
      };
      break;

    case types.VALIDATE_TOKEN_REJECTED:
      state = {
        ...state,
        tokenValid: false,
        receivedResponse: false,
        isLogin: false,
      };
      break;
    case types.LOGOUT:
      state = {
        isLogin: false,
        loginData: [],
        receivedResponse: false,
        message_error: "",
      };
      break;
    default:
      state = {
        ...state,
      };
  }

  return state;
}
