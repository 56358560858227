import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  ExportData: [],
  ExportList: [],
  bankList: [],
  addResponse: [],
  isExportListFetched: false,
  isbankListFetched: false,
  isExportDetailsFetched: false,
  isExportCreated: false,
  isFinalExportCreated: "",
  isExportFileAdded: false,
  isExportRevisionCreated: false,
  receivedResponse: false,
  message_error: "",
};

export default function ExportReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_ALL_EXPORTS_FULFILLED:
      state = {
        ...state,
        loading: false,
        ExportList: action.payload,
        receivedResponse: 200,
        isExportListFetched: true,
      };
      break;
    case types.GET_ALL_EXPORTS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isExportListFetched: false,
      };
      break;
    case types.GET_ALL_EXPORTS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_UPDATED_EXPORTS_FULFILLED:
      state = {
        ...state,
        loading: false,
        ExportList: action.payload,
        receivedResponse: 200,
        // isExportListFetched: true,
      };
      break;
    case types.GET_UPDATED_EXPORTS_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isExportListFetched: false,
      };
      break;
    case types.GET_UPDATED_EXPORTS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_BANK_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        bankList: action.payload,
        receivedResponse: 200,
        isbankListFetched: true,
      };
      break;
    case types.GET_BANK_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isbankListFetched: false,
      };
      break;
    case types.GET_BANK_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_EXPORT_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        ExportData: action.payload,
        isExportDetailsFetched: true,
      };
      break;
    case types.GET_EXPORT_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isExportDetailsFetched: false,
      };
      break;
    case types.GET_EXPORT_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.CREATE_EXPORT_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isExportCreated: true,
      };
      break;
    case types.CREATE_EXPORT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isExportCreated: false,
      };
      break;
    case types.CREATE_EXPORT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_EXPORT_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isExportRevisionCreated: true,
      };
      break;
    case types.CREATE_EXPORT_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isExportRevisionCreated: false,
      };
      break;
    case types.CREATE_EXPORT_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_FINAL_EXPORT_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isFinalExportCreated: true,
      };
      break;
    case types.CREATE_FINAL_EXPORT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.CREATE_FINAL_EXPORT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        isFinalExportCreated: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.RESET_EXPORT:
      state = {
        ...state,
        isExportCreated: false,
        isExportRevisionCreated: false,
      };
      break;
    case types.RESET_EXPORT_DATA:
      state = {
        ...state,
        isExportDetailsFetched: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
