import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { FileManager } from "../../components/jobOrder/FileManager";
import {
  ROLE,
  NOTIFICATION,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "../../constants";
// import fs from "fs";
import Loader from "../../assets/images/powertech-loader-2.gif";
import { logOut, validateToken } from "../../redux/actions/LoginActions";
import {
  getOrderById,
  addFilesToOrder,
  deleteJobFile,
  downloadPdf,
} from "../../redux/actions/OrderActions";
import { OpenNotification } from "../../components/shared/OpenNotification";
class Admin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentWillMount() {
    let sessionValue = JSON.parse(localStorage.getItem("data"));
    let params = {
      token: sessionValue?.loginData.user.token,
    };
    this.props
      .validateToken(params)
      .then((res) => {
        if (
          sessionValue.loginData.user.role.toLowerCase() !== ROLE.Admin &&
          res.value.success
        ) {
          this.props.history.push(
            `${sessionValue.loginData?.user?.role?.toLowerCase()}`
          );
        } else {
          this.setState(
            {
              token: sessionValue.loginData.user.token,
              user_id: sessionValue.loginData.user.id,
              role: sessionValue.loginData.role,
            },
            () => {
              let params = {
                id: this.props.match.params.uuid,
              };
              this.props.getOrderById(params);
            }
          );
        }
      })
      .catch((err) => this.props.history.push("/"));
  }

  handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    // console.log(data)
    if (!_.isEmpty(data)) {
      // localStorage.clear();
      this.props.history.push("/");
      this.props.logOut();
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };

  handleFileUpload = (props) => {
    var data = new FormData();
    Object.keys(props).forEach((key) => {
      data.append("files", props[key]);
    });
    data.append("id", this.props.match.params.uuid);
    this.props
      .addFilesToOrder(data)
      .then((res) => {
        if (res.value.added) {
          const notificationData = {
            type: NOTIFICATION.SUCCESS,
            title: "Success",
            description: SUCCESS_MESSAGES.ADDED_FILES_JOB_ORDER_SUCCESSFULLY,
            duration: 4,
          };
          OpenNotification(notificationData);
          this.props.getOrderById({ id: this.props.match.params.uuid });
        }
      })
      .catch((err) => {
        const notificationData = {
          type: NOTIFICATION.ERROR,
          title: "Error",
          description: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
          duration: 4,
        };
        OpenNotification(notificationData);
      });
  };

  deleteFile = (params) => {
    if (params) {
      const obj = {
        id: params.id,
        link: params.link,
      };
      this.props
        .deleteJobFile(obj)
        .then((res) => {
          if (!res.value.err) {
            const notificationDataDelete = {
              type: NOTIFICATION.SUCCESS,
              title: "Deleted",
              description: SUCCESS_MESSAGES.FILE_DELETED_SUCCESSFULLY,
              duration: 4,
            };
            OpenNotification(notificationDataDelete);
          }
        })
        .catch((err) => {
          const notificationDataDelete = {
            type: NOTIFICATION.ERROR,
            title: "Error",
            description: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
            duration: 4,
          };
          OpenNotification(notificationDataDelete);
        });
    }
  };

  download = (params) => {
    this.props.downloadPdf(params).then((res) => {
      if (res.value.success) {
        window.open(res.value.url);
      }
    });
  };

  render() {
    return (
      <>
        {this.props.fileData.isOrderFetched ? (
          <FileManager
            content={this.state}
            path={this.props.match}
            showModal={this.showModal}
            filesData={this.props.fileData}
            deleteFile={this.deleteFile}
            downloadFile={this.download}
            userData={this.props.userData}
            handleFileUpload={this.handleFileUpload}
          />
        ) : (
          <img
            src={Loader}
            alt="logo"
            className="mx-auto d-block loaderImage"
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.LoginReducer.loginData.user,
    fileData: state.OrdersReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut()),
    validateToken: (params) => dispatch(validateToken(params)),
    getOrderById: (params) => dispatch(getOrderById(params)),
    deleteJobFile: (params) => dispatch(deleteJobFile(params)),
    addFilesToOrder: (params) => dispatch(addFilesToOrder(params)),
    downloadPdf: (params) => dispatch(downloadPdf(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
