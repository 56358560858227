import React from "react";
import SidePic from "../assets/images/carlos-irineu-da-costa-eMc0lpn1P60-unsplash.png";
import Logo from "../assets/images/Logo.png";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

export const LoginForm = (props) => {
  const data = props.content;
  const history = useHistory();

  function handleChange(e) {
    props.handleChange(e);
  }

  function handleSubmit(e) {
    props.handleSubmit(e);
  }

  return (
    // <div className="container-fluid vh-100">
    //   <div className="row">
    //     <div className="col-4">
    //       <div className="my-auto ">
    //           <div >
    //               <img src={Logo} alt='logo'className='mx-auto d-block mt-4 mb-5' style={{width:'50%'}}/>
    //           </div>
    //         <h1 className="text-center pl-2 mt-3">Welcome Back</h1>
    //         <h6 className="text-center pl-2">Fill the given details to get logged In.</h6>
    //         <div className="card-body">
    //           <div className="  form-group">
    //             <label className="control-label">Email</label>
    //             <input
    //               type="text"
    //               placeholder="Enter Username"
    //               title="Please enter you username"
    //               onChange={(e) => handleChange(e)}
    //               name="username"
    //               id="username"
    //               className="form-control"
    //             />
    //           </div>
    //           <div className=" form-group">
    //             <label className="control-label">Password</label>
    //             <input
    //               type="password"
    //               title="Please enter your password"
    //               placeholder="******"
    //               onChange={(e) => handleChange(e)}
    //               name="password"
    //               id="password"
    //               className="form-control"
    //             />
    //             {/* <span className="help-block small">Your strong password</span> */}
    //           </div>
    //           <div className="form-check">
    //                 <input className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate"/>
    //                 <label className="form-check-label" for="flexCheckIndeterminate">
    //                 Keep me Logged In
    //                 </label>
    //             </div>
    //           <div className="form-group ">
    //             <button
    //               className="btn btn-primary col-md-12  mt-3 "
    //               onClick={(e) => handleSubmit(e)}
    //             >
    //               Sign In
    //             </button>

    //           </div>
    //           {data.message ? (
    //             <p
    //               className="alert-danger  col-md-12"
    //               style={{
    //                 padding: "10px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               {data.message}
    //             </p>
    //           ) : null}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="vh-100"
      style={{ background: "linear-gradient(to right, #1c66b1, #405671)" }}
    >
      <div className="formContainer">
        <form className="loginForm" onSubmit={(e) => handleSubmit(e)}>
          <img src={Logo} alt="logo" />
          <p>Welcome</p>
          <input
            type="text"
            placeholder="Username"
            name="username"
            id="username"
            onChange={(e) => handleChange(e)}
          />
          <br />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => handleChange(e)}
            name="password"
            id="password"
          />
          <br />
          <input type="submit" value="Sign in" />
          {data.message ? (
            <p
              className="alert-danger  col-md-12"
              style={{
                padding: "10px",
                textAlign: "center",
                color: "black",
                fontSize: "12px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              {data.message}
            </p>
          ) : (
            <br />
          )}
        </form>

        <div className="drops">
          <div className="drop drop-1"></div>
          <div className="drop drop-2"></div>
          <div className="drop drop-3"></div>
          <div className="drop drop-4"></div>
          <div className="drop drop-5"></div>
        </div>
      </div>
    </div>
  );
};
