import React, { useState, useEffect } from "react";
// import { banks } from "../../shared/data/bankDetails";
import codes from "../../shared/data/currencyList";
// import CreatableSelect from "react-select/creatable";
import EditableLabel from "../../shared/EditableLabel";
import TermsEditor from "../sales/quotationpages/invoiceaddition/TermsEditor";
export const ProformaForm = (props) => {
  // console.log(props);
  // const products =
  //   props.content.quotationData.QuotationData.data.sale_offer_revisions[0]
  //     .table_index
  // const currency =
  //   props.content.quotationData.QuotationData.data.sale_offer_revisions[0].amount_dig.slice(
  //     0,
  //     3
  //   )
  // const options = props.content.clientData.ClientsNameList.company_list;
  // const notifyLines = props.exportData.exportLines;
  //   let storedDate = localStorage.getItem("invoiceDate");
  //   let storedCurrency = localStorage.getItem("currency");

  const [edit, setEdit] = useState(false);
  const productLines = props.exportData.productLines;
  const banks = props.content.exportData.bankList.data;
  // const [company, setCompany] = useState("");
  // const [createdOption, setcreatedOption] = useState();
  // const [createdOption2, setcreatedOption2] = useState();
  const [name, setName] = useState("");
  // useEffect(() => {
  //   props.setCurrency(currency)
  // }, [currency])

  // useEffect(() => {
  //   props.productAddToData(products)
  // }, [products])

  useEffect(() => {
    let subTotal = 0;
    productLines?.forEach((productLine) => {
      const quantityNumber = parseFloat(productLine.quantity);
      const rateNumber = parseFloat(productLine.rate);
      const amount =
        quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;
      subTotal += amount;
    });
    props.setSubTotal(subTotal);
  }, [productLines]);

  const handleCreate = (name) => {
    setName(name);
    document.getElementById("client_pop")?.click();
  };

  const [notify, setNotify] = useState(false);
  const addNotifyParty = (e) => {
    setNotify(true);
    props.handleAdd(e);
  };

  const addProduct = (e) => {
    props.handleProductAdd(e);
  };

  return (
    <>
      <div className="row ml-5">
        <div className="col-md-3">
          <label className="labelWeight">
            Invoice Date
            <span
              style={{
                fontSize: "16px",
                color: "red",
                fontWeight: "bold",
              }}
            >
              *
            </span>
          </label>
        </div>
        <div className="col-md-6">
          <input
            type="date"
            defaultValue={props.date}
            className={`form-control ${props?.error?.date && "is-invalid"}`}
            onChange={(e) => {
              props.setDate(e.target.value);
              props.setError({ ...props.error, date: false });
            }}
          />
          {props?.error?.date && (
            <div class="invalid-feedback">
              {`please select the invoice date`}
            </div>
          )}
        </div>
      </div>
      <div class="hr-theme-slash-2">
        <div class="hr-line1"></div>
        <div class="hr-icon">
          <h6>Bank Details</h6>
        </div>
        <div class="hr-line2"></div>
      </div>

      <div className="form-group row  mt-3 ml-5">
        <label className="col-md-3 labelWeight">
          Bank
          <span
            style={{
              fontSize: "16px",
              color: "red",
              // position: "absolute",
              // left: "119px",
              // top: "56px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </label>
        <div className="col-md-6 ">
          <select
            className={`custom-select ${
              props.exportData?.bankDetails[0]?.bank_name?.error && "is-invalid"
            }`}
            name="bank_name"
            // defaultValue={props.exportData?.bankDetails[0]?.bank_name.value}
            onChange={(e) =>
              props.handleFieldChange(
                "bank_name",
                0,
                "bank_name",
                e.target.value
              )
            }
          >
            <option disabled selected>
              {props.exportData?.bankDetails[0]?.bank_name?.value
                ? props.exportData?.bankDetails[0]?.bank_name?.value
                : "Open this select menu"}
            </option>
            {banks.map((bank, index) => (
              <option value={index}>{bank.name}</option>
            ))}
          </select>
          {props.exportData?.bankDetails[0]?.bank_name?.error && (
            <div class="invalid-feedback">{`please select the bank`}</div>
          )}
        </div>
      </div>

      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End of bank */}
      {/* {console.log(props.exportData)} */}
      {props?.exportData?.shippingLines?.map((shippingLine, index) => (
        <>
          <div class="hr-theme-slash-2">
            <div class="hr-line1"></div>
            <div
              class="hr-icon"
              id="shipping_line_pop"
              data-trigger="focus"
              data-toggle="popover"
              data-container="body"
              data-placement="top"
              title="Attention!"
              data-content="The word 'shipping' is required in this field."
            >
              {/* <h6>{shippingLine.label}</h6> */}
              <EditableLabel
                labelValue={shippingLine.label}
                customEditIconStyle={{ color: "orange" }}
                customCancelIconStyle={{ color: "red" }}
                customApproveIconStyle={{ color: "green" }}
                placeholderStyle={{ color: "gray" }}
                editChangeEvent={(value) => {
                  props.handleHeaderChange("shippingLines", index, value);
                }}
              />
            </div>
            <div class="hr-line2"></div>
          </div>
          {/* <h6 contentEditable="true" suppressContentEditableWarning={true}>
            {shippingLine.label}
          </h6> */}
          <button
            type="button"
            className="btn btn-link btn-sm trashBtn"
            onClick={() => props.handleDeleteSection("shippingLines", index)}
          >
            <i className=" fa fa-trash text-primary" aria-hidden="true"></i>{" "}
            Section
          </button>
          <div className="ml-5">
            {Object.keys(shippingLine).map((key, i) => {
              if (key != "label") {
                return (
                  <div class="col" key={i}>
                    <div className="form-group row  mt-3">
                      <div className="col-sm-3">
                        <EditableLabel
                          labelValue={shippingLine[key].label}
                          customEditIconStyle={{ color: "orange" }}
                          customCancelIconStyle={{ color: "red" }}
                          customApproveIconStyle={{ color: "green" }}
                          placeholderStyle={{ color: "gray" }}
                          editChangeEvent={(value) => {
                            props.handleLabelChange(
                              "shippingLines",
                              index,
                              key,
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="shipping_by"
                          // className="form-control"
                          className={`form-control ${
                            shippingLine[key].error && "is-invalid"
                          }`}
                          defaultValue={shippingLine[key].value}
                          onChange={(event) =>
                            props.handleFieldChange(
                              "shippingLines",
                              index,
                              key,
                              event.target.value
                            )
                          }
                          placeholder={`Enter ${shippingLine[key].label}`}
                        />
                        {shippingLine[key].error && (
                          <div class="invalid-feedback">
                            {`${shippingLine[key].label} is null, if you want to remove this field, please click on the cross icon`}
                          </div>
                        )}
                      </div>
                      <div className="bg-transparent p-0 border-0 removeRow btn">
                        <i
                          className="fa fa-window-close text-primary"
                          onClick={() =>
                            props.handleFieldRemove("shippingLines", index, key)
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-primary btn-sm mt-4  ml-n5"
                onClick={() => props.handleNewField("shippingLines", index)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Field
              </button>
            </div>
          </div>
        </>
      ))}
      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={() => props.handleNewSection("shippingLines")}
        >
          <i class="fa fa-plus" aria-hidden="true"></i> New Section
        </button>
      </div>
      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End of shipping details */}

      {props?.exportData?.BankDetailLines?.map((bankLine, index) => (
        <>
          {/* <h6 contentEditable="true" suppressContentEditableWarning={true}>
            {bankLine.label}
          </h6> */}
          <div class="hr-theme-slash-2">
            <div class="hr-line1"></div>
            <div class="hr-icon">
              {/* <h6> {bankLine.label}</h6> */}
              <EditableLabel
                labelValue={bankLine.label}
                customEditIconStyle={{ color: "orange" }}
                editChangeEvent={(value) => {
                  props.handleHeaderChange("BankDetailLines", index, value);
                }}
              />
            </div>
            <div class="hr-line2"></div>
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm trashBtn"
            onClick={() => props.handleDeleteSection("BankDetailLines", index)}
          >
            <i className=" fa fa-trash text-primary" aria-hidden="true"></i>{" "}
            Section
          </button>
          <div className="ml-5">
            {Object.keys(bankLine).map((key, i) => {
              if (key != "label") {
                return (
                  <div class="col" key={i}>
                    <div className="form-group row  mt-3">
                      <div className="col-sm-3">
                        <EditableLabel
                          labelValue={bankLine[key].label}
                          customEditIconStyle={{ color: "orange" }}
                          customCancelIconStyle={{ color: "red" }}
                          customApproveIconStyle={{ color: "green" }}
                          placeholderStyle={{ color: "gray" }}
                          editChangeEvent={(value) => {
                            props.handleLabelChange(
                              "BankDetailLines",
                              index,
                              key,
                              value
                            );
                          }}
                        />
                      </div>

                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="shipping_by"
                          // class="form-control"
                          className={`form-control ${
                            bankLine[key].error && "is-invalid"
                          }`}
                          defaultValue={bankLine[key].value}
                          onChange={(event) =>
                            props.handleFieldChange(
                              "BankDetailLines",
                              index,
                              key,
                              event.target.value
                            )
                          }
                          placeholder={`Enter ${bankLine[key].label}`}
                        />
                        {bankLine[key].error && (
                          <div class="invalid-feedback">
                            {`${bankLine[key].label} is null, if you want to remove this field, please click on the cross icon`}
                          </div>
                        )}
                      </div>
                      <div className="bg-transparent p-0 border-0 removeRow btn">
                        <i
                          className="fa fa-window-close text-primary"
                          onClick={() =>
                            props.handleFieldRemove(
                              "BankDetailLines",
                              index,
                              key
                            )
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-primary btn-sm mt-4 ml-n5"
                onClick={() => props.handleNewField("BankDetailLines", index)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Field
              </button>
            </div>
          </div>
        </>
      ))}
      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={() => props.handleNewSection("BankDetailLines")}
        >
          <i class="fa fa-plus" aria-hidden="true"></i> New Section
        </button>
      </div>
      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End of bank details */}

      {props?.exportData?.billToLines?.map((billToLine, index) => (
        <>
          {/* <h6 contentEditable="true" suppressContentEditableWarning={true}>
            {billToLine.label}
          </h6> */}
          <div class="hr-theme-slash-2">
            <div class="hr-line1"></div>
            <div
              class="hr-icon"
              id="billto_line_pop"
              data-trigger="focus"
              data-toggle="popover"
              data-container="body"
              data-placement="top"
              title="Attention!"
              data-content="The word 'bill' is required in this field."
            >
              {/* <h6> {billToLine.label}</h6> */}
              <EditableLabel
                labelValue={billToLine.label}
                customEditIconStyle={{ color: "orange" }}
                customCancelIconStyle={{ color: "red" }}
                customApproveIconStyle={{ color: "green" }}
                placeholderStyle={{ color: "gray" }}
                editChangeEvent={(value) => {
                  props.handleHeaderChange("billToLines", index, value);
                }}
              />
            </div>
            <div class="hr-line2"></div>
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm trashBtn"
            onClick={() => props.handleDeleteSection("billToLines", index)}
          >
            <i className=" fa fa-trash text-primary" aria-hidden="true"></i>{" "}
            Section
          </button>
          <div className="ml-5">
            {Object.keys(billToLine).map((key, i) => {
              if (key != "label") {
                return (
                  <div class="col" key={i}>
                    <div className="form-group row  mt-3">
                      <div className="col-sm-3">
                        <EditableLabel
                          labelValue={billToLine[key].label}
                          customEditIconStyle={{ color: "orange" }}
                          customCancelIconStyle={{ color: "red" }}
                          customApproveIconStyle={{ color: "green" }}
                          placeholderStyle={{ color: "gray" }}
                          editChangeEvent={(value) => {
                            props.handleLabelChange(
                              "billToLines",
                              index,
                              key,
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="shipping_by"
                          className={`form-control ${
                            billToLine[key].error && "is-invalid"
                          }`}
                          defaultValue={billToLine[key].value}
                          onChange={(event) =>
                            props.handleFieldChange(
                              "billToLines",
                              index,
                              key,
                              event.target.value
                            )
                          }
                          placeholder={`Enter ${billToLine[key].label}`}
                        />
                        {billToLine[key].error && (
                          <div class="invalid-feedback">
                            {`${billToLine[key].label} is null, if you want to remove this field, please click on the cross icon`}
                          </div>
                        )}
                      </div>
                      <div className="bg-transparent p-0 border-0 removeRow btn">
                        <i
                          className="fa fa-window-close text-primary"
                          onClick={() =>
                            props.handleFieldRemove("billToLines", index, key)
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-primary btn-sm mt-4  ml-n5"
                onClick={() => props.handleNewField("billToLines", index)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Field
              </button>
            </div>
          </div>
        </>
      ))}
      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={() => props.handleNewSection("billToLines")}
        >
          <i class="fa fa-plus" aria-hidden="true"></i> New Section
        </button>
      </div>
      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End to billTo section */}

      {props?.exportData?.shipToLines?.map((shipToLine, index) => (
        <>
          {/* <h6 contentEditable="true" suppressContentEditableWarning={true}>
            {shipToLine.label}
          </h6> */}
          <div class="hr-theme-slash-2">
            <div class="hr-line1"></div>
            <div
              class="hr-icon"
              id="shipto_line_pop"
              data-trigger="focus"
              data-toggle="popover"
              data-container="body"
              data-placement="top"
              title="Attention!"
              data-content="The word 'ship' is required in this field."
            >
              {/* <h6> {shipToLine.label}</h6> */}
              <EditableLabel
                labelValue={shipToLine.label}
                customEditIconStyle={{ color: "orange" }}
                customCancelIconStyle={{ color: "red" }}
                customApproveIconStyle={{ color: "green" }}
                placeholderStyle={{ color: "gray" }}
                editChangeEvent={(value) => {
                  props.handleHeaderChange("shipToLines", index, value);
                }}
              />
            </div>
            <div class="hr-line2"></div>
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm trashBtn"
            onClick={() => props.handleDeleteSection("shipToLines", index)}
          >
            <i className=" fa fa-trash text-primary" aria-hidden="true"></i>{" "}
            Section
          </button>
          <div className="ml-5">
            {Object.keys(shipToLine).map((key, i) => {
              if (key != "label") {
                return (
                  <div class="col" key={i}>
                    <div className="form-group row  mt-3">
                      <div className="col-sm-3">
                        <EditableLabel
                          labelValue={shipToLine[key].label}
                          customEditIconStyle={{ color: "orange" }}
                          customCancelIconStyle={{ color: "red" }}
                          customApproveIconStyle={{ color: "green" }}
                          placeholderStyle={{ color: "gray" }}
                          editChangeEvent={(value) => {
                            props.handleLabelChange(
                              "shipToLines",
                              index,
                              key,
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="shipping_by"
                          className={`form-control ${
                            shipToLine[key].error && "is-invalid"
                          }`}
                          defaultValue={shipToLine[key].value}
                          onChange={(event) =>
                            props.handleFieldChange(
                              "shipToLines",
                              index,
                              key,
                              event.target.value
                            )
                          }
                          placeholder={`Enter ${shipToLine[key].label}`}
                        />
                        {shipToLine[key].error && (
                          <div class="invalid-feedback">
                            {`${shipToLine[key].label} is null, if you want to remove this field, please click on the cross icon`}
                          </div>
                        )}
                      </div>
                      <div className="bg-transparent p-0 border-0 removeRow btn">
                        <i
                          className="fa fa-window-close text-primary"
                          onClick={() =>
                            props.handleFieldRemove("shipToLines", index, key)
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-primary btn-sm mt-4  ml-n5"
                onClick={() => props.handleNewField("shipToLines", index)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Field
              </button>
            </div>
          </div>
        </>
      ))}
      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={() => props.handleNewSection("shipToLines")}
        >
          <i class="fa fa-plus" aria-hidden="true"></i> New Section
        </button>
      </div>
      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End to shipTo section */}

      {props?.exportData?.nofityPartyLines?.map((notifyLine, index) => (
        <>
          {/* <h6 contentEditable="true" suppressContentEditableWarning={true}>
            {notifyLine.label}
          </h6> */}
          <div class="hr-theme-slash-2">
            <div class="hr-line1"></div>
            <div
              class="hr-icon"
              id="notify_line_pop"
              data-trigger="focus"
              data-toggle="popover"
              data-container="body"
              data-placement="top"
              title="Attention!"
              data-content="The word 'notify' is required in this field."
            >
              {/* <h6> {notifyLine.label}</h6> */}
              <EditableLabel
                labelValue={notifyLine.label}
                customEditIconStyle={{ color: "orange" }}
                customCancelIconStyle={{ color: "red" }}
                customApproveIconStyle={{ color: "green" }}
                placeholderStyle={{ color: "gray" }}
                editChangeEvent={(value) => {
                  props.handleHeaderChange("nofityPartyLines", index, value);
                }}
              />
            </div>
            <div class="hr-line2"></div>
          </div>
          <button
            type="button"
            className="btn btn-link btn-sm trashBtn"
            onClick={() => props.handleDeleteSection("nofityPartyLines", index)}
          >
            <i className=" fa fa-trash text-primary" aria-hidden="true"></i>{" "}
            Section
          </button>
          <div className="ml-5">
            {/* {console.log("check-------------------->")} */}
            {Object.keys(notifyLine).map((key, i) => {
              if (key != "label") {
                return (
                  <div class="col" key={i}>
                    <div className="form-group row  mt-3">
                      <div className="col-sm-3">
                        <EditableLabel
                          labelValue={notifyLine[key].label}
                          customEditIconStyle={{ color: "orange" }}
                          customCancelIconStyle={{ color: "red" }}
                          customApproveIconStyle={{ color: "green" }}
                          placeholderStyle={{ color: "gray" }}
                          editChangeEvent={(value) => {
                            props.handleLabelChange(
                              "nofityPartyLines",
                              index,
                              key,
                              value
                            );
                          }}
                        />
                        {/* <p>{notifyLine[key].label}</p> */}
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          name="shipping_by"
                          className={`form-control ${
                            notifyLine[key].error && "is-invalid"
                          }`}
                          defaultValue={notifyLine[key].value}
                          onChange={(event) =>
                            props.handleFieldChange(
                              "nofityPartyLines",
                              index,
                              key,
                              event.target.value
                            )
                          }
                          placeholder={`Enter ${notifyLine[key].label}`}
                        />
                        {notifyLine[key].error && (
                          <div class="invalid-feedback">
                            {`${notifyLine[key].label} is null, if you want to remove this field, please click on the cross icon`}
                          </div>
                        )}
                      </div>
                      <div className="bg-transparent p-0 border-0 removeRow btn">
                        <i
                          className="fa fa-window-close text-primary"
                          onClick={() =>
                            props.handleFieldRemove(
                              "nofityPartyLines",
                              index,
                              key
                            )
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div class="col-md-12 text-center">
              <button
                type="button"
                class="btn btn-primary btn-sm mt-4  ml-n5"
                onClick={() => props.handleNewField("nofityPartyLines", index)}
              >
                <i class="fa fa-plus" aria-hidden="true"></i> New Field
              </button>
            </div>
          </div>
        </>
      ))}

      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={() => props.handleNewSection("nofityPartyLines")}
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Notify Section
        </button>
      </div>
      {/* <hr style={{ borderTop: "1px solid rgb(0 0 0 / 31%)" }} /> */}
      {/*End to notifyParty section */}
      {/* 
      <h6>
        Products
        <span
          style={{
           fontSize: "16px",
            color: "red",

            fontWeight: "bold",
          }}
        >
          *
        </span>
      </h6> */}
      <div class="hr-theme-slash-2">
        <div class="hr-line1"></div>
        <div class="hr-icon">
          <h6>
            Products
            <span
              style={{
                fontSize: "16px",
                color: "red",
                fontWeight: "bold",
              }}
            >
              *
            </span>
          </h6>
        </div>
        <div class="hr-line2"></div>
      </div>
      <div className="mt-1 d-flex .bg-secondary border-0 mb-3 mt-3 p-2">
        <div className=" col-md-3 padding0 ">
          <strong>Item</strong>
        </div>
        <div className=" col-md-4 padding0 ">
          <strong>Item Description</strong>
        </div>
        <div className="col-md-1 padding0">
          <strong>HSN</strong>
        </div>
        <div className=" col-md-1 padding0 ">
          <strong>Units</strong>
        </div>
        <div className=" col-md-1 padding0 ">
          <strong>Quan.</strong>
        </div>
        <div className="col-md-1 padding0">
          <strong>{`Rate ${
            props?.currency ? `(${props?.currency})` : ""
          }`}</strong>
        </div>
        <div className="col-md-2 padding0">
          <strong>{`Total ${
            props?.currency ? `(${props?.currency})` : ""
          }`}</strong>
        </div>
      </div>
      {productLines
        ? productLines.map((productLine, i) => {
            return (
              <div key={i} className=" d-flex mb-3 p-2 borderdown">
                <div className="col-md-3 pr-3 padding0">
                  <textarea
                    type="text"
                    name="address"
                    class={`form-control bg-input ${
                      productLine?.item_error && "is-invalid"
                    }`}
                    rows="3"
                    value={productLine.item && productLine.item}
                    onChange={(e) =>
                      props.handleProductChange(i, "item", e.target.value)
                    }
                  />
                  {productLine?.item_error && (
                    <div class="invalid-feedback">
                      {`item name is required`}
                    </div>
                  )}
                </div>
                <div className="col-md-4 pr-3 padding0">
                  <textarea
                    type="text"
                    name="address"
                    class={`form-control bg-input ${
                      productLine?.description_error && "is-invalid"
                    }`}
                    rows="3"
                    value={productLine.description}
                    onChange={(e) =>
                      props.handleProductChange(
                        i,
                        "description",
                        e.target.value
                      )
                    }
                  />
                  {productLine?.description_error && (
                    <div class="invalid-feedback">
                      {`item description is required`}
                    </div>
                  )}
                </div>
                <div className=" col-md-1 padding0">
                  <input
                    className="form-control border-0 col-md-10 "
                    value={productLine.hsn_no}
                    onChange={(value) =>
                      props.handleProductChange(i, "hsn_no", value.target.value)
                    }
                  />
                </div>
                <div className=" col-md-1 padding0 pl-2 pr-2 ">
                  <select
                    name="units"
                    className="form-control square-border width-100"
                    value={productLine.units}
                    onChange={(value) =>
                      props.handleProductChange(i, "units", value.target.value)
                    }
                  >
                    <option value="SET">SET</option>
                    <option value="NOs">NOs</option>
                    <option value="Kgs.">Kgs.</option>
                    <option value="Sq.Ft.">Sq.Ft.</option>
                    <option value="Ft.">Ft.</option>
                    <option value="MTR">MTR</option>
                    <option value="LOT">LOT</option>
                  </select>
                </div>
                <div className=" col-md-1 padding0 ">
                  <input
                    className="form-control border-0 col-md-10 "
                    value={productLine.quantity}
                    min="0"
                    type="number"
                    onChange={(value) =>
                      props.handleProductChange(
                        i,
                        "quantity",
                        value.target.value
                      )
                    }
                  />
                </div>
                <div className=" col-md-1 padding0">
                  <input
                    className="form-control border-0 col-md-10 "
                    type="number"
                    min="0"
                    value={productLine.rate}
                    onChange={(value) =>
                      props.handleProductChange(i, "rate", value.target.value)
                    }
                  />
                </div>

                <div className="col-md-2 padding0">
                  <span className="col-md-12 lead">
                    {props.calculateAmount(
                      productLine.quantity,
                      productLine.rate
                    )}
                  </span>
                  <div
                    className="bg-transparent p-0 border-0 removeRow btn"
                    onClick={() => props.handleRemove(i)}
                  >
                    <i
                      className="fa fa-window-close text-primary"
                      onClick={() => props.handleRemove(i)}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      <div class="col-md-12 text-center">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-4"
          onClick={(e) => addProduct(e)}
        >
          Add New Product <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
      <div className="container row">
        <div className="col-md-6"></div>
        <div className="mt-2 bg-secondary col-md-6 align-self-end rounded">
          <div className="d-flex mb-2">
            <div className=" p-1 col-md-10">
              <span>Sub Total</span>
            </div>
            <div className=" p-1 float-right">
              <span className="right bold dark float-right">
                {props.subTotal?.toFixed(2)}
              </span>
            </div>
          </div>
          <div className="d-flex  mb-2">
            <div className=" p-1 col-md-6">Total</div>
            {/* {console.log("curency test", props?.error?.cur)} */}
            <select
              className={`custom-select col-md-4 ${
                props?.error?.cur ? "is-invalid" : ""
              }`}
              name="currency"
              onChange={(value) => {
                props.setCurrency(value.target.value);
                props.setError({ ...props.error, cur: false });
              }}
            >
              <option selected>
                {props.currency ? props.currency : "Currency"}
              </option>
              <option value="INR">Indian rupee</option>
              <option value="USD">US dollar</option>
              <optgroup label="Other countries">
                {codes.map((country, index) => (
                  <option key={index} value={country.AlphabeticCode}>
                    {country.Currency} ({country.AlphabeticCode})
                  </option>
                ))}
              </optgroup>
            </select>
            {/* {currency} */}
            <div className="p-1 float-right ">
              <span className="right bold dark float-right">
                {props.subTotal?.toFixed(2)}
              </span>
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className=" p-1 col-md-6">
              <span>Amount Paid</span>
            </div>
            <div>
              <input
                type="number"
                className="form-control"
                disabled={props.subTotal === 0 ? true : false}
                value={props.amountPaid}
                onChange={(value) => props.setAmountPaid(value.target.value)}
              />
            </div>
          </div>

          <div className="d-flex mb-2">
            <div className=" p-1 col-md-10">
              <span>Balance</span>
            </div>
            <div className=" p-1 float-right">
              <span className="right bold dark float-right">
                {props.subTotal && props.amountPaid
                  ? (props.subTotal - props.amountPaid).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <TermsEditor
          value={props.value}
          setValue={props.setValue}
          edit={edit}
          setEdit={setEdit}
        />
      </div>
    </>
  );
};
