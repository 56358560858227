import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  PurchaseData: [],
  PurchaseCode: [],
  PurchaseAddResponse: [],
  PurchasesList: [],
  PurchasesNameList: [],
  PurchaseOrderStatusCountData: {},
  message_error: "",
  JobOrderPurposeCountData: {},
  isJobOrderPurposeCountFetched: false,
  receivedResponse: false,
  isPurchaseAdded: false,
  isPurchaseRevisionDelete: false,
  isPurchaseRevDelete: false,
  isPurchaseDetailsUpdated: false,
  isPurchaseDetailsFetched: false,
  isPurchaseContactUpdated: false,
  isPurchaseListFetched: false,
  isPurchaseStatusUpdated: false,
  isPurchaseOrderStatusCountFetched: false,
};

export default function PurchaseReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_PURCHASE_ORDER_STATUS_COUNT_FULFILLED:
      state = {
        ...state,
        loading: false,
        PurchaseOrderStatusCountData: action.payload,
        receivedResponse: 200,
        isPurchaseOrderStatusCountFetched: true,
      };
      break;
    case types.GET_PURCHASE_ORDER_STATUS_COUNT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseOrderStatusCountFetched: false,
      };
      break;
    case types.GET_PURCHASE_ORDER_STATUS_COUNT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
      break;

    case types.GET_PURCHASE_ORDER_STATUS_COUNT_FULFILLED:
      state = {
        ...state,
        loading: false,
        PurchaseOrderStatusCountData: action.payload,
        receivedResponse: 200,
        isPurchaseOrderStatusCountFetched: true,
      };
      break;
    case types.GET_PURCHASE_ORDER_STATUS_COUNT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseOrderStatusCountFetched: false,
      };
      break;
    case types.GET_PURCHASE_ORDER_STATUS_COUNT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occurred, please try again",
      };
      break;
    case types.ADD_NEW_PURCHASE_ORDER_FULFILLED:
      state = {
        ...state,
        loading: false,
        PurchaseAddResponse: action.payload,
        receivedResponse: 200,
        isPurchaseAdded: true,
      };
      break;
    case types.ADD_NEW_PURCHASE_ORDER_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseAdded: false,
      };
      break;
    case types.ADD_NEW_PURCHASE_ORDER_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_PURCHASE_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        PurchasesList: action.payload,
        receivedResponse: 200,
        isPurchaseListFetched: true,
      };
      break;
    case types.GET_PURCHASE_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseListFetched: false,
      };
      break;
    case types.GET_PURCHASE_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_UPDATED_PURCHASE_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        PurchasesList: action.payload,
        receivedResponse: 200,
        // isPurchaseListFetched: true,
      };
      break;
    case types.GET_UPDATED_PURCHASE_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isPurchaseListFetched: false,
      };
      break;
    case types.GET_UPDATED_PURCHASE_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.PURCHASE_REVISION_DELELTE_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isPurchaseRevisionDelete: true,
      };
      break;
    case types.PURCHASE_REVISION_DELELTE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseRevisionDelete: false,
      };
      break;
    case types.PURCHASE_SINGLE_REVISION_DELELTE_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.PURCHASE_SINGLE_REVISION_DELELTE_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isPurchaseRevDelete: true,
      };
      break;
    case types.PURCHASE_SINGLE_REVISION_DELELTE_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseRevDelete: false,
      };
      break;
    case types.PURCHASE_REVISION_DELELTE_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_PURCHASE_DETAILS_FULFILLED:
      state = {
        ...state,
        PurchaseData: action.payload,
        loading: false,
        receivedResponse: 200,
        isPurchaseDetailsFetched: true,
      };
      break;

    case types.GET_PURCHASE_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseDetailsFetched: false,
      };
      break;
    case types.GET_PURCHASE_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: "Vendor Not Found",
        receivedResponse: false,
      };
      break;
    case types.UPDATE_PURCHASE_STATUS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        isPurchaseStatusUpdated: true,
      };
      break;
    case types.UPDATE_PURCHASE_STATUS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseStatusUpdated: false,
      };
      break;
    case types.UPDATE_PURCHASE_STATUS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_PURCHASE_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        PurchaseAddResponse: action.payload,
        isPurchaseDetailsUpdated: true,
      };
      break;
    case types.CREATE_PURCHASE_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isPurchaseDetailsUpdated: false,
      };
      break;
    case types.CREATE_PURCHASE_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_PRODUCTION_DOWNLOAD_LINK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;
    case types.GET_PRODUCTION_DOWNLOAD_LINK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.GET_PRODUCTION_DOWNLOAD_LINK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;

    case types.RESET_PURCHASE_REVISION:
      state = {
        ...state,
        loading: true,
        isPurchaseDetailsUpdated: false,
        isPurchaseAdded: false,
      };
      break;
    case types.RESET_PURCHASE:
      state = {
        ...state,
        loading: true,
        isPurchaseDetailsUpdated: false,
        isPurchaseDetailsFetched: false,
        isPurchaseAdded: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
