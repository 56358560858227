import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  ProformaData: [],
  ProformaList: [],
  bankList: [],
  addResponse: [],
  isProformaListFetched: false,
  isbankListFetched: false,
  isProformaDetailsFetched: false,
  isProformaCreated: false,
  isFinalExportCreated: "",
  isExportFileAdded: false,
  isProformaRevisionCreated: false,
  receivedResponse: false,
  message_error: "",
};

export default function ExportReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.GET_ALL_PROFORMA_FULFILLED:
      state = {
        ...state,
        loading: false,
        ProformaList: action.payload,
        receivedResponse: 200,
        isProformaListFetched: true,
      };
      break;
    case types.GET_ALL_PROFORMA_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isProformaListFetched: false,
      };
      break;
    case types.GET_ALL_PROFORMA_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_UPDATED_PROFORMAS_FULFILLED:
      state = {
        ...state,
        loading: false,
        ProformaList: action.payload,
        receivedResponse: 200,
        // isProformaListFetched: true,
      };
      break;
    case types.GET_UPDATED_PROFORMAS_PENDING:
      state = {
        ...state,
        loading: true,
        // receivedResponse: false,
        // isProformaListFetched: false,
      };
      break;
    case types.GET_UPDATED_PROFORMAS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_BANK_LIST_FULFILLED:
      state = {
        ...state,
        loading: false,
        bankList: action.payload,
        receivedResponse: 200,
        isbankListFetched: true,
      };
      break;
    case types.GET_BANK_LIST_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isbankListFetched: false,
      };
      break;
    case types.GET_BANK_LIST_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.GET_PROFORMA_DETAILS_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
        ProformaData: action.payload,
        isProformaDetailsFetched: true,
      };
      break;
    case types.GET_PROFORMA_DETAILS_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isProformaDetailsFetched: false,
      };
      break;
    case types.GET_PROFORMA_DETAILS_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;

    case types.CREATE_PROFORMA_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isProformaCreated: true,
      };
      break;
    case types.CREATE_PROFORMA_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isProformaCreated: false,
      };
      break;
    case types.CREATE_PROFORMA_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_PROFORMA_REVISION_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isProformaRevisionCreated: true,
      };
      break;
    case types.CREATE_PROFORMA_REVISION_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
        isProformaRevisionCreated: false,
      };
      break;
    case types.CREATE_PROFORMA_REVISION_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        error: "An error occured please try again",
      };
      break;
    case types.CREATE_FINAL_EXPORT_FULFILLED:
      state = {
        ...state,
        loading: false,
        addResponse: action.payload,
        receivedResponse: 200,
        isFinalExportCreated: true,
      };
      break;
    case types.CREATE_FINAL_EXPORT_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.CREATE_FINAL_EXPORT_REJECTED:
      state = {
        ...state,
        loading: false,
        receivedResponse: false,
        isFinalExportCreated: false,
        error: "An error occured please try again",
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_FULFILLED:
      state = {
        ...state,
        loading: false,
        receivedResponse: 200,
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_PENDING:
      state = {
        ...state,
        loading: true,
        receivedResponse: false,
      };
      break;
    case types.GET_EXPORT_DOWNLOAD_LINK_REJECTED:
      state = {
        ...state,
        loading: false,
        message_error: types.ERROR_MESSAGES.UN_AUTHORISED,
      };
      break;
    case types.RESET_EXPORT:
      state = {
        ...state,
        isProformaCreated: false,
        isProformaRevisionCreated: false,
      };
      break;
    case types.RESET_EXPORT_DATA:
      state = {
        ...state,
        isProformaDetailsFetched: false,
      };
      break;
    default:
      state = {
        ...state,
      };
  }
  return state;
}
