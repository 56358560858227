import React from "react";
import { useHistory } from "react-router";
import { ItemLedgerDetails } from "../pages/store/inventory/ItemLedgerDetails";
export const ItemLedgerdashboard = (props) => {
  const history = useHistory();
  return (
    <div>
      {/*main content will go inside parent div*/}
      {/* <section className="content-header">
        <div className="row">
          <div className="col-md-1 pr-0">
            <button
              type="button"
              className="bck-btn btn btn-outline-primary mt-1"
              onClick={() => history.goBack()}
              style={{
                padding: "6px 10px",
                borderRadius: "10px",
              }}
            >
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-md-11 mr-2 ml-n4">
            <div
              className="header-icon mt-1"
              style={{
                "max-width": "2.5rem",
                "max-heigth": "2rem",
                fontSize: "2.5rem",
              }}
            >
              <i className="fa fa-list" />
            </div>
            <div className="header-title ml-5">
              <h1>Item's Ledger List</h1>
              <small>Store / Inventory</small>
            </div>
          </div>
        </div>
      </section> */}

      <ItemLedgerDetails content={props} />
    </div>
  );
};
