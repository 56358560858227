import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
// import { SalesSideBar } from "../../components/sales/SalesSideBar";
// import SalesHeader from "../../components/sales/SalesHeader";
import {
  NOTIFICATION,
  ERROR_MESSAGES,
  ROLE,
  // SUCCESS_MESSAGES,
} from "../../constants";
import { OpenNotification } from "../../components/shared/OpenNotification";
// import {
//   getAllProforma,
//   getUpdatedProformas,
// } from "../../redux/actions/ExportActions";
import {
  getAllProforma,
  getUpdatedProformas,
  resetExport,
  getAllBanks,
} from "../../redux/actions/ProformaActions";
import { ProformaDashboard } from "../../components/export/ProformaDashboard";
import { logOut } from "../../redux/actions/LoginActions";
import Loader from "../../assets/images/powertech-loader-2.gif";
class ViewExportsProforma extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      limit: 5,
      page: 1,
      search: "",
    };
  }

  componentWillMount() {
    let sessionValue = JSON.parse(localStorage.getItem("data"));
    // let url = window.location.pathname.split("/")[2];
    const { match } = this.props;
    const { id } = match.params;
    if (sessionValue === null) {
      this.props.history.push("/");
    } else if (sessionValue.loginData.user.role.toLowerCase() !== ROLE.Admin) {
      const notificationData = {
        type: NOTIFICATION.ERROR,
        title: "Unauthorised",
        description: ERROR_MESSAGES.UN_AUTHORISED_PAGE,
        duration: 4,
      };
      OpenNotification(notificationData);
      this.props.history.push(
        `/${sessionValue.loginData?.user?.role?.toLowerCase()}`
      );
    } else {
      this.setState(
        {
          token: sessionValue.loginData.user.token,
          //   final: url === "list" ? false : true,
        },
        () => {
          let params = {
            id: id,
            token: this.state.token,
            page: this.state.page,
            limit: this.state.limit,
          };
          // this.props.getAllQuotations(params);
          // this.props.getAllClientNames(params);
          this.props.getAllProforma(params);
        }
      );
    }
  }

  handlePagination = (page, limit) => {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({ page: page, limit: limit }, () => {
      const params = {
        page: this.state.page,
        limit: this.state.limit,
        token: this.state.token,
        id: id,
        // final: this.state.final,
      };
      this.props.getUpdatedProformas(params);
    });
  };

  handleSearch = (search) => {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({ page: 1, limit: 5, search: search }, () => {
      const params = {
        page: this.state.page,
        limit: this.state.limit,
        search: this.state.search,
        id: id,
        // final: this.state.final,
      };
      this.props.getUpdatedProformas(params);
    });
  };

  handleSignOut = (e) => {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("data"));
    if (!_.isEmpty(data)) {
      // localStorage.clear();
      this.props.history.push("/");
      this.props.logOut();
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  };
  render() {
    return (
      // <div style={{ backgroundColor: "#EEEEEE", height: "100vh" }}>
      //   <SalesHeader
      //     content={this.state}
      //     history={this.props.history}
      //     redirectToLeadPage={this.redirectToLeadPage}
      //     handleSignOut={this.handleSignOut}
      //   />
      //   <SalesSideBar content={this.state} />
      <>
        {this.props.proformaData.isProformaListFetched ? (
          <ProformaDashboard
            userData={this.props.userData}
            state={this.state}
            final={this.state.final}
            handlePagination={this.handlePagination}
            OpenNotification={OpenNotification}
            handleSearch={this.handleSearch}
            downloadInvoice={this.downloadInvoice}
            proformaData={this.props.proformaData}
          />
        ) : (
          <img
            src={Loader}
            alt="logo"
            className="mx-auto d-block loaderImage"
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.LoginReducer,
    exportData: state.ExportReducer,
    proformaData: state.ProformaReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => dispatch(logOut()),
    getAllProforma: (params) => dispatch(getAllProforma(params)),
    getUpdatedProformas: (params) => dispatch(getUpdatedProformas(params)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewExportsProforma);
