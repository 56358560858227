import * as types from "../../constants";

const initialFetchDataState = {
  loading: true,
  sidebarClosed: false,
};

export default function SidebarReducer(state = initialFetchDataState, action) {
  switch (action.type) {
    case types.SIDEBAR_TOGGLE:
      state = {
        ...state,
        sidebarClosed: !state.sidebarClosed,
      };
      break;
    case types.OPEN_SIDEBAR:
      state = {
        ...state,
        sidebarClosed: false,
      };
      break;
    case types.CLOSE_SIDEBAR:
      state = {
        ...state,
        sidebarClosed: true,
      };
      break;
    default:
      return state;
  }
  return state;
}
