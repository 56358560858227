import * as types from "../../constants";

export function closeSidebar() {
  return {
    type: types.CLOSE_SIDEBAR,
  };
}

export function openSidebar() {
  return {
    type: types.OPEN_SIDEBAR,
  };
}

export function toogleSidebar() {
  return {
    type: types.SIDEBAR_TOGGLE,
  };
}
