import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';
import reduxPromiseMiddleware from 'redux-promise-middleware'
import RootReducer from "./reducers/RootReducer";

export default createStore(
    RootReducer, {},
   // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
    composeWithDevTools(applyMiddleware( thunk, reduxPromiseMiddleware))
    // )
);