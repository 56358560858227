const React = require("react");
const { format } = require("date-fns");
const { ToWords } = require("to-words");

// Create styles
const styles = {
  page: {
    // flexDirection: "row",
    padding: 10,
    backgroundColor: "white",
  },
  image: {
    width: "100%",
    height: "10%",
    marginBottom: 10,
  },
  table: {
    display: "table",
    borderStyle: "solid",
    width: "100%",
    borderColor: "black",
    borderWidth: 1,
  },
  title: {
    fontSize: 7,
    textAlign: "center",
    // fontFamily: 'Times-Roman',
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    backgroundColor: "#dbe4f0",
    padding: 5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  consignee: {
    fontSize: 7,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    textAlign: "center",
    backgroundColor: "#dbe4f0",
    padding: 5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  description: {
    fontSize: 7,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    textAlign: "center",
    padding: 5,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
  },
  rows: {
    fontSize: 7,
    flexDirection: "row",
    textAlign: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    display: "flex",
  },
  twentyWid: {
    width: "20%",
    borderRight: "1px solid black",
    padding: 5,
  },
  thirtyWid: {
    width: "30%",
    borderRight: "1px solid black",
    padding: 5,
  },
  eightyWid: {
    width: "80%",
    padding: 5,
  },
  twentyFiveWid: {
    width: "25%",
    borderRight: "1px solid black",
    padding: 5,
  },
  twentyFiveWidNbr: {
    width: "25%",
    padding: 5,
  },
  contact: {
    marginTop: 12,
  },
  sign60: {
    width: "60%",
    borderRight: "1px solid black",
    padding: 5,
  },
  sign40: {
    width: "40%",
  },
};

// Create Document Component
const PreviewPdf = ({
  data,
  subTotal,
  createdData,
  currency,
  date,
  final,
  usage,
  amount_paid,
  already_paid,
}) => {
  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      ignoreDecimal: true,
      ignoreZeroCurrency: false,
    },
  });

  // console.log(data, "data");
  // console.log(subTotal, "subTotal");
  // console.log(createdData, "createdData");
  // console.log(currency, "currency");
  // console.log(date, "date");
  // console.log(final, "final");
  // console.log(usage, "usage");
  // console.log(amount_paid, "amount_paid");
  // console.log(downloadPDF,"downloadPDF");
  // console.log(transactionData,"transactionData");
  // console.log(products,"products");
  // console.log(calculateAmount,"calculateAmount");

  const getBankDetails = () => {
    let bankDetails = "";
    data.map((item) => {
      if (item?.label === "Bank Details") {
        bankDetails = item;
      }
    });
    return bankDetails;
  };

  return (
    <div>
      <div style={styles.table}>
        <div>
          {usage != "proforma" ? (
            <div style={styles.title}>
              {final == true ? "EXPORT INVOICE" : "EXPORT OFFER"}
            </div>
          ) : (
            <div style={styles.title}>PROFORMA INVOICE</div>
          )}
        </div>
        <div style={styles.rows}>
          <div style={{ width: "50%", borderRightWidth: 1, padding: 5 }}>
            EXPORTER
          </div>
          <div style={{ width: "25%", borderRightWidth: 1, padding: 5 }}>
            INVOICE NO & DATE
          </div>
          <div style={{ width: "11%", borderRightWidth: 1, padding: 5 }}>
            {createdData?.invoiceNo}
          </div>
          <div style={{ width: "14%", padding: 5 }}>
            {format(new Date(date), "dd-MM-yyyy")}
          </div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>COMPANY NAME</div>
          <div style={styles.thirtyWid}>POWERTECH INDIA</div>
          <div style={styles.twentyFiveWid}>GSTIN</div>
          <div style={styles.twentyFiveWidNbr}>09ABQPA6872M1ZY</div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>ADDRESS</div>
          <div style={styles.thirtyWid}>
            D-6,KAVI NAGAR INDUSTRIAL AREA,GHAZIABAD , UP INDIA
          </div>
          <div style={styles.twentyFiveWid}>IEC CODE</div>
          <div style={styles.twentyFiveWidNbr}>0512030839</div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>STATE CODE</div>
          <div style={styles.thirtyWid}>UTTAR PRADESH / 09</div>
          <div style={styles.twentyFiveWid}>BANK NAME AND ADDRESS</div>
          <div style={styles.twentyFiveWidNbr}>
            {getBankDetails()?.bank_name?.value}
          </div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>ORIGIN COUNTRY OF GOODS</div>
          <div style={styles.thirtyWid}>INDIA</div>
          <div style={styles.twentyFiveWid}>BANK ACCOUNT NO</div>
          <div style={styles.twentyFiveWidNbr}>
            {getBankDetails()?.bank_account_no?.value}
          </div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>
            <div style={styles.contact}>CONTACT DETAILS</div>
          </div>
          <div style={{ width: "30%", borderRight: "1px solid black" }}>
            <div style={{ borderBottomWidth: 1, padding: 5 }}>
              Mr. SUNIT RAI AGARWAL 0091-9871373955
            </div>
            <div style={{ padding: 5 }}>Mr. ROHAN AGARWAL 0091-9891529612</div>
          </div>
          <div style={{ width: "25%", borderRight: "1px solid black" }}>
            <div style={{ borderBottomWidth: 1, padding: 5 }}>SWIFT CODE</div>
            <div style={{ padding: 5 }}>BANK AD CODE</div>
          </div>
          <div style={{ width: "25%" }}>
            <div style={{ borderBottomWidth: 1, padding: 5 }}>
              {getBankDetails()?.swift_code?.value}
            </div>
            <div style={{ padding: 5 }}>
              {" "}
              {getBankDetails()?.ad_code?.value}
            </div>
          </div>
        </div>
        <div style={styles.rows}>
          <div style={styles.twentyWid}>E-MAIL ADDRESS</div>
          <div style={styles.thirtyWid}>powerlineindia@gmail.com</div>
          <div style={styles.twentyFiveWid}>RTGS CODE</div>
          <div style={styles.twentyFiveWidNbr}>
            {getBankDetails()?.rtgs_code?.value}
          </div>
        </div>
        {data &&
          data.slice(1, data.length - 2).map((item, index) => {
            let arr = Object.keys(item).filter((item) => item != "label");
            return (
              <>
                <div>
                  <div style={styles.consignee}>{item["label"]}</div>
                </div>
                {/*removed for testing un comment later*/}
                {arr.length % 2 == 0
                  ? arr.slice(0, arr.length / 2).map((key, i) => (
                      <div style={styles.rows} key={i} wrap={false}>
                        <div style={styles.twentyWid}>
                          {" "}
                          {arr[2 * i] ? item[arr[2 * i]]["label"] : ""}
                        </div>
                        <div style={styles.thirtyWid}>
                          {arr[2 * i] ? item[arr[2 * i]]["value"] : ""}
                        </div>
                        <div style={styles.twentyFiveWid}>
                          {arr[2 * i + 1] ? item[arr[2 * i + 1]]["label"] : ""}
                        </div>
                        <div style={styles.twentyFiveWidNbr}>
                          {arr[2 * i + 1] ? item[arr[2 * i + 1]]["value"] : ""}
                        </div>
                      </div>
                    ))
                  : arr.slice(0, arr.length / 2 + 1).map((key, i) => (
                      <div style={styles.rows} key={i} wrap={false}>
                        <div style={styles.twentyWid}>
                          {" "}
                          {arr[2 * i] ? item[arr[2 * i]]["label"] : ""}
                        </div>
                        <div style={styles.thirtyWid}>
                          {arr[2 * i] ? item[arr[2 * i]]["value"] : ""}
                        </div>
                        <div style={styles.twentyFiveWid}>
                          {arr[2 * i + 1] ? item[arr[2 * i + 1]]["label"] : ""}
                        </div>
                        <div style={styles.twentyFiveWidNbr}>
                          {arr[2 * i + 1] ? item[arr[2 * i + 1]]["value"] : ""}
                        </div>
                      </div>
                    ))}
              </>
            );
          })}
        {/* <div style={styles.rows}>
            <div style={styles.twentyWid}>SHIPPMENT BY</div>
            <div style={styles.thirtyWid}>
              {data?.applicant_details?.shippment_by}
            </div>
            <div style={styles.twentyFiveWid}>PAYMENT TERMS</div>
            <div style={styles.twentyFiveWidNbr}>
              {data?.applicant_details?.payment_terms}
            </div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>LOADING PORT</div>
            <div style={styles.thirtyWid}>
              {data?.applicant_details?.loading_port}
            </div>
            <div style={styles.twentyFiveWid}>PACKING LIST NO.</div>
            <div style={styles.twentyFiveWidNbr}>
              {" "}
              {data?.applicant_details?.packing_list_no}
            </div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>LC NO AND DATE</div>
            <div style={styles.thirtyWid}>
              {data?.applicant_details?.lc_no} DATE OF ISSUE 2019 Aug 04
            </div>
            <div style={styles.twentyFiveWid}>LCAF NO.</div>
            <div style={styles.twentyFiveWidNbr}>
              {data?.applicant_details?.lcaf_no}
            </div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}></div>
            <div style={styles.thirtyWid}></div>
            <div style={styles.twentyFiveWid}>DELIVERY TERMS</div>
            <div style={styles.twentyFiveWidNbr}>
              {data?.applicant_details?.delivery_terms}
            </div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>LC ISSUING BANK</div>
            <div style={styles.eightyWid}>
              {data?.applicant_details?.lc_issue_bank}
            </div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>APPLICANT BANK'S TIN NO.</div>
            <div style={styles.thirtyWid}>
              {data?.applicant_details?.applicant_bank_tin_no}
            </div>
            <div style={styles.twentyFiveWid}>APPLICANT BANK'S BIN NO.</div>
            <div style={styles.twentyFiveWidNbr}>
              {data?.applicant_details?.applicant_bank_bin_no}
            </div>
          </div>

          <div>
            <div style={styles.consignee}>CONSIGNEE</div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>INVOICE TO</div>
            <div style={styles.eightyWid}>{data?.billTo?.invoice_to}</div>
          </div>
          <div style={styles.rows}>
            <div style={styles.twentyWid}>ADDRESS</div>
            <div style={styles.eightyWid}>{data?.billTo?.address}</div>
          </div>

          <div>
            <div style={styles.consignee}>NOTIFY PARTY</div>
          </div>
          {data?.notifyParty?.map((party, i) => (
            <>
              <div style={styles.rows}>
                <div style={styles.twentyWid}>IMPORTER</div>
                <div style={styles.eightyWid}>{party?.importer}</div>
              </div>
              <div style={styles.rows}>
                <div style={styles.twentyWid}>ADDRESS</div>
                <div style={styles.thirtyWid}>{party?.importer_address}</div>
                <div style={styles.twentyFiveWid}>
                  FINAL DESTINATION COUNTRY{" "}
                </div>
                <div style={styles.twentyFiveWid}>
                  {party?.destination_country}
                </div>
              </div>
              <div style={styles.rows}>
                <div style={styles.twentyWid}>E-MAIL ADDRESS</div>
                <div style={styles.thirtyWid}>
                  {party?.contact_person_email}
                </div>
                <div style={styles.twentyFiveWid}>TIN NO</div>
                <div style={styles.twentyFiveWid}>{party?.tin_no}</div>
              </div>
              <div style={styles.rows}>
                <div style={styles.twentyWid}>CONTACT DETAILS</div>
                <div style={styles.thirtyWid}>
                  {party?.contact_person_name} Mobile:{" "}
                  {party?.contact_person_number}
                </div>
                <div style={styles.twentyFiveWid}>BIN NO.</div>
                <div style={styles.twentyFiveWid}>{party?.bin_no}</div>
              </div>
              <div style={styles.rows}>
                <div style={styles.twentyWid}>PORT OF DISCHARGE</div>
                <div style={styles.thirtyWid}>{party?.port_of_discharge}</div>
                <div style={styles.twentyFiveWid}>IRC NO.</div>
                <div style={styles.twentyFiveWid}>{party?.irc_no} </div>
              </div>
            </>
          ))} */}

        <div
          style={{
            width: "100%",
            height: 20,

            borderBottom: "1px solid black",
            backgroundColor: "#dbe4f0",
          }}
        >
          {/* <div style={styles.description}>
              Description of Goods : HT VCB, LIGHTNING ARRESTER, CURRENT TRANSFORMER , POTENTIAL TRANSFORMER, RELAY PANEL (
              SPECIFICATIONS,QUALITY,DECRIPTION AND ALL OTHER DETAILS ARE AS PER PROFORMA INVOICE NO:EXP/21248 DATED 10.07.2019 OF THE
              BENEFICIARY ) Delivery Terms : CPT Benapole Bangladesh
            </div> */}
        </div>

        <div style={styles.rows}>
          <div
            style={{ width: "8%", borderRight: "1px solid black", padding: 5 }}
          >
            SR.NO
          </div>
          <div
            style={{ width: "12%", borderRight: "1px solid black", padding: 5 }}
          >
            ITEM
          </div>
          <div
            style={{ width: "25%", borderRight: "1px solid black", padding: 5 }}
          >
            ITEM DESCRIPTION
          </div>
          <div
            style={{ width: "12%", borderRight: "1px solid black", padding: 5 }}
          >
            HSN NO
          </div>
          <div
            style={{ width: "11%", borderRight: "1px solid black", padding: 5 }}
          >
            QTY
          </div>
          <div
            style={{ width: "8%", borderRight: "1px solid black", padding: 5 }}
          >
            UNIT
          </div>
          <div
            style={{ width: "12%", borderRight: "1px solid black", padding: 5 }}
          >{`RATE PRICE (${currency})`}</div>
          <div style={{ width: "12%", padding: 5 }}>
            {`AMOUNT (${currency})`}{" "}
          </div>
        </div>

        {data?.slice(-1)[0]?.map((line, i) => {
          return line.item_description === "" ? (
            <div key={i}></div>
          ) : (
            <div style={styles.rows} key={i} wrap={false}>
              <div
                style={{
                  width: "8%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {i + 1}
              </div>
              <div
                style={{
                  width: "12%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.item}
              </div>
              <div
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.description}
              </div>
              <div
                style={{
                  width: "12%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.hsn_no}
              </div>
              <div
                style={{
                  width: "11%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.quantity}
              </div>
              <div
                style={{
                  width: "8%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.units}
              </div>
              <div
                style={{
                  width: "12%",
                  borderRight: "1px solid black",
                  padding: 5,
                }}
              >
                {line?.rate}
              </div>
              <div style={{ width: "12%", padding: 5 }}>
                {line?.quantity * line?.rate}
              </div>
            </div>
          );
        })}

        <div style={styles.rows}>
          <div
            style={{ width: "8%", borderRight: "1px solid black", padding: 5 }}
          ></div>
          <div
            style={{ width: "12%", borderRight: "1px solid black", padding: 5 }}
          ></div>
          <div
            style={{ width: "25%", borderRight: "1px solid black", padding: 5 }}
          ></div>
          <div
            style={{ width: "12%", borderRight: "1px solid black", padding: 5 }}
          ></div>
          <div
            style={{ width: "11%", borderRight: "1px solid black", padding: 5 }}
          ></div>
          <div
            style={{
              width: "20%",
              borderRight: "1px solid black",
              padding: 5,
              backgroundColor: "#dbe4f0",
            }}
          >
            Total
          </div>
          <div style={{ width: "12%", padding: 5 }}>{subTotal}</div>
        </div>

        <div style={styles.rows}>
          <div style={{ width: "68%", borderRightWidth: 1 }}>
            <div style={{ marginTop: 11 }}>
              {"Amount in Words : "}
              {subTotal &&
                currency &&
                `${currency} ${toWords.convert(subTotal, {
                  ignoreZeroCurrency: true,
                })} only.`}
            </div>
          </div>
          <div style={{ width: "20%" }}>
            <div
              style={{
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                borderLeft: "1px solid black",
                padding: 5,
              }}
            >
              FREIGHT & INSURANCE
            </div>
            <div
              style={{
                borderRight: "1px solid black",
                padding: 5,
                backgroundColor: "#dbe4f0",
                borderLeft: "1px solid black",
              }}
            >
              GRAND TOTAL
            </div>
          </div>
          <div style={{ width: "12%" }}>
            <div style={{ borderBottomWidth: 1, padding: 5 }}>{currency} </div>
            <div style={{ padding: 5 }}>{subTotal} </div>
          </div>
          {/* <div style={{width: '20%',  borderRight: "1px solid black", padding: 5}}>FREIGHT & INSURANCE</div> */}
        </div>
        {usage == "proforma" && (
          <>
            <div style={styles.rows}>
              <div style={{ width: "68%", borderRightWidth: 1 }}></div>
              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  padding: 5,
                  backgroundColor: "#dbe4f0",
                }}
              >
                Amount Paid Previously
              </div>
              <div style={{ width: "12%", padding: 5 }}>{already_paid}</div>
            </div>
            <div style={styles.rows}>
              <div style={{ width: "68%", borderRightWidth: 1 }}></div>
              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  padding: 5,
                  backgroundColor: "#dbe4f0",
                }}
              >
                Amount Paid Currently
              </div>
              <div style={{ width: "12%", padding: 5 }}>{amount_paid}</div>
            </div>
            <div style={styles.rows}>
              <div style={{ width: "68%", borderRightWidth: 1 }}></div>
              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  padding: 5,
                  backgroundColor: "#dbe4f0",
                }}
              >
                Amount Remaining
              </div>
              <div style={{ width: "12%", padding: 5 }}>
                {parseInt(subTotal) -
                  parseInt(amount_paid) -
                  parseInt(already_paid)}
              </div>
            </div>
          </>
        )}
        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '12%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '25%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '12%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '11%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '20%',  borderRight: "1px solid black", padding: 5, backgroundColor: '#dbe4f0'}}>GRAND TOTAL</div>
            <div style={{width: '12%', padding: 5}}>{subTotal} </div>
          </div> */}

        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>
              {subTotal &&
                currency &&
                `${currency} ${toWords.convert(subTotal, {
                  ignoreZeroCurrency: true,
                })}`}
            </div>
          </div> */}

        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>FREIGHT CHARGES: Prepaid, INR 48000 Equivalent to USD 690</div>
          </div> */}

        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>SHIPMENT (S) FROM INDIA TO BENAPOLE, BANGLADESH BY TRUCK</div>
          </div> */}

        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>PACKING IN STANDERD EXPORT QUALITY BAGS</div>
          </div> */}

        {/* <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>WE HEREBY CERTIFY THAT GOODS ARE OF INDIA ORIGIN</div>
          </div> */}
        {/* 
          <div style={styles.rows}>
            <div style={{width: '8%',  borderRight: "1px solid black", padding: 5}}></div>
            <div style={{width: '92%', padding: 5}}>
              WE CERTIFY THAT COUNTRY OF ORIGIN OF GOODS HAS BEEN MENTIONED ON SURFACE OF EACH PACKAGE/CARTON/CONTAINER/DRUM
            </div>
          </div> */}

        <div style={styles.rows}>
          <div style={styles.sign60}>
            <div style={{ padding: 6 }}>
              {data.slice(-2)[0][0]?.children[0]?.text}
            </div>
            <div style={{ padding: 6 }}>
              {data.slice(-2)[0][1]?.children[0]?.text}
            </div>
          </div>
          <div style={styles.sign40}>
            <div style={{ padding: 4 }}>For POWER TECH INDIA</div>
            <div style={{ marginTop: 22 }}>Rohan Agarwal</div>
          </div>
        </div>

        <div>
          <div style={styles.consignee}>
            “WE INTEND TO CLAIM REWARDS UNDER THE REMISSION OF DUTIES AND TAXES
            ON EXPORTED PRODUCTS (RODTEP) SCHEME”
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPdf;
