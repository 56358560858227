import * as types from "../../constants";
import InventoryAPI from "../../utils/api/InventoryAPI";

export const addNewItem = (params) => {
  return {
    type: types.ADD_NEW_ITEM,
    payload: InventoryAPI.addNewItem(params).then((response) => {
      return response.data;
    }),
  };
};

export const getItem = (params) => {
  return {
    type: types.GET_ITEMS,
    payload: InventoryAPI.getItem(params).then((response) => {
      return response.data;
    }),
  };
};

export const getAllItem = (params) => {
  return {
    type: types.GET_ALL_ITEMS,
    payload: InventoryAPI.getAllItem(params).then((response) => {
      return response.data;
    }),
  };
};
export const getItemLedger = (params) => {
  return {
    type: types.GET_ITEMS_LEDGER,
    payload: InventoryAPI.getItemLedger(params).then((response) => {
      return response.data;
    }),
  };
};

export const getUpdatedItemsLedger = (params) => {
  return {
    type: types.GET_UPDATED_ITEMS_LEDGER,
    payload: InventoryAPI.getItemLedger(params).then((response) => {
      return response.data;
    }),
  };
};

export const getUpdatedItems = (params) => {
  return {
    type: types.GET_UPDATED_ITEMS,
    payload: InventoryAPI.getItem(params).then((response) => {
      return response.data;
    }),
  };
};

export const getItemDetails = (params) => {
  return {
    type: types.GET_ITEM_DETAILS,
    payload: InventoryAPI.getItemDetails(params).then((response) => {
      return response.data;
    }),
  };
};

export const getStock = () => {
  return {
    type: types.GET_STOCK,
    payload: InventoryAPI.getStock().then((response) => {
      return response.data;
    }),
  };
};

export const addStock = (params) => {
  return {
    type: types.ADD_STOCK,
    payload: InventoryAPI.addStock(params).then((response) => {
      return response.data;
    }),
  };
};

export const assignStock = (params) => {
  return {
    type: types.ASSIGN_STOCK,
    payload: InventoryAPI.assignStock(params).then((response) => {
      return response.data;
    }),
  };
};
