import * as types from "../../constants";
import ProformaAPI from "../../utils/api/ProformaAPI";

export function getAllProforma(params) {
  return {
    type: types.GET_ALL_PROFORMA,
    payload: ProformaAPI.getAllProforma(params).then((response) => {
      return response.data;
    }),
  };
}

export function getUpdatedProformas(params) {
  return {
    type: types.GET_UPDATED_PROFORMAS,
    payload: ProformaAPI.getAllProforma(params).then((response) => {
      return response.data;
    }),
  };
}

export function getAllBanks(params) {
  return {
    type: types.GET_BANK_LIST,
    payload: ProformaAPI.getAllBanks(params).then((response) => {
      return response.data;
    }),
  };
}

export function addFilesToExport(params) {
  return {
    type: types.ADD_FILES_TO_EXPORT,
    payload: ProformaAPI.addFilesToExport(params).then((response) => {
      return response.data;
    }),
  };
}
export function getProformaById(params) {
  return {
    type: types.GET_PROFORMA_DETAILS,
    payload: ProformaAPI.getProformaById(params).then((response) => {
      return response.data;
    }),
  };
}

export function createNewProforma(params) {
  return {
    type: types.CREATE_PROFORMA,
    payload: ProformaAPI.createProforma(params).then((response) => {
      return response.data;
    }),
  };
}

export function createProformaRevision(params) {
  return {
    type: types.CREATE_PROFORMA_REVISION,
    payload: ProformaAPI.createProformaRevision(params).then((response) => {
      return response.data;
    }),
  };
}

export function createFinalExport(params) {
  return {
    type: types.CREATE_FINAL_EXPORT,
    payload: ProformaAPI.createFinalExport(params).then((response) => {
      return response.data;
    }),
  };
}

export function getDownloadLink(params) {
  // console.log(params)
  return {
    type: types.GET_EXPORT_DOWNLOAD_LINK,
    payload: ProformaAPI.getDownloadLink(params).then((response) => {
      return response.data;
    }),
  };
}

export function resetExport() {
  return {
    type: types.RESET_EXPORT,
  };
}

export function resetExportData() {
  return {
    type: types.RESET_EXPORT_DATA,
  };
}
