import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Layout } from "antd";

const { Content, Footer, Sider } = Layout;
const LayoutCom = ({ children }) => {
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sidebar />
      <Layout className="site-layout">
        <Header />
        <Content>
          <div
            className="site-layout-background"
            style={{
              minHeight: 360,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Powertech India ©2022 designed and maintained by{" "}
          <a href="https://assertit.io/" target="_blank">
            AssertIT
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutCom;
