export const labelStyle = {
  letterSpacing: "0.3px",
  borderStyle: "none none none none",
  borderWidth: "1px",
  borderColor: "lightgray",
  width: "fit-content",
  fontWeight: "bold",
  color: "#333",
  ":hover": {
    borderStyle: "none none dotted none",
    // fontWeight: "bold",
    cursor: "pointer",
    borderColor: "#6e6e6e",
  },
};

export const editIcon = {
  paddingLeft: "5px",
};

const defaultHeight = "25px";

export const inputStyle = {
  height: defaultHeight,
  minWidth: "200px",
  borderStyle: "none none solid none",
  borderWidth: "1px",
  borderColor: "lightgray",
  letterSpacing: "0.3px",
};

export const buttonStyle = {
  height: defaultHeight,
  width: defaultHeight,
  border: "none",
  marginBottom: "2px",
  marginLeft: "5px",
  backgroundColor: "white",
};

export const iconStyle = {
  color: "black",
};

export const errorText = {
  color: "#ad2411",
  display: "block",
};
