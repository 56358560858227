import React, { lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import axios from "axios";
import Login from "./Login";
import Layout from "./layout/Layout";
import ViewItemLedger from "./store/Inventory/ViewItemLedger";
import JobViewerFiles from "./jobOrder/JobViewerFiles";
import ExportDetails from "./export/ExportDetails";
//testing
import CreateExportProforma from "./export/CreateExportProforma";
import ViewProformaForExportList from "./export/ViewProformaForExportList";
import UploadClientData from "../components/pages/sales/clientpages/UploadClientData";

//this section above
/*Admin module imports */
const Admin = lazy(() => import("./admin/Admin"));
const CreateNewEmployee = lazy(() => import("./admin/EmployeeRegistration"));
const Employee = lazy(() => import("./admin/Employee"));
const FileManager = lazy(() => import("./admin/FileManager"));
/*sales module imports */
const Sales = lazy(() => import("./sales/Sales"));
const CreateNewClient = lazy(() => import("./sales/CreateNewClient"));
const Dashboard = lazy(() => import("./sales/Dashboard"));
const CreateQuotation = lazy(() => import("./sales/CreateQuotation"));
const ViewInvoice = lazy(() => import("./sales/ViewInvoice"));
const ViewSingleInvoice = lazy(() => import("./sales/ViewSingleInvoice"));
const NewQuery = lazy(() => import("./sales/NewQuery"));
const ViewEnquiry = lazy(() => import("./sales/ViewEnquiry"));
const ViewClientDetails = lazy(() => import("./sales/ViewClientDetails"));

/*Purchase module imports */
const PurchaseDashboard = lazy(() => import("./purchase/Dasboard"));
const CreateNewVendor = lazy(() => import("./purchase/CreateNewVendor"));
const VendorDetails = lazy(() => import("./purchase/VendorDetails"));
const VendorList = lazy(() => import("./purchase/VendorList"));
const PurchaseList = lazy(() => import("./purchase/PurchaseList"));
const NewPurchase = lazy(() => import("./purchase/NewPurchase"));
const ViewPurchase = lazy(() => import("./purchase/ViewPurchase"));

/*Job Order and Production */
const JobDashboard = lazy(() => import("./jobOrder/Dashboard"));
const JobOrderList = lazy(() => import("./jobOrder/viewJobOrders"));
const CreateJobOrder = lazy(() => import("./jobOrder/CreateJobOrder"));
const ViewOrderDetails = lazy(() => import("./jobOrder/ViewOrderDetails"));
const ViewJobFiles = lazy(() => import("./jobOrder/ViewJobFiles"));
const CreateMRN = lazy(() => import("./mrn/CreateMrn"));
const MrnList = lazy(() => import("./mrn/MrnList"));
const MrnDetails = lazy(() => import("./mrn/MrnDetails"));
/* Inventory in  Stock */
const StoreDashboard = lazy(() => import("./store/Dashboard"));
const AddItem = lazy(() => import("./store/Inventory/AddItem"));
const AddStock = lazy(() => import("./store/Inventory/AddStock"));
const AssignStock = lazy(() => import("./store/Inventory/AssignStock"));
const ViewStock = lazy(() => import("./store/Inventory/ViewStock"));
const ViewItemDetails = lazy(() => import("./store/Inventory/ViewItemDetails"));

/*Export imports*/

const ExportInvoice = lazy(() => import("./export/CreateExportInvoice"));
const ExportPacking = lazy(() => import("./export/CreatePackingList"));
const Proforma = lazy(() => import("./export/CreateProforma"));
const ProformaList = lazy(() => import("./export/ViewProformaList"));
const ViewProformaDetails = lazy(() => import("./export/ViewProformaDetails"));
const ViewExports = lazy(() => import("./export/ViewExports"));
const exportDashboard = lazy(() => import("./export/Dashboard"));
const ViewExport = lazy(() => import("./export/ViewExportDetails"));
const AddFilesToExport = lazy(() => import("./export/AddFilesToExport"));
const ViewPackings = lazy(() => import("./export/ViewPackings"));
const ViewPackingDetails = lazy(() => import("./export/ViewPackingDetails"));
// StorePurchase role
const StorePurchaseDashboard = lazy(() => import("./storePurchase/Dashboard"));
const Test = lazy(() => import("./test"));
class Routes extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Login} />

          <Route exact path="/test" component={Test} />
          <Route exact path="/job/files/:uuid" component={JobViewerFiles} />
          <Route
            exact
            path="/job/viewer/files/:uuid"
            component={JobViewerFiles}
          />
          {/*start of sales routes */}
          <>
            <Layout>
              <Route exact path="/sales" component={Dashboard} />
              <Route exact path="/sales/clients" component={Sales} />
              <Route
                exact
                path="/sales/client/new"
                component={CreateNewClient}
              />
              <Route
                exact
                path="/sales/client/client-information/:id"
                component={ViewClientDetails}
              />
              <Route
                exact
                path="/sales/create-quotation"
                component={CreateQuotation}
              />
              <Route exact path="/sales/invoicelist" component={ViewInvoice} />
              <Route
                exact
                path="/sales/quotation-information/:id"
                component={ViewSingleInvoice}
              />

              <Route exact path="/sales/leads" component={ViewEnquiry} />
              <Route exact path="/sales/leads/new" component={NewQuery} />

              {/*start of purchase routes */}
              <Route exact path="/purchase" component={PurchaseDashboard} />

              <Route exact path="/purchase/new" component={NewPurchase} />
              <Route exact path="/purchase/vendors" component={VendorList} />
              <Route
                exact
                path="/purchase/vendors/new"
                component={CreateNewVendor}
              />
              <Route
                exact
                path="/purchase/vendors/vendor-information/:id"
                component={VendorDetails}
              />
              <Route exact path="/purchase/list" component={PurchaseList} />
              <Route
                exact
                path="/purchase/purchase-information/:id"
                component={ViewPurchase}
              />

              {/*start of Order Handling and Production routes */}
              <Route exact path="/orders" component={JobDashboard} />
              <Route
                exact
                path="/orders/create-job"
                component={CreateJobOrder}
              />
              <Route exact path="/orders/list" component={JobOrderList} />
              <Route
                exact
                path="/orders/order-information/:id"
                component={ViewOrderDetails}
              />

              {/*start of admin routes */}
              <Route exact path="/admin" component={Admin} />
              <Route
                exact
                path="/admin/create-employee"
                component={CreateNewEmployee}
              />
              <Route exact path="/admin/employee" component={Employee} />
              <Route exact path="/admin/files" component={FileManager} />

              <Route
                exact
                path="/admin/upload-clients"
                component={UploadClientData}
              />
              {/*Start of mrn routes */}
              <Route exact path="/mrn/create-mrn" component={CreateMRN} />
              <Route exact path="/mrn/list" component={MrnList} />
              <Route
                exact
                path="/mrn/mrn-information/:id"
                component={MrnDetails}
              />

              {/* Start of Inventory in Stock*/}
              <Route exact path="/store" component={StoreDashboard} />
              <Route exact path="/store/create-item" component={AddItem} />
              <Route exact path="/store/add-stock" component={AddStock} />
              <Route exact path="/store/assign-stock" component={AssignStock} />
              <Route exact path="/store/item-stock" component={ViewStock} />
              <Route
                exact
                path="/store/item-ledger"
                component={ViewItemLedger}
              />
              <Route
                exact
                path="/store/item-information/:id"
                component={ViewItemDetails}
              />

              {/*Start of export routes*/}
              <Route exact path="/export" component={exportDashboard} />
              <Route exact path="/proforma/new" component={Proforma} />
              <Route exact path="/proforma/list" component={ProformaList} />
              <Route
                exact
                path="/proforma/details/:id"
                component={ViewProformaDetails}
              />
              <Route exact path="/export/new" component={ExportInvoice} />
              <Route exact path="/export/final" component={ExportInvoice} />
              <Route exact path="/export/packing" component={ExportPacking} />
              <Route
                exact
                path="/export/final_packing"
                component={ExportPacking}
              />
              <Route exact path="/export/list" component={ViewExports} />
              <Route exact path="/export/final_list" component={ViewExports} />
              <Route
                exact
                path="/export/packing-list"
                component={ViewPackings}
              />
              <Route
                exact
                path="/export/order/add-files"
                component={AddFilesToExport}
              />
              <Route
                exact
                path="/export/invoice/add-files"
                component={AddFilesToExport}
              />
              <Route
                exact
                path="/export/order/details/:id"
                component={ViewExport}
              />
              <Route
                exact
                path="/export/invoice/details/:id"
                component={ViewExport}
              />
              <Route
                exact
                path="/export/order/packing/details/:id"
                component={ViewPackingDetails}
              />
              <Route
                exact
                path="/export/invoice/packing/details/:id"
                component={ViewPackingDetails}
              />
              {/* under work*/}
              <Route exact path="/proforma/new/:id" component={ExportDetails} />
              <Route
                exact
                path="/export/proforma/:id"
                component={ViewProformaForExportList}
              />

              {/*Store Purchase Role */}
              <Route
                exact
                path="/storePurchase"
                component={StorePurchaseDashboard}
              />
            </Layout>
          </>
        </Switch>
      </div>
    );
  }
}

export default withRouter(Routes);
